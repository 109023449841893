import { Box, Grid, Typography } from "@mui/material";
import Loading from "container/loading";
import dayjs from "dayjs";
import { commonService } from "forms/helpers/common.service";
import TextView from "forms/hoc/text-view";
import { dateFormat } from "forms/hooks/customHook";
import { Roles } from "helpers/enum.helpers";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/store";
import Loader from "ui-component/Loader";
import EnhancedTable from "component/tables/selectableTable";

const CustomerPolicesView = ({
  isViewId
}: CustomerPolicesView) => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [policyInfoData, setPolicyInfoData] = useState<any>();
  const { t } = useTranslation();
  const [rows, setRows] = useState<any>();

  const headCell = [
    {
      id: "scheduled_amount",
      numeric: false,
      disablePadding: true,
      label: t("Amount Due"),
      isSort: false,
      isBold: true,
    },
    {
      id: "broker_fee",
      numeric: false,
      disablePadding: false,
      label: t("Broker Fee"),
      isSort: false,
    },
    {
      id: "created_at",
      numeric: false,
      disablePadding: false,
      label: t("Created At"),
      isSort: false,
    },
    {
      id: "amount_received",
      numeric: false,
      disablePadding: false,
      label: t("Amount Received"),
      isSort: false,
    },
  ];



  useEffect(() => {
    getPolicyInfo();
  }, []);

  const getPolicyInfo = () => {
    setLoading(true);
    commonService
      .getServices(`/excel-policy/policy/${isViewId}`)
      .then((res) => {
        const datas = res.data.data;
        const TableDetails = res?.data?.data?.excel_transactions.map(
          (item: any) => {
            return {
              scheduled_amount: item?.scheduled_amount,
              broker_fee: item?.broker_fee || 0,
              amount_received:item?.amount_received,
              created_at: item?.created_at
            };
          }
        );

        setRows(TableDetails);
        setPolicyInfoData(datas);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <Loading />}
      <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} lg={3}>
          <Box p={1}>
            <TextView
              fieldName={t("Policy Number")}
              fieldValue={policyInfoData ? policyInfoData?.policy_number : "-"}
            />
          </Box>
        </Grid>

        <Grid item xs={12} lg={3}>
          <Box p={1}>
            <TextView
              fieldName={t("Location")}
              fieldValue={
                policyInfoData?.location?.name
                  ? policyInfoData?.location?.name
                  : "-"
              }
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <EnhancedTable
          headCells={headCell}
          rows={rows}
          isPagination={false}
        />
      </Grid>
    </>
  );
};

export default CustomerPolicesView;


type CustomerPolicesView = {
  isViewId: string
}