import { Box, Grid, Typography } from "@mui/material";
import FormLayout from "component/form";
import Loading from "container/loading";
import { commonService } from "forms/helpers/common.service";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed, Success } from "forms/helpers/toast.helper";
import { AutoCompleteField, InputField } from "forms/hoc/formfield";
import useForm from "forms/hooks/useForm";
import { createLocationValidation , createLocationSchema} from "forms/hooks/validateRules";
import MainContainerWrapper from "layout/wrappers/MainContainerWrapper";
import MainCardWrapper from "layout/wrappers/MainLayoutWrapper";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { setBlocker } from "store/slice/blocker.slice";
import { setLocationID } from "store/slice/location.slice";
import { useAppDispatch, useAppSelector } from "store/store";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

export default function LocationCreate({
  handleNextTab
}: {
  handleNextTab: any
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [stateList, setStateList] = useState([]);
  // const { id } = useParams();
  const dispatch = useAppDispatch();
  const { locationID } = useAppSelector((state) => state.location);
  const navigate = useNavigate();

  const initialValues = {
    name:null,
    code: null,
    location_url: null,
    address_line_one: null,
    address_line_two: null,
    state: null,
    city: null,
    zip_code: null,
  };

  const getStateList = async () => {
    try {
      const stateResponse = await commonService.getServices(`/state`);
      if (!!stateResponse) {
        let stateList: any = stateResponse.data.data;
        setStateList(stateList);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getStateList();
    if (locationID) {
      getLocationData();
    }
  }, []);

   const getLocationData = () => {
    setLoading(true);
    commonService
      .getServices(`/location/${locationID}?tab=location`)
      .then((res) => {       
        console.log("Location data",res);
        const locationData = {
          name: res.data.data?.name,
          code: res.data.data?.code,
          location_url: res.data.data?.location_url,
          address_line_one: res.data.data?.address_line_one,
          address_line_two: res.data.data?.address_line_two,
          // state: res.data.data?.state?.id,
          state: res.data.data?.state 
          ? {
              value: res.data.data.state.id,
              name: res.data.data.state.name
            }
          : null, 
          city: res.data.data?.city,
          zip_code: res.data.data?.zip_code,
        }
        setPatchValue(locationData);
        setLoading(false);
      })

  }
  const formSubmit = () => {
    setLoading(true);
    const api = "/location";
    const bodyData = locationID
      ? { ...values,state:values?.state?.value,  id: locationID }
      : { ...values,state:values?.state?.value };
    // const bodyData = { ...values };

    commonService
      .postPutService(api, bodyData, locationID ? "patch" : "post")
      .then((res) => {
        console.log("res",res);
        Success(res?.data?.message);
        if(!locationID) dispatch(setLocationID(res?.data?.data?.id))
        handleNextTab();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        Failed(
            errorMessage(err, "Something went wrong, please try again later")
          );
      });
  };
  const { t } = useTranslation();

  const LocationcreateT = createLocationSchema(t);

  const {
    errors,
    values,
    handleChange,
    handleSubmit,
    setPatchValue,
    setUpdateValue,
    setUpdateFormValue,
    
    
  } = useForm(initialValues, formSubmit, createLocationValidation,LocationcreateT);

  const handleBack = () => {
    navigate(-1);
  };
  return (
        <Box sx={{ p: 3, height: "auto", width: "100%" }}>
          <FormLayout
            handleSubmit={handleSubmit}
            isBackButton
            backButtonClick={handleBack}
            backButtonText={t("Cancel")}
            isSaveButton
          >
            {loading && <Loading />}

            <Grid container>
              <Grid container columnSpacing={2}>
                <Grid item xs={12} lg={4}>
                  <InputField
                    fieldName={t("Location Name")}
                    values={values?.name || ""}
                    name="name"
                    errors={errors?.name}
                    handleChange={handleChange}
                    id="name"
                    length={40}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <InputField
                    fieldName={t("Location Code")}
                    values={values?.code || ""}
                    name="code"
                    errors={errors?.code}
                    handleChange={handleChange}
                    id="code"
                    length={10}
                  />
                </Grid>
                <Grid item xs={0} lg={4}>
                  <InputField
                    fieldName={t("Location URL")}
                    values={values?.location_url || ""}
                    name="location_url"
                    errors={errors?.location_url}
                    handleChange={handleChange}
                    id="location_url"
                    isRequired={false}
                  />
                </Grid>
                <Grid item xs={0} lg={4}>
                  <InputField
                    fieldName={t("Address Line 1")}
                    values={values?.address_line_one || ""}
                    name="address_line_one"
                    errors={errors?.address_line_one}
                    handleChange={handleChange}
                    id="address_line_one"
                    isRequired={false}
                    length={50}
                  />
                </Grid>

                <Grid item xs={0} lg={4}>
                  <InputField
                    fieldName={t("Address Line 2")}
                    values={values?.address_line_two || ""}
                    name="address_line_two"
                    errors={errors?.address_line_two}
                    handleChange={handleChange}
                    id="address_line_two"
                    isRequired={false}
                    length={50}
                  />
                </Grid>
                <Grid item xs={0} lg={4}>
                  <AutoCompleteField
                    fieldName={t("State")}
                    values={values.state || null}
                    name="state"
                    errors={errors.state  }
                    handleChange={(state: any) =>
                      setUpdateFormValue("state", state || null)
                    }
                    options={stateList || []}
                    id="state"
                    isRequired={false}
                  />
                </Grid>

                <Grid item xs={0} lg={4}>
                  <InputField
                    fieldName={t("City")}
                    values={values?.city || ""}
                    name="city"
                    errors={errors?.city}
                    handleChange={handleChange}
                    id="city"
                    isRequired={false}
                    length={20}
                  />
                </Grid>

                <Grid item xs={0} lg={4}>
                  <InputField
                    fieldName={t("ZipCode")}
                    values={values?.zip_code || ""}
                    name="zip_code"
                    errors={errors?.zip_code}
                    handleChange={handleChange}
                    id="zip_code"
                    isRequired={false}
                    length={6}
                  />
                </Grid>
              </Grid>
            </Grid>
          </FormLayout>
        </Box>
  );
}




