import { Box, Button, FormHelperText, Grid, IconButton, makeStyles, Stack, styled, Typography } from '@mui/material'
import { ClearIcon } from '@mui/x-date-pickers';
import { commonService } from 'forms/helpers/common.service';
import { errorMessage } from 'forms/helpers/function.helper';
import { Failed, Success } from 'forms/helpers/toast.helper';
import { InputField } from 'forms/hoc/formfield'
import useForm from 'forms/hooks/useForm';
import { createDocumentValidation, createDocumentValidationSchema } from 'forms/hooks/validateRules';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { FileUploader } from 'react-drag-drop-files';
import { useAppSelector } from 'store/store';
import { Margin, Padding } from '@mui/icons-material';

function ReferenceDocument({ handleNextTab }: ReferenceDocumentType) {


    const VisuallyHiddenInput = styled("input")({
        clip: "rect(0 0 0 0)",
        clipPath: "inset(50%)",
        height: 1,
        overflow: "hidden",
        position: "absolute",
        bottom: 0,
        left: 0,
        whiteSpace: "nowrap",
        width: 1,
    });
    const [doctype, setDoctype] = useState<any>();
    const [isLoading, setIsLoading] = useState<any>(false);
    const location = useLocation();
    const pathName = location.pathname.split("/");
    const [fileAdded, setFileAdded] = useState(false);
    const [fileName, setFileName] = useState("");
    const [file, setFile] = useState(null);
    const [filetype, setFiletype] = useState(null);
    const [filepath, setFilepath] = useState(null);
    let [rows, setRows] = useState<any[]>([]);
    const [fileError, setFileError] = useState(false);
    const [path, setPath] = useState<any>();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [dragFile, setDragFile] = useState(null);
    const { referenceID } = useAppSelector((state) => state.reference)







    const handleDragFileChange = (file: any) => {
        setIsLoading(true);
        setDragFile(file);
        console.log("Drag file", file);
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            // for (const file of fi) {
            formData.append("file", file);
            formData.append("upload_type", "customer");
            // }

            commonService
                .fileUploadService("/uploads", formData)
                .then((res: any) => {
                    setIsLoading(false);
                    if (res?.data) {
                        setPath((url: any) => (url = res.data?.data[0]?.path));
                        setFiletype((url: any) => (url = res.data?.data[0]?.file_type));
                        setUpdateFormValue("path", res.data?.data[0]?.path);
                        setUpdateFormValue("file_type", res.data?.data[0]?.file_type);
                        resolve(res.data);
                        setFilepath(res.data?.data[0]?.path)
                    } else {
                        Failed("Something went wrong");
                        reject();
                    }
                })
                .catch((err) => {
                    setIsLoading(false);
                    Failed(errorMessage(err, "Failed to Upload files"));
                    reject();
                });
        });

    }


    const createDocumentValidationSchemaT = createDocumentValidationSchema(t);
    useEffect(() => {
        initialFunc();
    }, []);

    const initialFunc = async () => {
        await getMaster();
    };


    function getMaster() {
        return new Promise(function (resolve, reject) {
            let params = new URLSearchParams();
            params.append("types", "Document type");
            commonService
                .getServices(`/master?${params.toString()}`)
                .then((res) => {
                    console.log(res?.data?.data);
                    const DocumentType = res?.data?.data["Document Type"]?.find(
                        (x: any) => x?.name == "Customer"
                    );
                    console.log("DocumentType?.value", DocumentType);
                    if (DocumentType) {
                        setDoctype((doc: any) => (doc = DocumentType?.value));
                    }

                    resolve(res);
                    console.log(doctype);
                })
                .catch((err) => {
                    console.log("err", err);
                    Failed(errorMessage(err, "Something went wrong"));
                    reject(err);
                });
        });
    }


    const initialValues = {
        name: "",
        reason: "",
        path: "",
        file_type: "",
    };


    const fileTypes = ["PDF"];


    const fileUpload = (fi: any) => {
        setIsLoading(true);
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            // for (const file of fi) {
            formData.append("file", fi);
            formData.append("upload_type", "customer");
            // }

            commonService
                .fileUploadService("/uploads", formData)
                .then((res: any) => {
                    setIsLoading(false);
                    if (res?.data) {
                        setPath((url: any) => (url = res.data?.data[0]?.path));
                        setFiletype((url: any) => (url = res.data?.data[0]?.file_type));
                        setUpdateFormValue("path", res.data?.data[0]?.path);
                        setUpdateFormValue("file_type", res.data?.data[0]?.file_type);
                        resolve(res.data);
                        setFilepath(res.data?.data[0]?.path)
                    } else {
                        Failed("Something went wrong");
                        reject();
                    }
                })
                .catch((err) => {
                    setIsLoading(false);
                    Failed(errorMessage(err, "Failed to Upload files"));
                    reject();
                });
        });
    };
    const handleFileChange = (e: any) => {
        const selectedFile = e.target.files[0];
        const size = selectedFile.size;
        const limit: any = process.env.REACT_APP_UPLOAD_LIMIT;
        if (size >= (+limit || 10) * 1024 * 1024) {
            return Failed("Size of attachments cannot exceed 10MB");
        }
        if (selectedFile.type !== "application/pdf")
            return Failed("Please select a PDF file.");

        if (selectedFile) {
            setFileName(selectedFile.name);
            setFile(selectedFile); // Set the file
            setFileAdded(true);
            setFileError(false);
            fileUpload(selectedFile);
        }
    };


    const handleRemoveFile = () => {
        setFile(null); // Clear the file state
        setFileName("");
        setFileAdded(false);
        setUpdateFormValue("path", "");
        setUpdateFormValue("file_type", "");
    };

    const addDocument = () => {
        if (!file) {
            setFileError(true);
        } else {
            const data = {
                referenceId: referenceID,
                documents: [{
                    name: values?.name,
                    reason: values?.reason,
                    path: values?.path,
                    file_type: values?.file_type,
                }]
            };
            DocumentSubmit(data);
        }
    };

    function DocumentSubmit(values: any) {
        setIsLoading(true);
        commonService
            .postService(`/document`, values)
            .then((res) => {
                setIsLoading(false);
                Success(res?.data?.message);
                handleNextTab()

            })
            .catch((err) => {

                Failed(
                    errorMessage(err, "Something went wrong, please try again later")
                );
                setIsLoading(false);
            });
    }

    const {
        values,
        errors,
        handleChange,
        setUpdateFormValue,
        handleSubmit,
        setPatchValue,
        resetForm,
    } = useForm(
        initialValues,
        addDocument,
        createDocumentValidation,
        createDocumentValidationSchemaT
    );
    console.log(errors);


    const getDocuments = (infoId: any, document_type: any) => {
        commonService
            .getServiceWithParams(`document`, {
                contactInfoId: infoId,
                document_type,
            })
            .then((res: any) => {
                const documents = res?.data?.data?.results?.map((document: any) => {
                    return {
                        id: document?.id,
                        name: document?.name,
                        reason: document?.reason,
                        path: document?.path,
                        file_type: document?.file_type,
                    };
                });
                setRows(documents);
            })
            .catch((err: any) => {
                setIsLoading(false);
                Failed(errorMessage(err, "Something went wrong"));
            });
    };


    return (
        <>

            <Grid container spacing={2} direction="row">
                {/* Left side: Form Section */}
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            {/* Input Fields */}
                            <Grid item xs={12}>
                                <InputField
                                    fieldName={t("file_name")}
                                    values={values?.name || ""}
                                    name="name"
                                    errors={errors?.name}
                                    handleChange={handleChange}
                                    id="name"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputField
                                    fieldName={t("reason")}
                                    values={values?.reason || ""}
                                    name="reason"
                                    errors={errors?.reason}
                                    handleChange={handleChange}
                                    id="reason"
                                    isRequired={false}
                                />
                            </Grid>
                            {/* File Upload */}
                            <Grid item xs={12}>
                                <Box
                                    border="1px dashed"
                                    p={3}
                                    position="relative"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    marginTop="1.2rem"
                                >
                                    {fileAdded ? (
                                        <Stack direction="row" alignItems="center" spacing={1}>
                                            <Typography>{fileName}</Typography>
                                            <IconButton onClick={handleRemoveFile} color="error">
                                                <ClearIcon />
                                            </IconButton>
                                        </Stack>
                                    ) : (
                                        <Button
                                            component="label"
                                            role={undefined}
                                            variant="contained"
                                            tabIndex={-1}
                                            startIcon={<CloudUploadIcon />}
                                        >
                                            {t("upload_file")}
                                            <VisuallyHiddenInput
                                                type="file"
                                                accept="application/pdf"
                                                onChange={handleFileChange}
                                            />
                                        </Button>
                                    )}
                                </Box>
                                <span
                                    style={{
                                        opacity: "0.8",
                                        textAlign: "center",
                                        float: "right",
                                    }}
                                >
                                    {t("Upload only PDF")}{" "}
                                    <span
                                        style={{
                                            color: "#ef5350",
                                            fontSize: ".7rem",
                                            display: "inline-block",
                                        }}
                                    >
                                        ({t("Maximum file size 10mb")})
                                    </span>
                                </span>
                                {errors?.path && (
                                    <Box>
                                        <FormHelperText sx={{ color: "red" }}>
                                            {errors?.path}
                                        </FormHelperText>
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                        {/* Save Button */}
                        <Box
                            sx={{
                                paddingTop: "2.4rem",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Button variant="contained" color="primary" onClick={handleSubmit}>
                                {t("Save")}
                            </Button>
                        </Box>
                    </form>
                </Grid>

                {/* Right side: PDF Preview Section */}
                <Grid
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-start",
                    }}
                >
                    {/* <Box sx={{ width: "100%" }}>
                        <Typography variant='h3'>{t("PDF Preview")}</Typography>
                        <Box sx={{pt:2}}>
                            <iframe
                                src={`${process.env.REACT_APP_IMG_URL}${filepath}`}
                                frameBorder="0"
                                width="100%"
                                height="500px"
                            ></iframe></Box>
                    </Box> */}

                    {/* <Box sx={{ width: "100%", ml: 2 }}>
                        <Typography variant="h3">{t("PDF Preview")}</Typography>

                        <FileUploader
                            handleChange={handleDragFileChange}
                            name="file"
                            types={fileTypes}
                        />
                    </Box> */}
                </Grid>
            </Grid>

        </>

    )
}

export default ReferenceDocument

type ReferenceDocumentType = {
    handleNextTab: any
}
