import { Box, Grid, Typography } from "@mui/material";
import FormLayout from "component/form";
import Loading from "container/loading";
import { commonService } from "forms/helpers/common.service";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed, Success } from "forms/helpers/toast.helper";
import { AutoCompleteField, InputField, PhoneField } from "forms/hoc/formfield";
import useForm from "forms/hooks/useForm";
import {
  createWholesalerValidation,
  createWholesalerSchema,
} from "forms/hooks/validateRules";
import MainContainerWrapper from "layout/wrappers/MainContainerWrapper";
import MainCardWrapper from "layout/wrappers/MainLayoutWrapper";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { setBlocker } from "store/slice/blocker.slice";
import { setLocationID } from "store/slice/location.slice";
import { useAppDispatch, useAppSelector } from "store/store";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

export default function WholesellerCreate() {
  const [loading, setLoading] = useState<boolean>(false);
  const [stateList, setStateList] = useState([]);
  // const { id } = useParams();
  const dispatch = useAppDispatch();
  const { locationID } = useAppSelector((state) => state.location);
  const navigate = useNavigate();
  const { id } = useParams();

  const initialValues = {
    name: null,
    fax: null,
    contact_name: null,
    bank_account: null,
    phone: null,
    email: null,
    address_line_one: null,
    address_line_two: null,
    state: null,
    city: null,
    zip_code: null,
  };

  const getStateList = async () => {
    try {
      const stateResponse = await commonService.getServices(`/state`);
      if (!!stateResponse) {
        let stateList: any = stateResponse.data.data;
        setStateList(stateList);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getStateList();
    if (id) {
      getWholesellerData();
    }
  }, []);

  const getWholesellerData = () => {
    setLoading(true);
    commonService.getServices(`/whole-salers/${id}`).then((res) => {
      console.log("Location data", res);
      const locationData = {
        name: res.data.data?.name,
        fax: res.data.data?.fax,
        contact_name: res.data.data?.contact_name,

        address_line_1: res.data.data?.address?.address_line_1,
        address_line_2: res.data.data?.address?.address_line_2,
        // state: res.data.data?.state?.id,
        state: res.data.data?.address?.state
          ? {
              value: res.data.data?.address?.state.id,
              name: res.data.data?.address?.state.name,
            }
          : null,
        city: res.data.data?.address?.city,
        zip_code: res.data.data?.address?.zip_code,
        phone: res?.data.data?.phone,
        // contact_name : res?.data.data?.contact_name,
        bank_account: res?.data.data?.bank_account,
        email: res?.data.data?.email,
      };
      setPatchValue(locationData);
      setLoading(false);
    });
  };

  const formSubmit = () => {
    setLoading(true);
    const api = id ? `/whole-salers/${id}` : `/whole-salers`;
    // const bodyData = locationID
    //   ? { ...values,state:values?.state?.value,  id: locationID }
    //   : { ...values,state:values?.state?.value };
    const bodyData = id
      ? {
          address: {
            address_line_1: values?.address_line_1,
            address_line_2: values?.address_line_2,
            city: values?.city,
            zip_code: values.zip_code,
            state: values?.state?.value,
          },
          ...values,
          id,
        }
      : {
          ...values,
          address: {
            address_line_1: values?.address_line_1,
            address_line_2: values?.address_line_2,
            city: values?.city,
            zip_code: values.zip_code,
            state: values?.state?.value,
          },
        };

    commonService
      .postPutService(api, bodyData, id ? "patch" : "post")
      .then((res) => {
        console.log("res", res);
        Success(res?.data?.message);
        // if(!locationID) dispatch(setLocationID(res?.data?.data?.id))

        setLoading(false);
        navigate(-1);
      })
      .catch((err) => {
        setLoading(false);
        console.log("klewnfkdfn", err);
        Failed(
          errorMessage(err, "Something went wrong, please try again later")
        );
      });
  };
  const { t } = useTranslation();

  const WholesalercreateT = createWholesalerSchema(t);

  const {
    errors,
    values,
    handleChange,
    handleSubmit,
    setPatchValue,
    setUpdateValue,
    setUpdateFormValue,
  } = useForm(
    initialValues,
    formSubmit,
    createWholesalerValidation,
    WholesalercreateT
  );

  console.log("wwwww", errors);

  //   const PostAddress =
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <MainContainerWrapper headingText="Wholesaler">
      <Box
        sx={{
          p: 3,
          height: "80vh",
          width: "100%",
        }}
      >
        <FormLayout
          handleSubmit={handleSubmit}
          isBackButton
          backButtonClick={handleBack}
          backButtonText={t("Cancel")}
          isSaveButton
        >
          {loading && <Loading />}

          <Grid container>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} lg={4}>
                <InputField
                  fieldName={t("Wholesaler")}
                  values={values?.name || ""}
                  name="name"
                  errors={errors?.name}
                  handleChange={handleChange}
                  id="name"
                  length={40}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <PhoneField
                  name="fax"
                  fieldName={t("fax")}
                  handleChange={handleChange}
                  errors={errors?.fax}
                  id="fax"
                  value={values?.fax}
                  isRequired={false}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <PhoneField
                  name="phone"
                  fieldName={t("phone_number")}
                  handleChange={handleChange}
                  errors={errors?.phone}
                  id="phone"
                  value={values?.phone}
                  isRequired={false}
                />
              </Grid>
              <Grid item xs={0} lg={4}>
                <InputField
                  fieldName={t("Address Line 1")}
                  values={values?.address_line_1 || ""}
                  name="address_line_1"
                  errors={errors?.address_line_1}
                  handleChange={handleChange}
                  id="address_line_1"
                  isRequired={false}
                  length={50}
                />
              </Grid>

              <Grid item xs={0} lg={4}>
                <InputField
                  fieldName={t("Address Line 2")}
                  values={values?.address_line_2 || ""}
                  name="address_line_2"
                  errors={errors?.address_line_2}
                  handleChange={handleChange}
                  id="address_line_2"
                  isRequired={false}
                  length={50}
                />
              </Grid>
              <Grid item xs={0} lg={4}>
                <AutoCompleteField
                  fieldName={t("State")}
                  values={values.state || null}
                  name="state"
                  errors={errors.state}
                  handleChange={(state: any) =>
                    setUpdateFormValue("state", state || null)
                  }
                  options={stateList || []}
                  id="state"
                  isRequired={false}
                />
              </Grid>

              <Grid item xs={0} lg={4}>
                <InputField
                  fieldName={t("City")}
                  values={values?.city || ""}
                  name="city"
                  errors={errors?.city}
                  handleChange={handleChange}
                  id="city"
                  isRequired={false}
                  length={20}
                />
              </Grid>
              <Grid item xs={0} lg={4}>
                <InputField
                  fieldName={t("email")}
                  values={values?.email || ""}
                  name="email"
                  errors={errors?.email}
                  handleChange={handleChange}
                  id="email"
                />
              </Grid>
              <Grid item xs={0} lg={4}>
                <InputField
                  fieldName={t("contact_name")}
                  values={values?.contact_name || ""}
                  name="contact_name"
                  errors={errors?.contact_name}
                  handleChange={handleChange}
                  id="contact_name"
                />
              </Grid>
              <Grid item xs={0} lg={4}>
                <InputField
                  fieldName={t("bank_account")}
                  values={values?.bank_account || ""}
                  name="bank_account"
                  errors={errors?.bank_account}
                  handleChange={handleChange}
                  id="bank_account"
                />
              </Grid>

              <Grid item xs={0} lg={4}>
                <InputField
                  fieldName={t("ZipCode")}
                  values={values?.zip_code || ""}
                  name="zip_code"
                  errors={errors?.zip_code}
                  handleChange={handleChange}
                  id="zip_code"
                  isRequired={false}
                  length={6}
                />
              </Grid>
            </Grid>
          </Grid>
        </FormLayout>
      </Box>
    </MainContainerWrapper>
  );
}
