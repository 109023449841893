import { Box, Button, Grid } from "@mui/material";
import FormLayout from "component/form";
import CustomerPolicyImportModal from "component/helper-modals/customerPolicyImport";
import PolicyCancel from "component/helper-modals/policyCancel";
import PolicyRejected from "component/helper-modals/policyRejected";
import Loading from "container/loading";
import dayjs from "dayjs";
import { commonService } from "forms/helpers/common.service";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed } from "forms/helpers/toast.helper";
import { Success } from "forms/helpers/toast.helper";
import {
  AutoCompleteField,
  InputDatePicker,
  InputField,
  SelectField,
} from "forms/hoc/formfield";
import TextView from "forms/hoc/text-view";
import useForm from "forms/hooks/useForm";
import {
  createCustomerCustomerValidation,
  createCustomerCustomerValidationSchema,
} from "forms/hooks/validateRules";
import { Roles } from "helpers/enum.helpers";
import MainContainerWrapper from "layout/wrappers/MainContainerWrapper";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { setBlocker } from "store/slice/blocker.slice";
import { setPayment, setPolicy } from "store/slice/policy.slice";
import { useAppDispatch, useAppSelector } from "store/store";
import Loader from "ui-component/Loader";

// type PolicyInfoTypes = {
//     isEditId?: string;
//     isEdit : boolean;
//     handleformSubmit: (val: string, val1: boolean) => void;
// };

const CustomerPolicyAdd = () => {
  const { role } = useAppSelector((state) => state.user.user);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [wholeSaler, setWholeSaler] = useState<any>();
  const initialValues = {
    email: "",
    first_name: "",
    last_name: "",
    customer_type: "",
  };
  const [loading, setLoading] = useState<any>(false);
  const [masterData, setMasterData] = useState<any>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const handleFormSubmit = () => {
    console.log("Form is submitting...");
    setLoading(true);
    console.log("Form Submitted with values:", values);

    const bodydata = id
      ? {
        // ...values,
        id: id,
        first_name: values?.first_name,
        last_name: values.last_name,
        address_line_1 : values?.address_line_1,
        address_line_2 : values?.address_line_2,
        zip_code: values?.zip_code
      }
      : {
        ...values,
        first_name: values?.first_name,
        last_name: values.last_name,
        email: values.email,
        customer_type: values.customer_type,
        zip_code: values?.zip_code
      };
    console.log("Body data:", bodydata);

    const api = id
      ? `excel-policy/edit-customer/${id}`
      : `/excel-policy/create-customer`;
    const method = id ? "patch" : "post";


    commonService
      .postPutService(api, bodydata, method)
      .then((res) => {
        setLoading(false);
        Success(res?.data?.message);
        // handleFormSubmit();
        // handleClose();
        navigate(-1);
      })
      .catch((err) => {
        setLoading(false);
        Failed(
          errorMessage(err, "Something went wrong, please try again later")
        );
      });
  };

  const { t } = useTranslation();

  const createClientSchemaT = createCustomerCustomerValidationSchema(t);

  const {
    errors,
    values,
    handleChange,
    setUpdateValue,
    setUpdateFormValue,
    handleSubmit,
    setPatchValue,
  } = useForm(
    initialValues,
    handleFormSubmit,
    createCustomerCustomerValidation,
    createClientSchemaT
  );

  useEffect(() => {
    dispatch(setBlocker({ status: false }));
    getMasterDataList();
    if (id) getCustomerData();
  }, []);

  const getCustomerData = () => {
    commonService
      .getServices(`/excel-policy/customer/${id}`)
      .then((res: any) => {
        console.log("ressss", res?.data?.data?.location?.id);

        const CustomerData = {
          email: res?.data?.data?.contactInfos[0]?.emails[0]?.email,
          first_name: res?.data?.data?.contactInfos[0]?.first_name,
          last_name: res?.data?.data?.contactInfos[0]?.last_name,
          customer_type: res?.data?.data?.customer_type?.id,
          address_line_1 :res?.data?.data?.contactInfos[0]?.addresses[0]?.address_line_1,
          address_line_2 :res?.data?.data?.contactInfos[0]?.addresses[0]?.address_line_2 ?? null,
          zip_code: res?.data?.data?.contactInfos[0]?.addresses[0]?.zip_code,
          
        };
        setPatchValue(CustomerData);
      });
  };

  const getMasterDataList = async () => {
    setLoading(true);
    try {
      let params = new URLSearchParams();
      params.append("types", `Customer`);
      const masterData: any = await commonService.getServices(
        `/master/get-all-master/types?${params.toString()}`
      );
      if (!!masterData?.data) {
        const data = masterData?.data;
        setMasterData(data?.data);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  console.log("errr",errors);
  
  return (
    <>
      {loading && <Loader />}
      <MainContainerWrapper headingText={t("Customers")}>
        <Box sx={{ p: 3, height: "80vh", width: "100%" }}>

          <FormLayout
            handleSubmit={handleSubmit}
            isBackButton
            backButtonClick={() => {
              navigate(`/customer-policy`);
            }}
          >
            <Grid
              container
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} lg={4}>
                <InputField
                  fieldName={t("First Name")}
                  values={values?.first_name}
                  name="first_name"
                  errors={errors?.first_name}
                  handleChange={handleChange}
                  id="first_name"
                  disabled={false}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <InputField
                  fieldName={t("Last Name")}
                  values={values?.last_name}
                  name="last_name"
                  errors={errors?.last_name}
                  handleChange={handleChange}
                  id="last_name"
                  disabled={false}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <InputField
                  fieldName={t("Email")}
                  values={values?.email}
                  name="email"
                  errors={errors?.email}
                  handleChange={handleChange}
                  id="email"
                  disabled={id ? true : false}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <SelectField
                  fieldName={t("Customer Type")}
                  values={values?.customer_type || ""}
                  name="customer_type"
                  errors={errors?.customer_type}
                  handleChange={handleChange}
                  options={masterData?.["Customer"] || []}
                  id="customer_type"
                  disabled={id ? true : false}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <InputField
                  fieldName={t("address_line_one")}
                  values={values?.address_line_1}
                  name="address_line_1"
                  errors={errors?.address_line_1}
                  handleChange={handleChange}
                  id="address_line_1"
                  
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <InputField
                  fieldName={t("address_line_two")}
                  values={values?.address_line_2}
                  name="address_line_2"
                  errors={errors?.address_line_2}
                  handleChange={handleChange}
                  id="address_line_2"
                  
                  isRequired={false}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <InputField
                  fieldName={t("zip_code")}
                  values={values?.zip_code}
                  name="zip_code"
                  errors={errors?.zip_code}
                  handleChange={handleChange}
                  id="zip_code"
                  
                />
              </Grid>
            </Grid>
          </FormLayout>
        </Box>
      </MainContainerWrapper>
    </>
  );
};

export default CustomerPolicyAdd;
