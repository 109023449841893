import { Box, Button, Grid } from '@mui/material'
import FormLayout from 'component/form'
import { commonService } from 'forms/helpers/common.service'
import { errorMessage } from 'forms/helpers/function.helper'
import { Failed, Success } from 'forms/helpers/toast.helper'
import { InputField, SelectField } from 'forms/hoc/formfield'
import useForm from 'forms/hooks/useForm'
import { newCustomervalidation, newCustomervalidationSchema, paymentPersonalInfoSchema, paymentPersonalInfoValidation } from 'forms/hooks/validateRules'
import MainContainerWrapper from 'layout/wrappers/MainContainerWrapper'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { setNewCustomerToken } from 'store/slice/makePayment.slice'
import { useAppDispatch } from 'store/store'
import Loader from 'ui-component/Loader'

function NewCustomer({ handleFormSubmit }: NewCustomerType) {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<any>(false);
    const [masterData, setMasterData] = useState<any>(null);
    const dispatch = useAppDispatch();

    const createQuoteCustomerSchemaT = newCustomervalidationSchema(t);
    useEffect(() => {
        getMasterDataList()
    }, [])
    
    const getMasterDataList = async () => {
        setIsLoading(true);
        try {
            let params = new URLSearchParams();
            params.append("types", `Customer`);
            const masterData: any = await commonService.getServices(
                `/master/get-all-master/types?${params.toString()}`
            );
            if (!!masterData?.data) {
                const data = masterData?.data;
                setMasterData(data?.data);
            }
        } catch (err) {
        } finally {
            setIsLoading(false);
        }
    };

    const formSubmit = () => {
        setIsLoading(true);
        commonService.postService(`excel-policy/create-customer-without-token`, {
            ...values
        })
            .then((res: any) => {
                Success(res?.data?.message)
                dispatch(setNewCustomerToken(res?.data?.data));
                handleFormSubmit()
                setIsLoading(false);
            })
            .catch((err: any) => {
                setIsLoading(false);
                Failed(
                    errorMessage(err, "Something went wrong, please try again later")
                  );
             })
    }

    const {
        errors,
        values,
        handleChange,
        handleSubmit,
    } = useForm({}, formSubmit, newCustomervalidation, createQuoteCustomerSchemaT);

    console.log("errrr", errors)

    return (
        <Box>
        {isLoading && <Loader />}
            <Grid
                container
                height={"80%"}
                sx={{ p: 7, mr: 4, ml: 4 }}
            >
                <FormLayout
                    handleSubmit={handleSubmit}
                    isSaveButton={true}

                >
                    <Grid container columnSpacing={4}>
                        <Grid item xs={12} lg={4}>
                            <InputField
                                fieldName={t("First Name")}
                                values={values?.first_name || ""}
                                name="first_name"
                                errors={errors?.first_name}
                                handleChange={handleChange}
                                id="first_name"
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <InputField
                                fieldName={t("last_name")}
                                values={values?.last_name || ""}
                                name="last_name"
                                errors={errors?.last_name}
                                handleChange={handleChange}
                                id="last_name"
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <InputField
                                fieldName={t("email")}
                                values={values?.email || ""}
                                name="email"
                                errors={errors?.email}
                                handleChange={handleChange}
                                id="email"
                            />
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            <SelectField
                                fieldName={t("Customer Type")}
                                values={values?.customer_type || ""}
                                name="customer_type"
                                errors={errors?.customer_type}
                                handleChange={handleChange}
                                options={masterData?.["Customer"] || []}
                                id="customer_type"
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <InputField
                                fieldName={t("address_line_1")}
                                values={values?.address_line_1 || ""}
                                name="address_line_1"
                                errors={errors?.address_line_1}
                                handleChange={handleChange}
                                id="address_line_1"
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <InputField
                                fieldName={t("address_line_2")}
                                values={values?.address_line_2 || ""}
                                name="address_line_2"
                                errors={errors?.address_line_2}
                                handleChange={handleChange}
                                id="address_line_2"
                                isRequired={false}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <InputField
                                fieldName={t("zip_code")}
                                values={values?.zip_code || ""}
                                name="zip_code"
                                errors={errors?.zip_code}
                                handleChange={handleChange}
                                id="zip_code"
                                onlyNumber={true}
                            />
                        </Grid>
                    </Grid>
                </FormLayout>
            </Grid>
        </Box>
    )
}

export default NewCustomer

type NewCustomerType = {
    handleFormSubmit?: any
}
