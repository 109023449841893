import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import ModalComponent from 'component/modal';
import Loading from 'container/loading';
import React, { useEffect, useState } from 'react'
import CloseIcon from "@mui/icons-material/Close";
import TextView from 'forms/hoc/text-view';
import { commonService } from 'forms/helpers/common.service';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';

function FeeMasterViewModel({
    open,
    handleClose,
    feeMasterId
}: FeeMasterModelType) {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [feeData, setFeeData] = useState<any>({})
    const [feeCoverageTypesList, setFeeCoverageTypesList] = useState<any[]>([])


    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        const api = `fee/${feeMasterId}`
        setIsLoading(true);
        commonService
            .getServices(api)
            .then((res: any) => {
                setFeeData(res?.data?.data)
                const feeDetails = res?.data?.data?.feeCoverageTypes.map((i: any) => {
                    return {
                        id: i?.id,
                        policy_type: i?.policy_type?.values,
                    }
                })
                setFeeCoverageTypesList(feeDetails)
                setIsLoading(false);
            }).catch((err: any) => { })

    }

    return (
        <Box>
            <ModalComponent
                open={open}
                handleClose={handleClose}
                customeStyle={{ width: "35%", height: "40%" }}
            ><>

                    <Typography
                        variant="h4"
                        sx={{
                            textAlign: "center",
                            mb: 2
                        }}
                    >
                        View Fee Master
                    </Typography>
                    {isLoading && <Loading />}
                    <CloseIcon
                        sx={{
                            position: "absolute",
                            top: 35,
                            right: 18,
                            color: "red",
                            cursor: "pointer",
                            marginTop: "-18px",
                        }}
                        onClick={handleClose} // Use the new close function
                    />
                    <Grid container>
                        <Grid item xs={12} lg={4}>
                            <Box p={1}>
                                <TextView
                                    fieldName="Fee Name"
                                    fieldValue={feeData?.name ?? "-"}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Box p={1}>
                                <TextView
                                    fieldName="Recurring Fee"
                                    fieldValue={feeData?.recurring_fee ? "True" : "False"}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Box p={1}>
                                <TextView
                                    fieldName="Agency Fee"
                                    fieldValue={feeData?.agency_fee ? "True" : "False"}
                                />
                            </Box>
                        </Grid>
                        {feeData?.apply_to_all_coverage &&
                            <Grid item xs={12} lg={4}>
                                <Box p={1}>
                                    <TextView
                                        fieldName="Policy Type"
                                        fieldValue={feeData?.apply_to_all_coverage ? "All Policy Types" : ""}
                                    />
                                </Box>
                            </Grid>}
                        {!feeData?.apply_to_all_coverage &&
                            <Grid item xs={12} lg={6}>
                                <Box p={1}>
                                    <Typography>
                                        {"Policy Type"}
                                    </Typography>
                                    <Box
                                        sx={{
                                            marginTop: "6px",
                                            border: "1px solid black", // Lighter border
                                            borderRadius: "5px",
                                            padding: "10px",
                                            height: "100px", // Set a fixed height
                                            overflowY: "auto", // Enable vertical scrolling
                                        }}
                                    >
                                        <ul style={{ padding: "0 16px", margin: 0 ,listStyleType: "disc"}}>
                                            {feeCoverageTypesList.map((type, index) => (
                                                <li key={index} style={{ marginBottom: "8px" }}>
                                                    <Typography variant="body1">{type?.policy_type}</Typography>
                                                </li>
                                            ))}
                                        </ul>
                                    </Box>
                                </Box>
                            </Grid>}
                    </Grid>
                </>

            </ModalComponent>
        </Box>
    )
}

export default FeeMasterViewModel

type FeeMasterModelType = {
    open: boolean;
    handleClose: any;
    feeMasterId: string

}