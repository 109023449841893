import { Box, Grid } from "@mui/material";
import { commonService } from "forms/helpers/common.service";
import TextView from "forms/hoc/text-view";
import MainContainerWrapper from "layout/wrappers/MainContainerWrapper";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "ui-component/Loader";
import { useTranslation } from "react-i18next";
import { Failed } from "forms/helpers/toast.helper";

const FinanceCompanyView = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [financeCompanyData, setFinanceCompanyData] = useState<any>({});
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getFinanceCompanyData();
    }
  }, [id]);

  const getFinanceCompanyData = () => {
    setIsLoading(true);
    commonService
      .getServices(`/finance-company/${id}`)
      .then((res) => {
        const data = res.data.data;
        const companyDetails = {
          name: data?.name || "-",
          email: data?.email || "-",
          contact_name: data?.contact_name || "-",
          account: data?.account || "-",
          website: data?.website || "-",
        };
        setFinanceCompanyData(companyDetails);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        Failed(t("Something went wrong, please try again later"));
      });
  };

  return (
    <MainContainerWrapper headingText={t("Finance Company View")} isBackButton>
      {isLoading && <Loader />}
      <Grid container sx={{ padding: "20px" }}>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} lg={4}>
            <Box p={1}>
              <TextView
                fieldName={t("Name")}
                fieldValue={financeCompanyData?.name}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box p={1}>
              <TextView
                fieldName={t("Email")}
                fieldValue={financeCompanyData?.email}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box p={1}>
              <TextView
                fieldName={t("Contact Name")}
                fieldValue={financeCompanyData?.contact_name}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box p={1}>
              <TextView
                fieldName={t("Account")}
                fieldValue={financeCompanyData?.account}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box p={1}>
              <TextView
                fieldName={t("Website")}
                fieldValue={financeCompanyData?.website}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </MainContainerWrapper>
  );
};

export default FinanceCompanyView;
