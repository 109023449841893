import { IPopupItems } from "interface/redux.interface";

export const popupTenentFree: IPopupItems[] = [
  {
    path: `/profile`,
    title: "Profile",
    icon: "profile",
  },
  {
    path: `/resetPassword`,
    title: "Reset_Password",
    icon: "settings",
  },
  {
    path: `/subscription`,
    title: "Subscription",
    icon: "subscription",
  },
  {
    path: `/knowledgeBase`,
    title: "Knowledge Base",
    icon: "knowledgeBase",
  },
  {
    title: "Logout",
    icon: "logout",
    isLogout: true,
  },
];

export const popupPaidTenent: IPopupItems[] = [
  {
    path: `/profile`,
    title: "Profile",
    icon: "profile",
  },
  {
    path: `/resetPassword`,
    title: "Reset_Password",
    icon: "settings",
  },
  {
    path: `/subscription`,
    title: "Subscription",
    icon: "subscription",
  },
  {
    path: `/knowledgeBase`,
    title: "Knowledge Base",
    icon: "knowledgeBase",
  },

  {
    path: `/transaction-history`,
    title: "Transaction History",
    icon: "Reconciliation",
  },
  //   {
  //   path:`/chequelist`,
  //   title: "Check",
  //   icon: "check",
  // },
  {
    path: `/employees`,
    title: "Employees",
    icon: "Employees",
  },
  {
    path: `/reconciliation`,
    title: "Reconciliation",
    icon: "Reconciliation",
  },
  {
    path: `/quickRecipt`,
    title: "QuickRecipt",
    icon: "ReceiptLongIcon",
  },
  {
    path: `/settings`,
    title: "Settings",
    icon: "setting",
  },
  {
    title: "Logout",
    icon: "logout",
    isLogout: true,
  },
];

export const popupCustomer: IPopupItems[] = [
  {
    path: `/customerprofile`,
    title: "Profile",
    icon: "profile",
  },
  {
    path: `/resetPassword`,
    title: "Reset_Password",
    icon: "settings",
  },
  {
    title: "Logout",
    icon: "logout",
    isLogout: true,
  },
];


export const popupEmployee: IPopupItems[] = [
  {
    path: `/profile`,
    title: "Profile",
    icon: "profile",
  },
  {
    path: `/resetPassword`,
    title: "Reset_Password",
    icon: "settings",
  },
  // {
  //   path:`/knowledgeBase`,
  //   title: "Knowledge Base",
  //   icon: "knowledgeBase",
  // },
  {
    title: "Logout",
    icon: "logout",
    isLogout: true,
  }
]