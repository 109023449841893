

import { commonService } from 'forms/helpers/common.service';
import { Failed, Success } from 'forms/helpers/toast.helper';
import MainContainerWrapper from 'layout/wrappers/MainContainerWrapper';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'store/store';
import ReferenceDocument from '../create/ReferenceDocument';
import { Box, Button } from '@mui/material';
import EnhancedTable from 'component/tables/selectableTable';
import Loader from 'ui-component/Loader';
import deleteAlert from 'component/HOC/deleteAlert';
import { errorMessage } from 'forms/helpers/function.helper';

function ReferenceDocumentList({ handleNextTab }: ReferenceDocumentListType) {


    const { t } = useTranslation();

    const { referenceID } = useAppSelector((state) => state.reference)
    const [rows, setRows] = useState<any>([]);
    const [isLoading, setIsloading] = useState(false);
    const [referenceDocument, setReferenceDocument] = useState<boolean>(false)

    console.log("hahahahahha", referenceID);

    const { id } = useParams()
    const navigate = useNavigate();

    const referenceContactList = [

        {
            id: "document_name",
            numeric: false,
            disablePadding: false,
            label: t("name"),
            isSort: false,
        },
        {
            id: "reason",
            numeric: false,
            disablePadding: false,
            label: t("reason"),
            isSort: false,
        },
        {
            id: "is_active",
            numeric: false,
            disablePadding: false,
            label: t("attached"),
            isSort: false,
          },
        {
            id: "action",
            numeric: false,
            disablePadding: false,
            label: t("Action"),
            isSort: false,
        },
    ]

    useEffect(() => {
        getTableData();
    }, [])

    const getTableData = () => {
        setIsloading(true)
        const api = `document`
        commonService
            .getServiceWithParams(api,
                {
                    referenceId: referenceID
                }
            )
            .then((res: any) => {
                const TableData = res?.data?.data?.results.map((data: any) => {

                    return {
                        id:data?.id,
                        document_name: data?.name,
                        reason: data?.reason,
                        is_active: !!data?.is_attach
                    }

                })
                setRows(TableData)
                setIsloading(false)
            })

            .catch((err: any) => {
                Failed("Something went wrong, please try again later");
            })
    };

    const handleCreateReferenceContact = () => {
        setReferenceDocument(true)
    }

    const handleSubmitReferenceContact = () => {
        setReferenceDocument(false)
        getTableData()
    }

    const handleSave = () => {
        navigate(-1)
    }



    const handleAction = (id: string, action: string) => {
        if (action === "delete") {
            handleDelete(id)
        }
    }


    const handleStatus = (id: string, checked: boolean) => {
        deleteAlert({
          title: checked ? t("attached") : t("unattached"),
          message: t("Are You sure?"),
          confirmButtonText: t("Yes"),
          onConfirmed: setActive,
          data: { id, checked },
        });
      };
    
      const setActive = (data: { id: string; checked: boolean }) => {
        commonService
          .patchService(`/document`, { 
            documentId: data?.id,
            is_attach: data.checked 
        })
          .then((res: any) => {
            Success(res.data.message);
            getTableData();
          })
          .catch((err) => {
            console.log(err);
            Failed(
              errorMessage(
                err,
                err?.response?.data?.error ?? "Oops! Something went wrong!"
              )
            );
            
          });
      };

    const handleDelete = (id: string) => {
        deleteAlert({
            title: t("Delete"),
            message: t("Are You sure?"),
            confirmButtonText: t("Yes"),
            onConfirmed: deleteAction,
            data: id,
        });
    };


    const deleteAction = (id: string) => {
        commonService
            .deleteService(`/document/${id}`)
            .then((res: any) => {
                Success(res?.data?.message);
                getTableData();
            })
            .catch((err) => {
                Failed("Error deleting")
            });
    };
    return (
        <>

            {isLoading && <Loader />}
            {!referenceDocument && <MainContainerWrapper
                headingText='Document List'
                isCreateButton
                buttonText={t("Create Document")}
                buttonIcon={<span>&#43;</span>}
                handleClick={handleCreateReferenceContact}
            >
                <EnhancedTable
                    headCells={referenceContactList}
                    rows={rows}
                    isDelete={true}
                    isPagination={false}
                    isSelectable={false}
                    actionFunction={handleAction}
                    setActive={handleStatus}
                    
                />
                <Box sx={{ pt: 2 }} display={"flex"} justifyContent={"right"}>
                    <Button
                        variant="contained"
                        sx={{ ml: 2 }}
                        type="button"
                        onClick={handleSave}
                    >

                        {t("Save_&_Proceed")}
                    </Button>
                </Box>
            </MainContainerWrapper>}
            {referenceDocument &&
                <ReferenceDocument
                    handleNextTab={handleSubmitReferenceContact}
                />}
        </>
    )
}

export default ReferenceDocumentList


type ReferenceDocumentListType = {
    handleNextTab?: any
}