import { Stack } from "@mui/material";
import TabComponent from "component/tabs";
import MainContainerWrapper from "layout/wrappers/MainContainerWrapper";
import { useEffect, useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Failed, Success } from "forms/helpers/toast.helper";
import { useAppSelector } from "store/store";
import { Roles } from "helpers/enum.helpers";
import PolicyCliam from "pages/claims";
import { useTranslation } from "react-i18next";

import { commonService } from "forms/helpers/common.service";
import SweetAlertComponent from "component/HOC/Alert";
import { errorMessage } from "forms/helpers/function.helper";
import deleteAlert from "component/HOC/deleteAlert";
import CustomerInfoView from "./CustomerBasicView";
import CustomerrPolicy from "./CustomerPolicy";

const CustomerrView = () => {
  const { role } = useAppSelector((state) => state.user.user);

  const location = useLocation();
  const { id } = useParams();
  const [tab, setTab] = useState(location?.state?.tab ?? 0);
  const [policyStatus, setPolicyStatus] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [actionType, setActionType] = useState("Create");

  const updatePolicyStatus = (status: string) => {
    setPolicyStatus(status);
  };
  const handleDeleteDriver = () => {
    console.log(policyStatus);
    deleteAlert({
      title: t("Delete"),
      message: t("Are you sure you want to reinstate the status?"),
      confirmButtonText: t("Yes"),
      onConfirmed: deleteAction,
      data: id,
    });
  };

  const deleteAction = (id: string) => {
    const values = {
      policy_id: id,
    };
    commonService
      .postService(`/policy/update/reinstated-status`, values)
      .then((res) => {
        Success(res.data.message);
        handleChangeList();
      })
      .catch((err) => {
        SweetAlertComponent({
          message: errorMessage(err, "Oops! Something went wrong!"),
          title: "Warning",
          onSuccess: () => {},
        });
      });
  };

  const handleChangeList = () => {
    navigate(-1);
  };
  const [open, setOpen] = useState(false);

  const tabList = [
    {
      label: t("Customer Info"),
      value: 0,
      Component: <CustomerInfoView customerId={id} />,
    },
    {
      label: t("Policy"),
      value: 2,
      Component: <CustomerrPolicy customerId={id} />,
    },
  ];

  return (
    <MainContainerWrapper headingText={t("Customer View")} isBackButton={true}>
      <Stack flexGrow={1}>
        <TabComponent
          handleChange={(e: any, value: number) => setTab(value)}
          value={tab}
          tabs={tabList}
          setTab={setTab}
        />
      </Stack>
    </MainContainerWrapper>
  );
};

export default CustomerrView;
