import { Box, Button, Grid, Link, Typography, useTheme } from "@mui/material";
import FormLayout from "component/form";
import Loading from "container/loading";
import { commonService } from "forms/helpers/common.service";
import { Failed, Success } from "forms/helpers/toast.helper";
import { GroupRadioButton, InputField } from "forms/hoc/formfield";
import useForm from "forms/hooks/useForm";
import {
  existingCustomervalidation,
  existingCustomervalidationSchema,
} from "forms/hooks/validateRules";
import MainContainerWrapper from "layout/wrappers/MainContainerWrapper";
import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import OtpInput from "react-otp-input";
import { useAppDispatch } from "store/store";
import { setExistingCustomerToken } from "store/slice/makePayment.slice";
import { errorMessage } from "forms/helpers/function.helper";
import { useLocation } from "react-router-dom";

function VerifyEmail({ handleFormSubmit }: VerifyEmailType) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [verified, setVerified] = useState<Boolean>(false);
  const [isOtpValid, setIsOtpValid] = useState(false);
  const [emailToken, setEmailToken] = useState<string>("");
  const [loading, setLoading] = useState<any>(false);
  const [otp, setOtp] = useState<string>("");
  const [countdown, setCountdown] = useState(60);
  const location = useLocation();
  const [isDisabled, setIsDisabled] = useState(true);
  let timer: any;
  useEffect(() => {
    startCountdown();
    return () => clearInterval(timer);
  }, [isOtpValid]);

  const startCountdown = () => {
    setIsDisabled(true);
    setCountdown(60); // Reset countdown to 60 seconds
    timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      setIsDisabled(false); // Enable button after 60 seconds
    }, 60000);
  };

  const dispatch = useAppDispatch();
  const CustomerSchemaT = existingCustomervalidationSchema(t);

  const onChange = () => {
    setVerified(true);
  };

  const handleResendOTP = (event: any) => {
    event.preventDefault();
    setLoading(true);
    commonService
      .postService(`make_payment/validate-email`, {
        ...values,
      })
      .then((res: any) => {
        Success(res?.data?.message);
        setEmailToken(res?.data?.data?.token);
        setIsOtpValid(true);
        startCountdown();
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        Failed(
          errorMessage(err, "Something went wrong, please try again later")
        );
      });
  };
  const formSubmit = () => {
    if (!isOtpValid) {
      if (!verified) {
        Failed(t("Please verify the captcha to proceed"));
        return;
      }

      commonService
        .postService(`make_payment/validate-email`, {
          ...values,
        })
        .then((res: any) => {
          Success(res?.data?.message);
          setEmailToken(res?.data?.data?.token);
          setIsOtpValid(true);
        })
        .catch((err: any) => {
          Failed(
            errorMessage(err, "Something went wrong, please try again later")
          );
        });
    } else {
      commonService
        .postService(`make_payment/validate-otp`, {
          otp: otp,
          token: emailToken,
        })
        .then((res: any) => {
          Success(res?.data?.message);
          dispatch(setExistingCustomerToken(res?.data?.data?.token));
          handleFormSubmit();
        })
        .catch((err: any) => {
          Failed(
            errorMessage(err, "Something went wrong, please try again later")
          );
        });
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const enteredKey = event.key;
    if (!/^[0-9]$/.test(enteredKey)) {
      event.preventDefault();
    }
  };
  const {
    errors,
    values,
    handleChange,
    handleSubmit,
    setUpdateValue,
    setUpdateFormValue,
    setPatchValue,
  } = useForm({}, formSubmit, existingCustomervalidation, CustomerSchemaT);

  return (
    <>
      <Box
        sx={{
          maxWidth: "416px",
          margin: "auto",
        }}
      >
        {loading && <Loading />}
        <Grid
          container
          height={"80%"}
          sx={{ p: 7 }}
          alignItems={"center"} // Vertically center
          justifyContent={"center"} // Horizontally center
        >
          <Grid item lg={12}>
            <Box sx={{ overflow: "hidden" }}>
              <FormLayout
                // handleSubmit={handleSubmit}
                submitButtonText="Proceed"
                isSaveButton={false}
              >
                <Grid container columnSpacing={2} direction="column">
                  {!isOtpValid && (
                    <Grid item xs={12}>
                      <Box sx={{ width: "300px" }}>
                        <InputField
                          fieldName={t("Enter your Email")}
                          values={values?.email || ""}
                          name="email"
                          errors={errors?.email}
                          handleChange={handleChange}
                          id="email"
                        />
                      </Box>
                    </Grid>
                  )}

                  {!isOtpValid && (
                    <Grid item xs={12}>
                      <Box sx={{ mt: 4, overflowY: "hidden" }}>
                        <ReCAPTCHA
                          sitekey={`${process.env.REACT_APP_GOOGLE_CAPATCHA}`}
                          onChange={onChange}
                        />
                      </Box>
                    </Grid>
                  )}
                  {isOtpValid && (
                    <Grid item xs={12}>
                      <Typography
                        py="10px"
                        fontWeight={400}
                        fontSize={"1rem"}
                        color={theme.palette.text.dark}
                      >
                        {t("Enter OTP")} {' '}
                        {<span style={{ color: "red" }}>*</span>}
                      </Typography>
                      <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderSeparator={<span> </span>}
                        renderInput={(props: any) => (
                          <input
                            onKeyPress={handleKeyPress}
                            type={"number"}
                            {...props}
                          />
                        )}
                        inputStyle={{
                          width: "2.64rem",
                          height: "2.7rem",
                          margin: "0.5rem 0.25rem  0  0.25rem",
                          fontSize: "1rem",
                          borderRadius: 4,
                          border: `1px solid ${theme.palette.grey[400]}`,
                          textAlign: "center",
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sx={{ alignItems: "center" }}>
                    <Box sx={{ alignItems: "center" }}>
                      <Button
                        onClick={handleSubmit}
                        sx={{
                          mt: 4,
                          fontSize: "16px",
                          width: "300px",
                        }}
                      >
                        {isOtpValid ? "Submit OTP" : "Get OTP"}
                      </Button>
                    </Box>
                    <Box width={"100%"} textAlign="right" mt={1} mb={2}>
                      {isOtpValid &&
                        (isDisabled ? (
                          <Typography
                            variant="h4"
                            sx={{
                              textAlign: "center",
                              width: "100%",
                              mt: 3,
                            }}
                          >
                            Resend OTP in {countdown} seconds
                          </Typography>
                        ) : (
                          <Link
                            onClick={handleResendOTP}
                            variant="body2"
                            style={{ cursor: "pointer" }}
                          >
                            {t("Resend OTP")}
                          </Link>
                        ))}
                    </Box>
                  </Grid>
                </Grid>
              </FormLayout>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default VerifyEmail;

type VerifyEmailType = {
  handleFormSubmit: any;
};
