import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Box, Typography } from '@mui/material';
import { commonService } from 'forms/helpers/common.service';
import { Failed } from 'forms/helpers/toast.helper';
import TextView from 'forms/hoc/text-view';
import MainContainerWrapper from 'layout/wrappers/MainContainerWrapper';
import Loader from 'ui-component/Loader';
import { useTranslation } from 'react-i18next';

const FeeView: React.FC = () => {
    const { id } = useParams();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [feeData, setFeeData] = useState<any>(null);
    const [feeCoverageTypesList, setFeeCoverageTypesList] = useState<any[]>([])

    useEffect(() => {
        if (id) {
            fetchFeeData();
        }
    }, [id]);

    const fetchFeeData = async () => {
        try {
            setIsLoading(true);
            const response = await commonService.getServices(`/fee/${id}`);
            setFeeData(response?.data?.data);

            const feeDetails = response?.data?.data?.feeCoverageTypes.map((i: any) => {
                return {
                    id: i?.id,
                    policy_type: i?.policy_type?.values,
                }
            })
            setFeeCoverageTypesList(feeDetails)
        } catch (error) {
            Failed(t('Something went wrong, please try again later.'));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <MainContainerWrapper headingText="Fee View" isBackButton={true}>
            {isLoading ? (
                <Loader />
            ) : (
                <Box sx={{ paddingRight: '25px', pl: 2, px:2, py:5 }}>
                    <Grid container spacing={2}>
                        {/* Render fee details */}
                        <Grid item xs={12} lg={3}>
                            <TextView
                                fieldName={t('Name')}
                                fieldValue={feeData?.name || '-'}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <TextView
                                fieldName={t('Recurring Fee')}
                                fieldValue={feeData?.recurring_fee ? t('Yes') : t('No')}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <TextView
                                fieldName={t('Agency Fee')}
                                fieldValue={feeData?.agency_fee ? t('Yes') : t('No')}
                            />
                        </Grid>

                        <Grid item xs={12} lg={3}>
                            <TextView
                                fieldName={t('Apply to All Coverage')}
                                fieldValue={feeData?.apply_to_all_coverage ? t('Yes') : t('No')}
                            />
                        </Grid>

                        <Grid item xs={12} lg={5}>
                            {/* <TextView
                                fieldName={t('Policy Types')}
                                fieldValue={
                                    feeData?.feeCoverageTypes?.length
                                        ? feeData.feeCoverageTypes
                                              .map((type: any) => type.policy_type?.values)
                                              .join(', ')
                                        : t('N/A')
                                }
                            /> */}

                            {!feeData?.apply_to_all_coverage &&
                                <Grid item xs={12} lg={6}>
                                    <Box p={1}>
                                        <Typography>
                                            {"Policy Type"}
                                        </Typography>
                                        <Box
                                            sx={{
                                                marginTop: "6px",
                                                border: "1px solid black", // Lighter border
                                                borderRadius: "5px",
                                                padding: "10px",
                                                height: "100px", // Set a fixed height
                                                overflowY: "auto", // Enable vertical scrolling
                                            }}
                                        >
                                            <ul style={{ padding: "0 46px", margin: 0, listStyleType: "disc" }}>
                                                {feeCoverageTypesList.map((type, index) => (
                                                    <li key={index} style={{ marginBottom: "8px" }}>
                                                        <Typography variant="body1">{type?.policy_type}</Typography>
                                                    </li>
                                                ))}
                                            </ul>
                                        </Box>
                                    </Box>
                                </Grid>}
                        </Grid>
                        
                    </Grid>
                </Box>
            )}
        </MainContainerWrapper>
    );
};

export default FeeView;
