import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IRolodex {
  rolodexCategory: any;
  rolodexType : any;

  rolodexId : any
}

const initialState: IRolodex = {
    rolodexCategory: null,
    rolodexType : null,
    rolodexId : null
};

export const rolodexSlice = createSlice({
  name: "action",
  initialState,
  reducers: {
    setrolodexCategory(state, action: PayloadAction<any>) {
      state.rolodexCategory = action.payload;
    },
    setrolodexType(state, action: PayloadAction<any>) {
      state.rolodexType = action.payload;
    },
    setrolodexId(state, action: PayloadAction<any>) {
      state.rolodexId = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    }),
});

export const { setrolodexCategory , setrolodexType , setrolodexId } =
rolodexSlice.actions;
export default rolodexSlice.reducer;
