import { Box, Button, Card, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'store/store'
import NewPolicyPayment from './NewPolicyPayment'
import { commonService } from 'forms/helpers/common.service'
import { Failed } from 'forms/helpers/toast.helper'
import { errorMessage } from 'forms/helpers/function.helper'
import { paymentType, paymentTypeEnum } from 'utils/constant.utils'

function PolicyTest({ policyType }: PolicyPaymentType) {

    const { t } = useTranslation()

    const [groupedPolicies, setGroupedPolicies] = useState<any[]>([])
    const [payableAmount, setPayableAmount] = useState<number | string>("$0.00");
    const [cc_percentage, setCc_percentage] = useState<number | string>("");
    const [dc_percentage, setDc_percentage] = useState<number | string>("");
    const [achFlatValue, setachFlatValue] = useState<number | string>("")
    const [amountErrors, setAmountErrors] = useState<string>("");
    const [isDisabled, setIsDisabled] = useState(true);

    const makePayment = useAppSelector((state) => state?.makePayment?.makepayment)


    const existingCustomerToken = makePayment?.existingCustomerToken

    useEffect(() => {
        getGroupedPoliciesList()
    }, [])

    const getGroupedPoliciesList = () => {

        if (policyType === "Existing Policy") {

            commonService.postService(`make_payment/list-policy`, {
                token: existingCustomerToken
            })
                .then((res: any) => {
                    const updateGRPoliciesArray = res?.data?.data?.grouped_policies.map((i: any) => {
                        return {
                            ...i,
                            sum_of_amount_location: i?.total_amount_due,
                            type_of_card: null, // Add the new key with an initial value (e.g., null)
                        }
                    })

                    console.log("uppppppppppppppp", updateGRPoliciesArray);

                    setGroupedPolicies(updateGRPoliciesArray)
                    setCc_percentage(res?.data?.data?.cc_percentage)
                    setDc_percentage(res?.data?.data?.dc_percentage)
                    setachFlatValue(res?.data?.data?.ach_flat_value)
                })
                .catch((err: any) => { })
        }

    }

    useEffect(() => {
        console.log("aaaaaaaaaaa", groupedPolicies);

    }, [groupedPolicies])

    const handlePaymentSubmit = (location: any, amount1: any, type_of_card: any) => {

        const amount =  parseFloat(amount1)
        let percentage = 0;
        let calculatedAmount = amount;

        if (type_of_card === "credit") {
            percentage = parseFloat(cc_percentage as string) / 100; // Ensure cc_percentage is a number
            calculatedAmount = amount + amount * percentage;
        } else if (type_of_card === "debit") {
            percentage = parseFloat(dc_percentage as string) / 100; // Ensure dc_percentage is a number
            calculatedAmount = amount + amount * percentage;
        } else if (type_of_card === "ach") {
            const achFlatValue1 = parseFloat(achFlatValue as string || "0"); // Ensure ach_flat_value is a number
            calculatedAmount = amount + achFlatValue1;
        }


        console.log("Debit", type_of_card, "amount", payableAmount, "accccc", achFlatValue);

        commonService.postService(
            `make_payment/payment`, {
            location: location,
            token: existingCustomerToken,
            amount: +calculatedAmount,
            transaction_type: paymentTypeEnum[type_of_card],
            type_of_card: type_of_card,
            who_has_to_pay_the_transaction_fee: "Driver",
            is_existing_policy: true
        }
        ).then((res: any) => {
            window.open(res?.data?.url, "_self");
        }).catch((err: any) => {
            Failed(
                errorMessage(err, "Something went wrong, please try again later")
            );

        })
    }


    const handleTypeCardChange = (index: string, key: string, value: string) => {
        const updateGoupedPolices: any = [...groupedPolicies]
        updateGoupedPolices[index][key] = value;    // upate the specific Key with value
        setGroupedPolicies(updateGoupedPolices)
    }

    const handleAmountChange = (index: string, key: string, value: string) => {

        let amtVal = parseFloat(value)
        console.log("amttttt", amtVal);
        
        // if (amtVal >= 65) {
        //     console.log("zzzzzzzzzzzzzzzzzzzzzzzzzzzzzz");

        // }
        
        const updateGoupedPolices: any = [...groupedPolicies]
        if(amtVal > updateGoupedPolices[index]["sum_of_amount_location"]){
            setAmountErrors("Amount should not exceed total due amount")
        }else {
            setAmountErrors("")
        }
        updateGoupedPolices[index][key] = value;    // upate the specific Key with value
        setGroupedPolicies(updateGoupedPolices)
    }


    return (

        <Box sx={{ px: 6, py: 6 }}>
            {policyType === "Existing Policy" && <Grid container spacing={4}>

                {groupedPolicies?.map((locationData: any, locationIndex: any) => (
                    <Grid item lg={6}>
                        <Card
                            key={locationIndex}
                            sx={{
                                width: "80%", // Fixed width
                                height: "100%", // Dynamic height based on content
                                bgcolor: "white",
                                boxShadow: 2,
                                display: "flex",
                                flexDirection: "column",
                                position: "relative",

                            }}
                        >
                            <Box sx={{ mb: 3 }}>
                                {/* Card title with Location */}
                                <div style={{ padding: '16px', fontSize: '18px', fontWeight: 'bold' }}>
                                    Location : {locationData.location}
                                    <FormControl variant="outlined" sx={{
                                        position: "absolute", // Position it absolutely
                                        right: "16px",        // 16px from the right
                                        mb: -1,
                                        alignItems: "end",
                                    }}>
                                        {/* <InputLabel id="category-label">{t("Mode of Payment")}</InputLabel> */}

                                        <Typography

                                            // fontWeight={400}
                                            fontSize={"12px"}
                                            marginTop={"-12px"}
                                        >
                                            {"Mode of Payment"}
                                            {<span style={{ color: "red" }}>*</span>}
                                        </Typography>
                                        <Select
                                            disabled= {locationData.total_amount_due === 0}
                                            sx={{ height: "38px", width: "126px", top: "5px" }}
                                            value={locationData?.type_of_card}
                                            onChange={(e) => handleTypeCardChange(locationIndex, "type_of_card", e.target.value)}
                                        >
                                            {paymentType.map((i: any, idx: any) => (
                                                <MenuItem key={idx} value={i?.value}>
                                                    {i?.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </Box>

                            {/* Table to display policies for the location */}
                            <TableContainer component={Paper}
                                sx={{
                                    maxHeight: 250, // Set a fixed height for the table container
                                    overflowY: "auto", // Enable vertical scrolling
                                }}
                            >
                                <Table stickyHeader>
                                    <TableHead
                                    >
                                        <TableRow

                                        >
                                            <TableCell
                                                sx={{
                                                    backgroundColor: "#f2f6fc"
                                                }}> <Typography variant="h4" fontWeight="bold">Policy Number</Typography></TableCell>
                                            <TableCell
                                                sx={{
                                                    backgroundColor: "#f2f6fc"
                                                }}
                                            ><Typography variant="h4" fontWeight="bold">Total Amount Due</Typography></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {locationData.policies.map((policy: any, policyIndex: any) => (
                                            <TableRow key={policyIndex}>
                                                <TableCell>{policy?.policy_number}</TableCell>
                                                <TableCell>
                                                    {policy?.total_amount}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>


                            {/* //Here I need one   text field to set the   locationData?.total_amount_due and that text field can able to edit the amount on the onnchage how? 
                                here also i need to set the erros if the amount is eqaul to zero? how?
                            */}

                            <Box sx={{ padding: '16px' }}>
                                <TextField
                                    label="Total Amount Due"
                                    variant="outlined"
                                    value={locationData.total_amount_due}
                                    onChange={(e) => handleAmountChange(locationIndex, "total_amount_due", e.target.value)}
                                    error={locationData.total_amount_due === 0 ? false :locationData.total_amount_due <= 0}  // Error condition: if amount is <= 0
                                    helperText={locationData.total_amount_due === 0? "" : locationData.total_amount_due <= 0 ? "Amount must be greater than zero" : ""}
                                    disabled = {locationData.total_amount_due === 0}
                                />
                                {amountErrors && (
                                    <Typography variant="body2" color="error" sx={{ marginTop: "4px" }}>
                                        {amountErrors}
                                    </Typography>
                                )}
                            </Box>
                            {/* Card Action */}
                            <Box style={{
                                padding: '16px',
                                textAlign: 'end',
                                
                            }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={(locationData?.type_of_card === null || locationData.total_amount_due <= 0 || locationData.total_amount_due > locationData.sum_of_amount_location ) ? true : false} // Disable Pay Now if no type is selected for this card
                                    onClick={() => handlePaymentSubmit(locationData?.locationId, locationData?.total_amount_due, locationData?.type_of_card)}
                                >
                                    Pay Now
                                </Button>
                            </Box>
                        </Card>
                    </Grid>
                ))}
            </Grid>}


            {policyType === "New Policy" && <NewPolicyPayment isPolicyNew = {true} />}
        </Box >

    )
}

export default PolicyTest


type PolicyPaymentType = {
    policyType?: string
}