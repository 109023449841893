import { combineReducers } from "@reduxjs/toolkit";
import userSlice from "./user.slice";
import customizationReducer from "features/theme/customizationReducer";
import actionSlice from "./action.slice";
import customerSlice from "./customer.slice";
import policySlice from "./policy.slice";
import quoteSlice from "./quote.slice";
import blockerSlice from "./blocker.slice";
import settingsSlice from "./settings.slice";
import locationSlice from "./location.slice";
import makePaymentSlice from "./makePayment.slice";
import referenceSlice from "./reference.slice";
import  rolodexSlice  from "./rolodex.slice";

const rootReducer = combineReducers({
  user: userSlice,
  customization: customizationReducer,
  action: actionSlice,
  customer: customerSlice,
  policy: policySlice,
  quote: quoteSlice,
  blocker : blockerSlice,
  settings: settingsSlice,
  location: locationSlice,
  makePayment: makePaymentSlice,
  reference: referenceSlice,
  rolodex : rolodexSlice ,
});

export default rootReducer;
