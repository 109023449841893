import NavigationScroll from "container/layout/NavigationScroll";
import { lazy } from "react";
import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";
import Loader from "ui-component/Loader";
import { AuthProvider } from "./auth.provider";
import GuestRoute from "./GuestRoute";
import ProtectedUserRoute from "./ProtectedRoute";
import Remainder from "pages/remainder";
import Vehicle_List from "pages/vehicle/vehiclecustomerlist";
import VehicleViewCustomer from "pages/vehicle/vehiclecustomerview";
import ReminderCreate from "pages/remainder/create";
import Testimonial from "pages/setting/Testimonial";
import NotFound from "pages/pagenotfound/Notfound";
import TestimonialList from "pages/setting/TestimonialList";
import PolicyBalance from "pages/dashboard/PolicyBalance";
import DailyLedger from "pages/dashboard/DailyLedger";
import PaymentToCarrier from "pages/dashboard/PaymentToCarrier";
import PolicyCollection from "pages/dashboard/PolicyCollection";
import DdcReports from "pages/dashboard/Ddc";
import Landing from "pages/setting/Landing";
import PolicyCancellationForm from "pages/policy/cancel/PolicyCancelForm";
import { Roles } from "helpers/enum.helpers";
import EmailConfig from "pages/setting/EmailConfig";
import SmsConfig from "pages/setting/SmsConfig";
import PaymentConfig from "pages/setting/location/PaymentConfig";
import Location from "pages/setting/location/Location";
import LocationCreate from "pages/setting/location";
import WholesellerCreate from "pages/setting/wholeseller/WholesellerCreate";
import WholesellerView from "pages/setting/wholeseller/WholesellerView";
import RolodexView from "pages/setting/rolodex/RolodexView";
import LocationView from "pages/setting/location/view";
import SubscriptionPlans from "pages/Subscriptions";
import Paymentsuccess from "pages/paymentsucess/paymentsuccess";
import Paymentfailed from "pages/paymentfailed/paymentfailed";
import NotificationSection from "pages/notification/Notification";
import CustomerPolicyAdd from "pages/Customer_policy/addCustomer_policy";
import CustomerPolicy from "pages/Customer_policy";
import TransactionHistory from "pages/TransactionHistory";
import SuspensionPolicy from "pages/dashboard/SuspensionPolicy";
import PolicyCountBasedOnInsuranceCompany from "pages/reports/PolicyCoutnBasedOnInsuranceCompany";
import CustomerListReport from "pages/reports/CustomerListReport";
import FeesReport from "pages/reports/FeesReport";
import ReinstatementReport from "pages/reports/ReinstatementReport";
import ReinstatementDueReport from "pages/reports/ReinstatementDueReport";
import PolicyRenewalReport from "pages/reports/PolicyRenewalReport";
import ProspectReport from "pages/reports/ProspectReport";
import RenewalReport from "pages/reports/RenewalReport";
import DashboardSettings from "pages/dashboard/customDashboard";
import FinanceCompanyList from "pages/setting/FinanceCompany/FinanceCompanyList";
import FinanceCompanyCreate from "pages/setting/FinanceCompany/FinanceCompanyCreate";
import RefernceList from "pages/setting/reference/list/RefernceList";
import ReferecnceIndex from "pages/setting/reference/create";
import FeeView from "pages/setting/Fee/FeeView";
import FinanceCompanyView from "pages/setting/FinanceCompany/FinanceCompanyView";
import FeeList from "pages/setting/Fee/FeeList";
import FeeCreate from "pages/setting/Fee/FeeCreate";
import AgencyCommissionList from "pages/setting/AgencyCommision/list";
import AgencyCommissionCreate from "pages/setting/AgencyCommision/create/AgencyCommissionCreate";
import QuickReciptList from "pages/QuickReceipt";
import CreateReceipt from "pages/QuickReceipt/create/CreateReceipt";
import CustomerrView from "pages/Customer_policy/view";
import NewMakePayment from "pages/NewMakePayment.tsx";
import NewCustomer from "pages/NewMakePayment.tsx/NewCustomer/NewCustomer";
import PolicyTest from "pages/NewMakePayment.tsx/ExistingCustomer/PolicyListPayment";
import ExistingCustomerIndex from "pages/NewMakePayment.tsx/ExistingCustomer";
import NewCustomerIndex from "pages/NewMakePayment.tsx/NewCustomer";
import PosIntegration from "pages/setting/PosIntegration";


/* guest routes */
const PageNotFound = lazy(() => import("pages/pagenotfound"));

const ComingSoon = lazy(() => import("forms/hoc/coming-soon"));

const Login = lazy(() => import("pages/authentication/Login"));
const SetPassword = lazy(() => import("pages/authentication/SetPassword"));
const ForgotPassword = lazy(
  () => import("pages/authentication/ForgotPassword")
);
const OtpPage = lazy(() => import("pages/authentication/OtpPage"));
const LandingPage = lazy(() => import("pages/landingPage/Index"));
const MakePayments = lazy(() => import("pages/Make-payments/Index"));
/* layout component */
const Layout = lazy(() => import("../layout/layout/MainLayout"));

// Pages
const CustomerDashboard = lazy(
  () => import("pages/dashboard/CustomerDashboard")
);
const ClientList = lazy(() => import("pages/clients/list"));
const CreateClient = lazy(() => import("pages/clients/create"));
const CreateRolodex = lazy(() => import("pages/setting/rolodex/index"));

const ClientView = lazy(() => import("pages/clients/view"));
const CreatePolicy = lazy(() => import("pages/policy/create"));
const ViewPolicy = lazy(() => import("pages/policy/view"));
const PolicyList = lazy(() => import("pages/policy/list"));
const CreateQuote = lazy(() => import("pages/quote/create"));

const Dashboard = lazy(() => import("pages/dashboard"));
const Employees = lazy(() => import("pages/employee/list"));
const EmployeeCreate = lazy(() => import("pages/employee/create"));
const QuotationsList = lazy(() => import("pages/quote/list"));
const QuotationView = lazy(() => import("pages/quote/view"));
const Profile = lazy(() => import("pages/profile"));
const ResetPassword = lazy(() => import("pages/resetPassword"));
const Subscription = lazy(() => import("pages/subscription/Subscription"));
const KnowledgeBase = lazy(() => import("pages/knowledgeBase/KnowledgeBase"));
const Success = lazy(() => import("pages/policy/invoice/payment/success"));
const Decline = lazy(() => import("pages/policy/invoice/payment/decline"));
const MakePaymentSuccess = lazy(
  () => import("pages/Make-payments/MakePaymentSuccess")
);
const MakePaymentCancel = lazy(
  () => import("pages/Make-payments/MakePaymentCancel")
);
const Reconciliation = lazy(() => import("pages/reconciliation"));
const Reports = lazy(() => import("pages/reports"));
const Chequelist = lazy(() => import("pages/cheque"));
const ChequeEdit = lazy(() => import("pages/cheque/edit"));
const ChequeView = lazy(() => import("pages/cheque/view"));
const DisplayCard = lazy(() => import("settings_card/Displaycard"));
const Company = lazy(() => import("pages/setting/company"));
const MasterType = lazy(() => import("pages/setting/masterType"));
const Industry = lazy(() => import("pages/setting/Industry"));
const Wholeseller = lazy(() => import("pages/setting/Wholeseller"))
const Rolodex = lazy(() => import("pages/setting/Rolodex"))
const DocuSign = lazy(() => import("../pages/docusign"));
const AccordDocuSign = lazy(() => import("../pages/docusignAccord"));
const DocuSignVerify = lazy(() => import("../pages/docusign/verification"));
const EndorsementNotification = lazy(() => import("../pages/endorsement"));
const AllDocuments = lazy(() => import("../pages/documents"));
const DriverChangeRequest = lazy(() => import("../pages/clients/view/changeRequest/driver"));
const VehicleChangeRequest = lazy(() => import("../pages/clients/view/changeRequest/vehicle"));
const appLoader = () => {
  return <Loader />;
};

const AuthLayout = () => (
  <AuthProvider>
    <NavigationScroll>
      <Outlet />
    </NavigationScroll>
  </AuthProvider>
);

const RoutePath = createBrowserRouter(
  [
    {
      path: "/",
      element: <AuthLayout />,
      children: [
        {
          path: "/",
          element: <GuestRoute />,
          loader: appLoader,
          children: [
            {
              path: "",
              element: <Navigate to="quote" replace />,
            },
            {
              path: "/login",
              element: <Login />,
            },
            {
              path: "/make-payments",
              element: <MakePayments />,
            },
            {
              path: "/makePayment-success",
              element: <MakePaymentSuccess />,
            },
            {
              path: "/makePayment-cancel",
              element: <MakePaymentCancel />,
            },
            {
              path: "/test",
              element: <PolicyTest />,
            },
            {
              path:"/make-payment",
              element: <NewMakePayment/>
            },
            {
              path: "/make-payment/existing-customer",
              element: <ExistingCustomerIndex/>,
            },
            {
              path: "/make-payment/new-customer",
              element: <NewCustomerIndex/>,
            },
            {
              path: "/domain-not-found",
              element: <NotFound />,
            },
            {
              path: "/setpassword",
              element: <SetPassword />,
            },
            {
              path: "/forgotpassword",
              element: <ForgotPassword />,
            },  
            {
              path: "/generateotp",
              element: <OtpPage />,
            },
            {
              path: "/quote",
              element: <LandingPage />,
            },
          ],
        },
        {
          path: "/",
          element: (
            <ProtectedUserRoute
              roles={[Roles.Customer, Roles.TenantAdmin, Roles.TenantEmployee]}
            />
          ),
          children: [
            {
              path: "",
              element: <Layout />,
              children: [
                {
                  path: "/quotes",
                  element: <QuotationsList />,
                },
                {
                  path: "/customer-policy",
                  element: <CustomerPolicy />,
                },
                {
                  path: "/customer-policy/create",
                  element: <CustomerPolicyAdd />,
                },
                {
                  path: "/customer-policy/edit/:id",
                  element: <CustomerPolicyAdd />,
                },
                {
                  path: "/customer-policy/view/:id",
                  element: <CustomerrView />,
                },
                // {
                //   path: "/customer-policy/add",
                //   element: <CustomerPolicyLocation open={false} handleClose={undefined} handleFormSubmit={undefined} />,
                // },
                {
                  path: "transaction-history",
                  element: <TransactionHistory />,
                },
                {
                  path: "/quote/create",
                  element: <CreateQuote />,
                },
                {
                  path: "/quote/edit/:id",
                  element: <CreateQuote />,
                },
                {
                  path: "/quote/view/:id",
                  element: <QuotationView />,
                },
                {
                  path: "/policy",
                  element: <PolicyList />,
                },
                {
                  path: "/customer/Vehicle",
                  element: <Vehicle_List />,
                },
                {
                  path: "/customer/Vehicle/view/:id",
                  element: <VehicleViewCustomer />,
                },
                {
                  path: "/customers/policy/view/:id",
                  element: <ViewPolicy />,
                },
                {
                  path: "/resetPassword",
                  element: <ResetPassword />,
                },
                {
                  path: "/subscription",
                  element: <Subscription />,
                },
                {
                  path: "/subscriptionplans",
                  element: <SubscriptionPlans />,
                },
                {
                  path: "/knowledgeBase",
                  element: <KnowledgeBase />,
                },
                {
                  path: "/coming_soon",
                  element: <ComingSoon />,
                },
                {
                  path: "/notification",
                  element: <NotificationSection />,
                },
                {
                  path: `/customer/policy/docusign-verify`,
                  element: <DocuSignVerify />,
                },
              ],
            },
          ],
        },
        {
          path: "/",
          element: <ProtectedUserRoute roles={[Roles.TenantAdmin]} />,
          children: [
            {
              path: "",
              element: <Layout />,
              children: [
                {
                  path: "/settings",
                  element: <DisplayCard />,
                },
                {
                  path: "/settings/insurance",
                  element: <Company />,
                },
                {
                  path: "/settings/masters",
                  element: <MasterType />,
                },
                {
                  path: "/settings/industry",
                  element: <Industry />,
                },
                {
                  path: "/settings/Wholeseller",
                  element: <Wholeseller />,
                },
                {
                  path: "/settings/Rolodex",
                  element: <Rolodex />,
                },
                {
                  path: "/settings/landing",
                  element: <Landing />,
                },
                {
                  path: "testimonial",
                  element: <Testimonial />,
                },
                {
                  path: "reports/policyBalance",
                  element: <PolicyBalance />,
                },
                {
                  path: "reports/policy-count",
                  element: <PolicyCountBasedOnInsuranceCompany />,
                },
                {
                  path: "reports/customer-list",
                  element: <CustomerListReport />,
                },
                {
                  path: "reports/fees-report",
                  element: <FeesReport />,
                },
                {
                  path: "reports/reinstatement-report",
                  element: <ReinstatementReport />,
                },
                {
                  path: "reports/reinstatement-due-report",
                  element: <ReinstatementDueReport />,
                },
                {
                  path: "reports/policy-renewal-report",
                  element: <PolicyRenewalReport />,
                },
                {
                  path: "reports/prospects-report",
                  element: <ProspectReport />,
                },
                {
                  path: "reports/policy-renewed-report",
                  element: <RenewalReport />,
                },
                {
                  path: "reports/paymentToCarrier",
                  element: <PaymentToCarrier />,
                },
                {
                  path: "reports/dailyLedger",
                  element: <DailyLedger />,
                },
                {
                  path: "reports/policyCollection",
                  element: <PolicyCollection />,
                },
                {
                  path: "reports/ddc",
                  element: <DdcReports />,
                },
                {
                  path: "reports/suspendedPolicies",
                  element: <SuspensionPolicy />,
                },
                {
                  path: "testimonial/edit/:id",
                  element: <Testimonial />,
                },
                {
                  path: "/settings/testimonial/list",
                  element: <TestimonialList />,
                },
                // {
                //   path:"/settings/paymentConfig",
                //   element:<PaymentConfig />,
                // },
                {
                  path: "/settings/emailConfig",
                  element: <EmailConfig />,
                },
                {
                  path: "/settings/smsConfig",
                  element: <SmsConfig />,
                },
                {
                  path: "/settings/posIntegration",
                  element: <PosIntegration />
                },
                {
                  path: "settings/financeCompany",
                  element: <FinanceCompanyList />
                },
                {
                  path: "settings/financeCompany/create",
                  element: <FinanceCompanyCreate />
                },
                {
                  path: "settings/financeCompany/edit/:id",
                  element: <FinanceCompanyCreate />
                },
                {
                  path: "/settings/financeCompany/view/:id",
                  element: <FinanceCompanyView/>,
                },
                {
                  path: "settings/fee",
                  element: <FeeList />
                },
                {
                  path: "settings/fee/create",
                  element: <FeeCreate />
                },
                {
                  path: "settings/fee/edit/:id",
                  element: <FeeCreate />
                },
                {
                  path: "settings/AgencyCommission",
                  element: <AgencyCommissionList />
                },
                {
                  path: "settings/AgencyCommission/create",
                  element: <AgencyCommissionCreate />
                },
                {
                  path: "settings/AgencyCommission/edit/:id",
                  element: <AgencyCommissionCreate />
                },
                {
                  path: "settings/reference",
                  element: <RefernceList />
                },
                {
                  path: "settings/reference/create",
                  element: <ReferecnceIndex />
                },
                {
                  path: "settings/reference/edit/:id",
                  element: <ReferecnceIndex />
                },
                {
                  path: "/settings/location",
                  element: <Location />,
                },
                {
                  path: "/settings/location/create",
                  element: <LocationCreate />,
                },
                {
                  path: "/settings/wholeseller/create",
                  element: <WholesellerCreate />,
                },
                {
                  path: "/settings/wholeseller/edit/:id",
                  element: <WholesellerCreate />,
                },
                {
                  path: "/settings/location/edit/:id",
                  element: <LocationCreate />,
                },
                {
                  path: "/settings/location/view/:id",
                  element: <LocationView />,
                },
                {
                  path: "/settings/wholeseller/view/:id",
                  element: <WholesellerView />,
                },
                {
                  path: "/settings/fee/view/:id",
                  element: <FeeView/>,
                },
                
                {
                  path: "/settings/Rolodex/view/:id",
                  element: <RolodexView />,
                },
                {
                  path: "/employees",
                  element: <Employees />,
                },
                {
                  path: "/employee/create",
                  element: <EmployeeCreate />,
                },
                {
                  path: "/employee/edit/:id",
                  element: <EmployeeCreate />,
                },
                {
                  path: "/employee/view/:id",
                  element: <EmployeeCreate />,
                },
                {
                  path: "/quickRecipt",
                  element: <QuickReciptList />,
                },
                {
                  path: "/quickRecipt/create",
                  element: <CreateReceipt />,
                },
                {
                  path: "/quickRecipt/edit/:id",
                  element: <CreateReceipt />,
                },
                {
                  path: "/reconciliation",
                  element: <Reconciliation />,
                },
                {
                  path: "/reports",
                  element: <Reports />,
                },
                {
                  path: "settings/reminder",
                  element: <Remainder />,
                },
                {
                  path: "settings/reminder/create",
                  element: <ReminderCreate />,
                },
                {
                  path: "settings/reminder/edit/:id",
                  element: <ReminderCreate />,
                },
                {
                  path: "settings/reminder/view/:id",
                  element: <ReminderCreate />,
                },
              ],
            },
          ],
        },
        {
          path: "/",
          element: (
            <ProtectedUserRoute
              roles={[Roles.TenantAdmin, Roles.TenantEmployee]}
            />
          ),
          children: [
            {
              path: "",
              element: <Layout />,
              children: [
                {
                  path: "/dashboard",
                  element: <Dashboard />,
                },
                {
                  path: "/profile",
                  element: <Profile />,
                },
                {
                  path: "/customers",
                  element: <ClientList />,
                },
                {
                  path: "/customers/create",
                  element: <CreateClient />,
                },
                {
                  path: "/rolodex/create",
                  element: <CreateRolodex />,
                },
                {
                  path: "/rolodex/edit/:id",
                  element: <CreateRolodex />,
                },
                {
                  path: "/customers/edit/:id",
                  element: <CreateClient />,
                },
                {
                  path: "/customers/view/:id",
                  element: <ClientView />,
                },
                {
                  path: "/customers/policy/create/:id",
                  element: <CreatePolicy />,
                },
                {
                  path: "/customers/policy/edit/:id",
                  element: <CreatePolicy />,
                },
                {
                  path: "customers/policy/cancel/:id",
                  element: <PolicyCancellationForm />,
                },
                {
                  path: "/chequelist",
                  element: <Chequelist />,
                },
                {
                  path: "/cheque/edit/:id",
                  element: <ChequeEdit />,
                },
                {
                  path: "/cheque/view/:id",
                  element: <ChequeView />,
                },
                {
                  path: `/customer/policy/cancel/document-sign`,
                  element: <DocuSign />,
                },
                {
                  path: `/endorsements`,
                  element: <EndorsementNotification />,
                },
                {
                  path: `/all-documents`,
                  element: <AllDocuments />,
                },
                {
                  path: `/policy/driverchangerequest`,
                  element: <DriverChangeRequest />,
                },
                {
                  path: `/policy/vehiclechangerequest`,
                  element: <VehicleChangeRequest />,
                },
              ],
            },
          ],
        },
        {
          path: "/",
          element: <ProtectedUserRoute roles={[Roles.Customer]} />,
          children: [
            {
              path: "",
              element: <Layout />,
              children: [
                {
                  path: "/customer/dashboard",
                  element: <CustomerDashboard />,
                },
                {
                  path: "/customerprofile",
                  element: <ClientView />,
                },
                {
                  path: "/payment-success",
                  element: <Success />,
                },
                {
                  path: "/payment-decline",
                  element: <Decline />,
                },
                {
                  path: "/payment-cancel",
                  element: <Decline />,
                },
                {
                  path: `/customer/policy/document-sign`,
                  element: <DocuSign />,
                },
                {
                  path: `/customer/policy/Accord-document-sign`,
                  element: <AccordDocuSign />,
                },
              ],
            },
          ],
        },
        {
          path: "*",
          element: <PageNotFound />,
        },
        {
          path: "/payment/failed",
          element: <Paymentfailed />,
        },
        {
          path: "/payment/success",
          element: <Paymentsuccess />,
        },
        {
          path: "404",
          element: <PageNotFound />,
        },
      ],
    },
  ],
  {
    basename: "/",
  }
);

export default RoutePath;
