import { Box, Grid } from "@mui/material";
import FormLayout from "component/form";
import Loading from "container/loading";
import { commonService } from "forms/helpers/common.service";
import { Success } from "forms/helpers/toast.helper";
import { InputField } from "forms/hoc/formfield";
import useForm from "forms/hooks/useForm";
import { posIntegrationPagevalidation, posIntegrationPagevalidationSchema } from "forms/hooks/validateRules";
import MainContainerWrapper from "layout/wrappers/MainContainerWrapper";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CryptoJS from 'crypto-js';


export default function PosIntegration() {

    const [loading, setLoading] = useState<any>(false);
    const [isUsernameDisable, setUsernameDisable] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const navigate = useNavigate()

    const initialValues = {
        username: "",
        password: "",
    }

    const handleBack = () => {
        navigate(-1);
    };

    const formSubmit = () => {
        let secretKey : string = process.env.REACT_APP_POS_SECRET || "PosSecrectForBrokerPortal";
        const encryptedPassword = CryptoJS.AES.encrypt(values?.password, secretKey).toString();
        
        // setLoading(true);
        const api = `/pos-integrations`;
        let payload = {
            username : values?.username,
            password : encryptedPassword
        }
        commonService.postService(api, payload)
            .then((res) => {
                setLoading(false);
                Success(res?.data?.message)
                handleBack()
            })

    }

    const { t } = useTranslation();
    const importModalT = posIntegrationPagevalidationSchema(t);

    const {
        errors,
        values,
        handleChange,
        handleSubmit,
        setPatchValue,
        setUpdateValue,

    } = useForm(initialValues, formSubmit, posIntegrationPagevalidation, importModalT)


    useEffect(() => {
        getPosIntegrationData();
    }, [])

    const getPosIntegrationData = () => {
        setLoading(true);
        commonService.
            getServices(`/pos-integrations`)
            .then((res) => {
                const result = res?.data?.data;
                console.log(result)
                if (result?.length > 0) {
                    setPatchValue({
                        username: result?.[0]?.username
                    });
                    setUsernameDisable(true);
                }
                setLoading(false);
            }).catch(err => console.log(err))
    }

    return (
        <>
            <MainContainerWrapper headingText={t("POS Integration")} isBackButton={true}>
                <Box sx={{ p: 3 }}>
                    {loading && <Loading />}
                    <FormLayout
                        handleSubmit={handleSubmit}
                        submitButtonText={!isUsernameDisable ? t("Submit") : t("Update")}
                    >
                        <>
                            <Grid container columnSpacing={2}>
                                <Grid item xs={12} lg={4}>
                                    <InputField
                                        fieldName={t("Username")}
                                        values={values?.username || ""}
                                        name="username"
                                        errors={errors?.username}
                                        handleChange={handleChange}
                                        id="username"
                                        disabled={isUsernameDisable}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <InputField
                                        fieldName={t("Password")}
                                        values={values.password}
                                        name="password"
                                        handleChange={handleChange}
                                        errors={errors.password}
                                        adorament="endAdornment"
                                        position="end"
                                        Icon={showConfirmPassword ? Visibility : VisibilityOff}
                                        handleIconClick={handleClickShowConfirmPassword}
                                        type={showConfirmPassword ? "text" : "password"}
                                        length={16}
                                    />
                                </Grid>
                            </Grid>
                        </>

                    </FormLayout>

                </Box>
            </MainContainerWrapper>
        </>
    )

}