import { Box, Button, Grid, Typography } from '@mui/material'
import ModalComponent from 'component/modal'
import React from 'react'
import CloseIcon from "@mui/icons-material/Close";
import { InputField } from 'forms/hoc/formfield';
import { useTranslation } from 'react-i18next';
import { voidReasonValidation, voidReasonValidationSchema } from 'forms/hooks/validateRules';
import useForm from 'forms/hooks/useForm';
import { commonService } from 'forms/helpers/common.service';
import { Success } from 'forms/helpers/toast.helper';

function VoidReasonModel({
    open,
    handleClose,
    receiptId

}: VoidReasonModelType) {

    const { t } = useTranslation()


    const voidReasonValidationSchemaT = voidReasonValidationSchema(t)

    const formSubmit = () => {
        commonService.postService(`quick-receipt/void-unvoid/${receiptId}`, {
            ...values,
            is_void: true
        }).then((res:any)=> {
            Success(res?.data?.message)
            handleClose()
        }).catch((err:any)=> {
            console.log(err)
        })
    }
    const {
        errors,
        values,
        handleChange,
        handleSubmit,
        setPatchValue,
        setUpdateValue,
    } = useForm({}, formSubmit, voidReasonValidation, voidReasonValidationSchemaT);


    return (
        <>
            <ModalComponent
                open={open}
                handleClose={handleClose}
            >

                <Box>
                    <CloseIcon
                        sx={{
                            position: "absolute",
                            top: 25,
                            right: 18,
                            color: "red",
                            cursor: "pointer",
                        }}
                        onClick={handleClose}
                    />{" "}
                    <Typography variant="h2" sx={{ textAlign: "center", marginBottom: 2 }}>
                        Void Reason
                    </Typography>
                    <Grid container>
                        <Grid item xs={12}>
                            <InputField
                                fieldName={t("Reason for Voiding this Receipt?")}
                                values={values?.void_reason || ""}
                                name="void_reason"
                                errors={errors?.void_reason}
                                handleChange={handleChange}
                                id="void_reason"
                                isRequired={false}
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{ display: "flex", justifyContent: "end" }}>
                        <Button
                            variant="outlined"
                            color="secondary" onClick={handleClose}
                            sx={{
                                marginRight: 1,
                                mt: 2,
                                height: "2.4rem"
                            }}
                        >
                            Cancel</Button>

                        <Button
                            sx={{
                                marginRight: 1,
                                mt: 2,
                                height: "2.4rem"
                            }}
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>

            </ModalComponent>
        </>
    )
}

export default VoidReasonModel


type VoidReasonModelType = {
    open: any,
    handleClose: any,
    receiptId: string
}