
import { Box, Button, Grid } from '@mui/material'
import FormLayout from 'component/form'
import Loading from 'container/loading';
import { commonService } from 'forms/helpers/common.service';
import { errorMessage } from 'forms/helpers/function.helper';
import { Failed } from 'forms/helpers/toast.helper';
import { GroupRadioButton, InputField, SelectField } from 'forms/hoc/formfield';
import useForm from 'forms/hooks/useForm';
import { newPolicyPaymentvalidation, newPolicyPaymentvalidationSchema, policyNumberSchema, policyNumbervalidation } from 'forms/hooks/validateRules';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'store/store';
import { paymentType, paymentTypeEnum } from 'utils/constant.utils';

function NewPolicyPayment( { isPolicyNew = false} : {isPolicyNew?: boolean}) {

    const { t } = useTranslation();

    const [loading, setLoading] = useState<any>(false);
    const [locationList, setLocationList] = useState([]);
    const [payableAmount, setPayableAmount] = useState<number | string>("$0.00");
    const [cc_percentage, setCc_percentage] = useState<number | string>("");
    const [dc_percentage, setDc_percentage] = useState<number | string>("");
    const [achFlatValue, setachFlatValue] = useState<number | string>("")



    const SchemaT = newPolicyPaymentvalidationSchema(t)

    const makePayment = useAppSelector((state) => state?.makePayment?.makepayment)

    const policyType = makePayment?.policyType?.policy_type

    const newCustomerToken = makePayment?.newCustomerToken
    const existingCustomerToken = makePayment?.existingCustomerToken


    const location = useLocation()

    const pathname = location.pathname;
    const lastRoute = pathname.split("/").pop(); // Get the last segment


    console.log("ssssss", lastRoute);


    useEffect(() => {
        getLocationData()
        getPercentageDeatils()
    }, [])

  

    const getPercentageDeatils = () => {
        commonService.postService(`/make_payment/get-percentage-details`, {
            token: lastRoute === "new-customer" ? newCustomerToken : existingCustomerToken
        })
            .then((res: any) => {
                console.log("resssssss", res?.data);
                setCc_percentage(res?.data?.data?.cc_percentage)
                setDc_percentage(res?.data?.data?.dc_percentage)
                setachFlatValue(res?.data?.data?.ach_flat_value)
            }).catch((err: any) => {
                Failed(
                    errorMessage(err, "")
                )
            })
    }

    const getLocationData = () => {
        commonService.getServices(`location/location-without-token`)
            .then((res: any) => {

                const locData = res?.data?.data.map((e: any) => {
                    return {
                        name: e?.name,
                        value: e?.id
                    }
                })

                setLocationList(locData)
            }).catch((err: any) => { })
    }
    const formSubmit = () => {

        const bodyData = lastRoute === "new-customer" ? {
            ...values,
            amount:payableAmount,
            token: newCustomerToken,
            transaction_type: paymentTypeEnum[values.type_of_card],
            who_has_to_pay_the_transaction_fee: "Driver",
            is_existing_policy: false
        } : {
            ...values,
            amount:payableAmount,
            token: existingCustomerToken,
            transaction_type: paymentTypeEnum[values.type_of_card],
            who_has_to_pay_the_transaction_fee: "Driver",
            is_existing_policy: isPolicyNew? false : true
        }

        commonService.postService(`make_payment/payment`,
            bodyData,
        ).then((res: any) => {

            window.open(res?.data?.url, "_self");

        }).catch((err: any) => {
            Failed(
                errorMessage(err, "Something went wrong, please try again later")
            );
        })

    }


    const {
        errors,
        values,
        handleChange,
        handleSubmit,
        setUpdateValue,
        setUpdateFormValue,
        setPatchValue,
    } = useForm({}, formSubmit, newPolicyPaymentvalidation, SchemaT);


    useEffect(() => {
        if (values?.amount) {
            const amount = parseFloat(values?.amount); // Ensure amount is a number
            let percentage = 0;
            let calculatedAmount = amount;

            if (values.type_of_card === "credit") {
                percentage = parseFloat(cc_percentage as string) / 100; // Ensure cc_percentage is a number
                calculatedAmount = amount + amount * percentage;
            } else if (values.type_of_card === "debit") {
                percentage = parseFloat(dc_percentage as string) / 100; // Ensure dc_percentage is a number
                calculatedAmount = amount + amount * percentage;
            } else if (values.type_of_card === "ach") {
                const achFlatValue1 = parseFloat(achFlatValue as string|| "0"); // Ensure ach_flat_value is a number
                calculatedAmount = amount + achFlatValue1;
            }
            setPayableAmount(calculatedAmount.toFixed(2)); // Set amount with 2 decimal places
        } 

    }, [values.amount, values.type_of_card])
    
    return (
        <Box sx={{
            maxWidth: "416px",
            margin: "auto"
        }}>
            {loading && <Loading />}
            <Grid
                container
                height={"80%"}
                sx={{ p: 7 }}
                alignItems={"center"} // Vertically center
                justifyContent={"center"} // Horizontally center
            >
                <Grid item lg={12}>
                    <Box
                        sx={{ overflow: "hidden" }}
                    >
                        <FormLayout
                            handleSubmit={handleSubmit}
                            submitButtonText="Proceed"
                            isSaveButton={false}
                        >
                            <Grid container columnSpacing={2} direction="column">
                                <Grid item xs={12}>
                                    <Box sx={{ width: "300px" }}>
                                        <SelectField
                                            fieldName={t("Location")}
                                            values={values?.location || null}
                                            name="location"
                                            errors={errors?.location}
                                            handleChange={handleChange}
                                            options={locationList || []}
                                            id="location"
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12}>
                                    <Box sx={{ width: "300px" }}>
                                        <InputField
                                            fieldName={t("Enter Amount")}
                                            values={values?.amount || ""}
                                            name="amount"
                                            errors={errors?.amount}
                                            handleChange={handleChange}
                                            id="amount"
                                            onBlur={() => setUpdateFormValue("amount", parseFloat(values?.amount || 0).toFixed(2))}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} >
                                    <Box sx={{
                                        width: "100%",
                                        marginTop: "25px",
                                        marginBottom: "7px",
                                        border: "1px solid black",
                                        borderRadius: "5px",
                                        padding: "5px",
                                        bgcolor: "#f2f2f2",
                                        textAlign: "center"
                                    }}>
                                        <GroupRadioButton
                                            isRow={false}
                                            fieldName={t("Mode of Payment")}
                                            values={values?.type_of_card || ""}
                                            errors={errors?.type_of_card}
                                            name="type_of_card"
                                            handleChange={handleChange}
                                            options={paymentType}
                                            id="type_of_card"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sx={{ alignItems: "center" }} >
                                    <Box sx={{ alignItems: "center" }} >
                                        <Button
                                            onClick={handleSubmit}
                                            sx={{
                                                mt: 4,
                                                fontSize: "16px",
                                                width: "300px",
                                            }}
                                        >

                                            {t("Proceed")}
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>

                        </FormLayout>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default NewPolicyPayment