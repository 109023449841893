import { dateTimeFormat } from "forms/hooks/customHook";
import * as yup from "yup";
import { PhoneNumberUtil } from "google-libphonenumber";
import dayjs from "dayjs";
import { vin } from "@form-validation/validator-vin";

import { Roles } from "helpers/enum.helpers";
import { useTranslation } from "react-i18next";
import { scheduler } from "timers/promises";
const weightRegex = RegExp(/^(?!00000)[0-9]+(?:\.[0-9]+)?(?:\s*(kg|lbs))?$/);

const urlPattern =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

const SSNPattern = /^\d{3}-?\d{2}-?\d{4}$/;

const validTLDs = [
  "com",
  "org",
  "net",
  "in",
  "gov",
  "edu",
  "mil",
  "int",
  "info",
  "biz",
  "name",
  "museum",
  "areo",
  "coop",
  "pro",
  "jobs",
  "mobi",
  "tel",
  "travel",
]; // Add more TLDs as needed
const tldRegex = validTLDs.join("|");

const usZipRegex = RegExp(/^(?!00000)[0-9]{5}(?:-[0-9]{4})?$/);
const emailRegex = RegExp(
  new RegExp(`^[\\w.+-]+@[a-zA-Z\\d.-]+\\.(${tldRegex})(?:\\.[a-zA-Z]{2,})?$`)
);

const passwordPattern =
  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!#$%&()*+-/:;<=>?@[\]^{|}~])([a-zA-Z0-9!#$%&()*+-/:;<=>?@[\]^{|}~]{8,16})$/;

const VINRegex = RegExp(/^[A-Za-z0-9]{17}$/);

function getErrorsFromValidationError(validationError: any) {
  const FIRST_ERROR = 0;
  return validationError.inner.reduce((errors: any, error: any) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR],
    };
  }, {});
}

function handleErrorMeg(msg: any, schema: any) {
  try {
    schema.validateSync(msg, { abortEarly: false });
    return {};
  } catch (error) {
    return getErrorsFromValidationError(error);
  }
}

const phoneNumberUtil = PhoneNumberUtil.getInstance();

// Custom Yup validation method for phone number validation
const phoneSchema = (isRequired: boolean, errorText: string) =>
  yup.string().test("phone", errorText, function (value) {
    if (!value) return true; // Allow empty values
    if (value.length <= 3 && !isRequired) {
      return true;
    }
    try {
      const phoneNumber = phoneNumberUtil.parse(value);
      return phoneNumberUtil.isValidNumber(phoneNumber);
    } catch (error) {
      return false;
    }
  });

const vinSchema = (isRequired: boolean, errorText: string) =>
  yup.string().test("vin", errorText, function (value) {
    if (!value) return true;
    if (value.length && !isRequired) {
      return true;
    }
    try {
      const res2 = vin().validate({
        value: value,
        options: {
          message: "Invalid vin",
        },
      });
      return res2.valid;
    } catch (err) {
      return false;
    }
  });

export let LoginVerificationSchema = (trans: any) => yup.object().shape({
  // email: yup
  //   .string()
  //   .trim()
  //   .matches(emailRegex, trans("Invalid email id"))
  //   .required(trans("email_required")),

  email: yup
    .string()
    .trim()
    .test(
      "is-valid-email-or-customer-id",
      trans("Must be a valid email/customer ID"),
      function (value) {
        if (!value) return false; // Required field

        // If the value contains an "@" symbol, validate it as an email
        if (value.includes("@")) {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return emailRegex.test(value); // Valid email
        }

        // If there's no "@" symbol, treat it as a customer ID (additional rules can be added here)
        const customerIdRegex = /^[0-9]+$/; // Assuming alphanumeric for customer ID
        return customerIdRegex.test(value); // Valid customer ID
      }
    )
    .required(trans("email_or_customer_id_required")),
  password: yup.string().required(trans("password_required")),
});

export function LoginValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export let EmailSchema = (trans: any) => yup.object().shape({
  // email: yup
  //   .string()
  //   .trim()
  //   .matches(emailRegex, trans("Invalid email id"))
  //   .required(trans("email_id_is_required")),

  email: yup
    .string()
    .trim()
    .test(
      "is-valid-email-or-customer-id",
      trans("Must be a valid email/customer ID"),
      function (value) {
        if (!value) return false; // Required field

        // If the value contains an "@" symbol, validate it as an email
        if (value.includes("@")) {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return emailRegex.test(value); // Valid email
        }

        // If there's no "@" symbol, treat it as a customer ID (additional rules can be added here)
        const customerIdRegex = /^[0-9]+$/; // Assuming alphanumeric for customer ID
        return customerIdRegex.test(value); // Valid customer ID
      }
    )
    .required(trans("email_or_customer_id_required")),

});

export function EmailValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export function setPasswordValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export function resetPasswordValidation(values: any) {
  return handleErrorMeg(values, resetPasswordSchema);
}

export function changePasswordValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const changePasswordSchema = (trans: any) => yup.object().shape({
  oldPassword: yup
    .string()
    //.matches(passwordPattern, "Password Should Contain 8 Characters ( 1 Upper Case , 1 Lower Case , 1 Special Character (@, $, !, %, *, ?, or &) and 1 Numeric Value )")
    .min(8, trans("Password Must Be At Least 8 Characters"))
    .max(16, "Password Must Be aAt Most 32 Characters")
    .required(trans("Old_Password_is_Required")),
  password: yup
    .string()
    .matches(
      passwordPattern,
      trans("Password Should Contain 8 Characters ( 1 Upper Case , 1 Lower Case , 1 Special Character (@, $, !, %, *, ?, or &) and 1 Numeric Value )")
    )
    .required(trans("Password_is_Required")),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], trans("password_match")),
});

export const resetPasswordSchema = (trans: any) => yup.object().shape({
  password: yup
    .string()
    .matches(
      passwordPattern,
      "Password Should Contain 8 Characters ( 1 Upper Case , 1 Lower Case , 1 Special Character (@, $, !, %, *, ?, or &) and 1 Numeric Value )"
    )
    .required("Password is Required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], trans("password_match")),
});

export const setPasswordSchema = (trans: any) => yup.object().shape({
  password: yup
    .string()
    .matches(
      passwordPattern,
      trans("password_valid")
    )
    .required(trans("password_required")),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], trans("password_match")),
});

export const createClientSchema = (trans: any) => yup.object().shape({
  customer_type: yup
    .object()
    .required(trans("customer_type_required"))
    .typeError(trans("customer_type_required")),
  prefix: yup.string().nullable().optional(),
  first_name: yup.string().required(trans("first_name_required")),
  middle_name: yup.string().nullable().optional(),
  last_name: yup.string().required(trans("last_name_required")),
  suffix: yup.string().nullable().optional(),
  gender: yup.string().nullable().optional(),
  dob: yup.date().nullable().optional(),
  marital_status: yup.string().nullable().optional(),
  email: yup.string().trim().nullable().optional(),
  phone_number: yup.string().nullable().optional(),
  address: yup.string().nullable().optional(),
  date_terminated: yup.date().nullable().optional(),
  date_employed: yup.date().nullable().optional(),
  social_security: yup
    .string()
    .nullable()
    .optional()
    .test("is-valid-ssn", trans("ssn_invalid"), (value) => {
      if (value) {
        return yup.string().matches(SSNPattern).isValidSync(value);
      }
      return true; // Allow empty value
    }),

  // second insured
  second_prefix: yup.string().nullable().optional(),
  second_first_name: yup.string().when("customer_type", {
    is: (value: any) => value?.name === "Two Individuals",
    then: () => yup.string().required(trans("first_name_required_second")),
    otherwise: () => yup.string().nullable().optional(),
  }),
  second_middle_name: yup.string().optional(),
  second_last_name: yup.string().when("customer_type", {
    is: (value: any) => value?.name === "Two Individuals",
    then: () => yup.string().required(trans("last_name_required_second")),
    otherwise: () => yup.string().nullable().optional(),
  }),
  second_suffix: yup.string().nullable().optional(),
  second_gender: yup.string().nullable().optional(),
  second_dob: yup.date().nullable().optional(),
  second_marital_status: yup.string().nullable().optional(),
  second_date_terminated: yup.date().nullable().optional(),
  second_date_employed: yup.date().nullable().optional(),
  second_social_security: yup.string().nullable().optional(),

  // company validation
  company_name: yup.string().when("customer_type", {
    is: (value: any) => (value?.name !== "Two Individuals" && value?.name !== "Individual"),
    then: () => yup.string().required(trans("company_name_required")),
    otherwise: () => yup.string().nullable().optional(),
  }),
  dba_name: yup.string().nullable().optional(),
  tax_id: yup.string().nullable().optional(),
  employer_registration: yup.string().nullable().optional(),
  date_business_started: yup.string().nullable().optional(),
  incorporation_state: yup.string().nullable().optional(),
  second_email: yup.string().nullable().optional(),
  second_phone_number: yup.string().nullable().optional(),
  second_address: yup.string().nullable().optional(),
});

export function CreateClientValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}


export const createRolodexSchema = (trans: any) => yup.object().shape({
  prefix: yup.string().when("rolodexType", {
    is: (value: any) => value === "person",
    then: () => yup.string().required(trans("prefix_required")),
    otherwise: () => yup.string().nullable().optional(),
  }),
  company_name_one: yup.string().when("rolodexType", {
    is: (value: any) => value === "company",
    then: () => yup.string().required(trans("Company Name One Required")),
    otherwise: () => yup.string().nullable().optional(),
  }),
  company_name_two: yup.string().nullable().optional(),
  first_name: yup.string().when("rolodexType", {
    is: (value: any) => value === "person",
    then: () => yup.string().required(trans("first_name_required")),
    otherwise: () => yup.string().nullable().optional(),
  }),
  middle_name: yup.string().nullable().optional(),
  last_name: yup.string().when("rolodexType", {
    is: (value: any) => value === "person",
    then: () => yup.string().required(trans("last_name_required")),
    otherwise: () => yup.string().nullable().optional(),
  }),
  suffix: yup.string().nullable().optional(),
  gender: yup.string().nullable().optional(),
  title: yup.string().nullable().optional(),
  website: yup.string().nullable().optional(),
  dmv_code: yup.string().nullable().optional(),
  naci_code: yup.string().nullable().optional(),
  producer_code: yup.string().nullable().optional(),
  dob: yup.date().nullable().optional(),
  email: yup.string().trim().nullable().optional(),
  phone_number: yup.string().nullable().optional(),
  address: yup.string().nullable().optional(),
});

export function CreateRolodexValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export let policyCreateValidationSchema = (trans: any) => yup.object().shape({
  policyId: yup
    .string()
    .trim()
    .matches(emailRegex, trans("Invalid email id"))
    .required("Email id is required"),
  policyType: yup.string().required("Password is required"),
});

export function policyCreateValidation(values: any) {
  return handleErrorMeg(values, policyCreateValidationSchema);
}

export let emailModalSchema = (trans: any) => yup.object().shape({
  email: yup
    .string()
    .trim()
    .matches(emailRegex, trans("Invalid email id"))
    .required(trans("email_id_is_required")),
  email_type: yup.string().required(trans("email_type_is_required")),
  notes: yup.string().optional(),
  is_default: yup.boolean().optional(),
});

export let phoneModalSchema = (trans: any) => yup.object().shape({
  phone_type: yup.string().required(trans("Phone_type_is_required")),
  note: yup.string().optional(),
  phone: phoneSchema(true, trans("Invalid Phone Number")).required(
    (trans("Phone_is_required"))
  ),
  area_code: yup.number().required(trans("Area_code_is_required")),
  is_default: yup.boolean().optional(),
});

export let addressModalSchema = (trans: any) => yup.object().shape({
  address_type: yup
    .string()
    .required(trans("Please_select_your_address_type"))
    .typeError("Please select your address type"),
  address_line_1: yup.string().trim().required(trans("Address_Line_1_is_required")),
  address_line_2: yup.string().nullable(),
  city: yup.string().trim().required(trans("City_is_required")),
  state: yup
    .string()
    .required(trans("Please_select_your_state"))
    .typeError("Please select your state"),
  zip_code: yup
    .string()
    .matches(usZipRegex, "Invalid Zip Code")
    .required(trans("Zipcode_is_required")),
  is_default: yup.boolean().optional(),
});

export function AddressModalValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export function PhoneModalValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export function EmailModalValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}
export let createCompanyValidationSchema = (trans: any) => yup.object().shape({
  company_name: yup.string().required(trans("Company_name_is_required")),
  dba_name: yup.string().required(trans("DBA_name_is_required")),
  taxId: yup.string().required(trans("Tax_ID_is_required")),
  employee_registration: yup
    .string()
    .required(trans("Employee_Registration_is_required")),
  start_date: yup
    .date()
    .required(trans("Start_date_is_required"))
    .typeError("Start date is required"),
  state: yup.string().required(trans("State is required")),
});

export function createCompanyValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export let createBankValidationSchema = (trans: any) => yup.object().shape({
  // insurance_company: yup.string().required("Insurance Company is required"),
  bank: yup.string().required(trans("Bank_is_required")),
  routing_no: yup.number().required(trans("Routing_No_is_required")),
  account_no: yup.number().required(trans("Account_No_is_required")),
  account_type: yup.string().required(trans("Account_Type_is_required")),
  starting_check_no: yup.string().required(trans("Starting_Check_no_is_required")),
  name_of_account: yup.string().required(trans("Account_Name_is_required")),
  entity_type: yup.string().required(trans("Entity_type_is_required")),
  address: yup.string().required(trans("Address_is_required")),
  //bank_type:yup.string().required(trans("Type  is Required")),
});
export let createBankValidationSchemaForCustomer = (trans: any) => yup.object().shape({
  // insurance_company: yup.string().required("Insurance Company is required"),
  bank: yup.string().required(trans("Bank_is_required")),
  routing_no: yup.number().required(trans("Routing_No_is_required")),
  account_no: yup.number().required(trans("Account_No_is_required")),
  account_type: yup.string().required(trans("Account_Type_is_required")),
  starting_check_no: yup.string().required(trans("Starting_Check_no_is_required")),
  name_of_account: yup.string().required(trans("Account_Name_is_required")),
  entity_type: yup.string().required(trans("Entity_type_is_required")),
  address: yup.string().required(trans("Address_is_required")),
  bank_type:yup.string().required(trans("Type  is Required")),
});

export function createBankValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const createDocumentValidationSchema = (t: any) => yup.object().shape({
  name: yup.string().required(t("file_name_required")),
  reason: yup.string().nullable(),
  path: yup.string().required(t("file_required")).typeError(t("file_required")),
  file_type: yup
    .string()
    .required(t("file_type_required"))
    .typeError(t("file_type_required")),
});

export function createDocumentValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export let createEmploymentValidationSchema = (trans: any) => yup.object().shape({
  customer_type: yup
    .object()
    .required(trans("Cutomer_Type_is_required"))
    .typeError("Cutomer Type is required"),
  occupation: yup
    .object()
    .required(trans("occupation_is_required"))
    .typeError(trans("Occupation is required")),
  applicants_employer: yup.string().when("occupation", {
    is: (value: any) => value?.name == "Employed" || value?.name == "Retired",
    then: () =>
      yup
        .string().nullable().optional(),
    // .required(trans("Applicant's_Employer_is_required"))
    // .typeError("Applicant's Employer is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  job_title: yup.string().when("occupation", {
    is: (value: any) => value?.name == "Employed" || value?.name == "Retired",
    then: () =>
      yup
        .string().nullable().optional(),
    // .required(trans("Job_Title_is_required"))
    // .typeError("Job Title is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  address_line_1: yup.string().when("occupation", {
    is: (value: any) => value?.name == "Employed" || value?.name == "Retired",
    then: () =>
      yup
        .string().nullable().optional(),
    // .required(trans("Address_1_is_required"))
    // .typeError("Address 1 is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  address_line_2: yup.string().nullable().optional(),
  city: yup.string().when("occupation", {
    is: (value: any) => value?.name == "Employed" || value?.name == "Retired",
    then: () =>
      yup.string().nullable().optional(),
    // .required(trans("City_is_required")).typeError("City is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  state: yup.string().when("occupation", {
    is: (value: any) => value?.name == "Employed" || value?.name == "Retired",
    then: () =>
      // yup.string().required(trans("State_is_required")).typeError("State is required"),
      yup.string().nullable().optional(),
    otherwise: () => yup.string().nullable().optional(),
  }),
  zip_code: yup.string().when("occupation", {
    is: (value: any) => value?.name == "Employed" || value?.name == "Retired",
    then: () =>
      yup
        .string()
        .nullable()
        .optional()
        .test('is-valid-zip', 'Invalid Zip Code', function (value) {
          // Check if the value is provided, then validate
          if (value) {
            return usZipRegex.test(value);
          }
          return true; // If no value, return true (valid)
        }),
    // .required(trans("Zip_Code_is_required"))
    // .typeError("Zip Code is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  work_phone: phoneSchema(true, "Invalid Mobile Number").nullable().optional(),
  industry: yup.string().when("occupation", {
    is: (value: any) => value?.name == "Employed" || value?.name == "Retired",
    then: () =>
      yup
        .string().nullable().optional(),
    // .required(trans("Industry_is_required"))
    // .typeError("Industry is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  years_with_company: yup
    .number()
    .typeError("Years with Company should be number")
    .when("occupation", {
      is: (value: any) => value?.name == "Employed" || value?.name == "Retired",
      then: () =>
        yup
          .number()
          // .required(trans("Years_with_Company_is_required"))
          .nullable()
          .optional()
          .typeError("Years with Company should be number"),
      otherwise: () =>
        yup
          .number()
          .nullable()
          .optional()
          .typeError("Years with Company should be number"),
    }),
  years_with_previous_employer: yup
    .number()
    .optional()
    .nullable()
    .typeError("Years should be number"),

  // second insured
  second_occupation: yup.string().when("customer_type", {
    is: (value: any) => value?.name == "Two Individuals",
    then: () =>
      yup
        .object()
        .required(trans("Occupation_is_required"))
        .typeError(trans("Occupation is required")),
    otherwise: () => yup.string().nullable().optional(),
  }),
  second_applicants_employer: yup
    .string()
    .when(["customer_type", "second_occupation"], {
      is: (value: any, secondOccupation: any) =>
        value?.name == "Two Individuals" &&
        (secondOccupation?.name == "Employed" ||
          secondOccupation?.name == "Retired"),
      then: () =>
        // yup.string().required("Applicant's_Employer_is_required"),
        yup.string().nullable().optional(),
      otherwise: () => yup.string().nullable().optional(),
    }),
  second_job_title: yup.string().when("customer_type", {
    is: (value: any, secondOccupation: any) =>
      value?.name == "Two Individuals" &&
      (secondOccupation?.name == "Employed" ||
        secondOccupation?.name == "Retired"),
    then: () =>
      //  yup.string().required("Job_Title_is_required"),
      yup.string().nullable().optional(),
    otherwise: () => yup.string().nullable().optional(),
  }),
  second_address_line_1: yup.string().when("customer_type", {
    is: (value: any, secondOccupation: any) =>
      value?.name == "Two Individuals" &&
      (secondOccupation?.name == "Employed" ||
        secondOccupation?.name == "Retired"),
    then: () =>
      // yup.string().required("Address_1_is_required"),
      yup.string().nullable().optional(),
    otherwise: () => yup.string().nullable().optional(),
  }),
  second_address_line_2: yup.string().nullable().optional(),
  second_city: yup.string().when("customer_type", {
    is: (value: any, secondOccupation: any) =>
      value?.name == "Two Individuals" &&
      (secondOccupation?.name == "Employed" ||
        secondOccupation?.name == "Retired"),
    then: () =>
      // yup.string().required("City_is_required"),
      yup.string().nullable().optional(),
    otherwise: () => yup.string().nullable().optional(),
  }),
  second_state: yup.string().when("customer_type", {
    is: (value: any, secondOccupation: any) =>
      value?.name == "Two Individuals" &&
      (secondOccupation?.name == "Employed" ||
        secondOccupation?.name == "Retired"),
    then: () =>
      // yup.string().required("State_is_required"),
      yup.string().nullable().optional(),
    otherwise: () => yup.string().nullable().optional(),
  }),
  second_zip_code: yup.string().when("customer_type", {
    is: (value: any, secondOccupation: any) =>
      value?.name == "Two Individuals" &&
      (secondOccupation?.name == "Employed" ||
        secondOccupation?.name == "Retired"),
    then: () =>
      yup
        .string()
        .nullable()
        .optional()
        .test('is-valid-zip', 'Invalid Zip Code', function (value) {
          // Check if the value is provided, then validate
          if (value) {
            return usZipRegex.test(value);
          }
          return true; // If no value, return true (valid)
        }),
    otherwise: () => yup.string().nullable().optional(),
  }),
  second_work_phone: phoneSchema(true, "Invalid Mobile Number").nullable().optional(),
  second_industry: yup.string().when("customer_type", {
    is: (value: any, secondOccupation: any) =>
      value?.name == "Two Individuals" &&
      (secondOccupation?.name == "Employed" ||
        secondOccupation?.name == "Retired"),
    then: () =>
      // yup.string().required("Industry_is_required"),
      yup.string().nullable().optional(),
    otherwise: () => yup.string().nullable().optional(),
  }),
  second_years_with_company: yup
    .number()
    .typeError("Years should be in numbers")
    .when("customer_type", {
      is: (value: any, secondOccupation: any) =>
        value?.name == "Two Individuals" &&
        (secondOccupation?.name == "Employed" ||
          secondOccupation?.name == "Retired"),
      then: () =>
        yup
          .number()
          // .required("Years_with_Company_is_required")
          .nullable()
          .optional()
          .typeError("Years should be in numbers"),
      otherwise: () =>
        yup
          .number()
          .nullable()
          .optional()
          .typeError("Years should be in numbers"),
    }),
  second_years_with_previous_employer: yup
    .number()
    .optional()
    .nullable()
    .typeError("Years should be in numbers"),
});

export function createEmploymentValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export function createPersonalInfoValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}
export function transferDocumentValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}
export const transferDocumentSchema = (trans: any) => yup.object().shape({
  policy_select: yup
    .string()
    .required(trans("Please_Select_the_Policy"))
});
export function transferDocumentPolicyValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}
export const transferDocumentPolicySchema = (trans: any) => yup.object().shape({
  Customer_select: yup
    .string()
    .required(trans("Please_Select_the_Policy"))
});
export const personalInfoSchema = (trans: any) => yup.object().shape({
  primary_residence: yup
    .object()
    // .oneOf(['own_home', 'own_condo', 'own_coop'])
    .required("Primary Residence is required"),
  office: yup.object().nullable().optional(),
  file_number: yup.string().nullable().optional(),
  customer_number: yup.string().nullable().optional(),
  radio_base: yup.string().nullable().optional(),
  radio_group_number: yup.string().nullable().optional(),
  medalion_number: yup.string().nullable().optional(),
  signed_on_date: yup.date().nullable().optional(),
  client_left_agency: yup.date().nullable().optional(),
  pricipal_insured_ssn: yup.string().nullable().optional(),
  second_insured_ssn: yup.string().nullable().optional(),
  preferred_client: yup.string().nullable().optional(),
  alert_client: yup.string().nullable().optional(),
  do_not_accept_checks: yup.string().nullable().optional(),
  money_advanced: yup.string().nullable().optional(),
  // Add more fields as needed
});

const createDriverSchema = yup.object().shape({
  same_as_applicant: yup.boolean().optional(),
  prefix: yup.string().required("Prefix is required"),
  first_name: yup.string().required("First name is required"),
  middle_name: yup.string().optional(),
  last_name: yup.string().required("Last name is required"),
  suffix: yup.string().nullable().optional(),
  gender: yup.string().required("Gender is required"),
  dob: yup
    .date()
    .required("Date of Birth is required")
    .typeError("Date of Birth is required"),
  marital_status: yup.string().required("Marital status is required"),
  email: yup.string().required("Email is required"),
  occupation: yup.string().required("Occupation is required"),
  //driver_status: yup.string().required("Driver status is required"),
  military_status: yup.string().required("Military status is required"),
  //is_licensed_driver: yup.string().required("Licensed driver is required"),
  date_licensed: yup
    .date()
    .required("License Date required")
    .typeError("License Date required"),
  // .test(
  //   "SameDate",
  //   "License Expiry Date and Date of licensed must not be equal",
  //   function (value) {
  //     const { date_licensed } = this.parent;
  //     return !dayjs(value).isSame(date_licensed);
  //   }
  // )
  // .min(
  //   yup.ref("date_licensed"),
  //   "License Expiry Date can't be before Date of Licensed"
  // ),

  license_exp_date: yup
    .date()
    .required("License Exp Date required")
    .typeError("License Exp Date required")
    .test(
      "notBeforeDateLicensed",
      "License Expiry Date can't be before or same as Date of Licensed",
      function (value) {
        const dateLicensed = this.parent.date_licensed;
        if (!dateLicensed) return true;
        return (
          !dayjs(value).isSame(dateLicensed) &&
          !dayjs(value).isBefore(dateLicensed)
        );
      }
    ),
  license_number: yup.string().required("License number is required"),
  state_licensed: yup
    .object()
    .required("License state is required")
    .typeError("License state is required"),
  tlc_license_number: yup.string().nullable().optional(),
  tlc_exp_date: yup.date().nullable().optional(),
  total_accidental_conviction_point: yup
    .string()
    .required("Conviction/Accident Points is required"),
});

export function createDriverValidation(values: any) {
  return handleErrorMeg(values, createDriverSchema);
}

export const CreateVehicleBasicValidationShema = yup.object().shape({
  vehicle_identification_number: yup
    .string()
    .typeError("Vehicle Identification Number is required")
    .required("Vehicle Identification Number is required"),
  vehicle_class: yup.string().required("Vehicle Class is required"),
  fuel_type: yup.string().required("Fuel Type is required"),
  vehicle_make: yup
    .string()
    .trim()
    .max(50, "Max 50 characters")
    .required("Vehicle Make is required"),
  vehicle_model: yup
    .string()
    .trim()
    .max(50, "Max 50 characters")
    .required("Vehicle Model is required"),
  vehicle_year: yup
    .object()
    .required("Vehicle Year is required")
    .typeError("Vehicle Year is required"),
  weight: yup
    .string()
    .trim()
    .matches(weightRegex, "Invalid weight")
    .max(50, "Max 50 characters")
    .required("Weight is required"),
  safety_rating: yup
    .string()
    .trim()
    .matches(/^(?!0)/, "Invalid Safety Rating")
    .required("Safety Rating is required"),
  seating_capacity: yup
    .string()
    .trim()
    .matches(/^(?!0)/, "Invalid Seating Capacity")
    .required("Seating Capacity is required"),
  vehicle_license_number: yup
    .string()
    .trim()
    .max(50, "Max 50 characters")
    .matches(/^(?!0)(?!1111)/, "Invalid FHV Vehicle License Number")
    .required("FHV Vehicle License Number is required"),
});

export function CreateVehicleBasicValidation(values: any) {
  return handleErrorMeg(values, createVehicleSchema);
}

export const createVehicleSchema = yup.object().shape({
  endorsement_date: yup
    .date()
    .optional()
    .nullable()
    .typeError("Endorsement Date is required"),
  vin: yup
    .string()
    .max(17, "Invalid Identification Number(VIN)")
    .matches(VINRegex, "Invalid Identification Number(VIN)")
    .required("Identification Number(VIN) is required"),
  make: yup.object().required("Make is required"),
  // model: yup.string().required("Model is required"),
  year: yup.object().required("Year is required").typeError("Year is required"),
  number_plate: yup.string().required("Number Plate is required"),
  shl_permit: yup.object().required("SHL Permit is required"),
  state_registered: yup
    .object()
    .required("State Registered is required")
    .typeError("State Registered is required"),
  body_type: yup
    .object()
    .required("Body Type is required")
    .typeError("Body Type is required"),
  vehicle_usage: yup
    .object()
    .required("Vehicle Usage is required")
    .typeError("Vehicle Usage is required"),
  performance: yup
    .object()
    .required("Performance is required")
    .typeError("Performance is required"),
  territory: yup
    .object()
    .required("Territory is required")
    .typeError("Territory is required"),
  comp_symbol: yup
    .object()
    .required("Comp Symbol is required")
    .typeError("Comp Symbol is required"),
  collison_symbol: yup
    .object()
    .required("Collision Symbol is required")
    .typeError("Collision Symbol is required"),
  cylinders: yup
    .object()
    .required("Cylinders is required")
    .typeError("Cylinders is required"),
  horse_power: yup.string().required("Horse Power is required"),
  fuel_type: yup.string().required("Fuel Type is required"),
  purchase_date: yup
    .date()
    .required("Purchase Date is required")
    .typeError("Purchase Date is required"),
  class_code: yup
    .object()
    .required("Class Code is required")
    .typeError("Class Code is required"),
  seating_capacity: yup.string().required("Seating Capacity is required"),
  reg_exp_date: yup
    .date()
    .required("Reg Exp Date is required")
    .typeError("Reg Exp Date is required"),
  diamond_exp_date: yup
    .date()
    .required("Diamond Exp Date is required")
    .typeError("Diamond Exp Date is required"),
  odometer: yup.string().required("Odameter is required"),
  vehicle: yup
    .object()
    .required("Vehicle is required")
    .typeError("Vehicle is required"),
});

export const createPolicyInfoValidationSchema = (trans: any) => yup.object().shape({
  policy_state: yup
    .object()
    .required(trans("policy_state_required"))
    .typeError(trans("policy_state_required")),
  policy_number: yup.string().required(trans("policy_number_required")),
  insurance_company: yup
    .object()
    .required(trans("insurance_company_required"))
    .typeError(trans("insurance_company_required")),
  carier_status: yup
    .string()
    .required(trans("carier_status_required"))
    .typeError(trans("carier_status_required")),
  policy_type: yup
    .string()
    .required(trans("policy_type_required"))
    .typeError(trans("policy_type_required")),
  type: yup.string().required(trans("type")).typeError(trans("type_required")),
  policy_status: yup
    .string()
    .required(trans("policy_status_required"))
    .typeError(trans("policy_status_required")),
  policy_term: yup
    .string()
    .required(trans("policy_term_required"))
    .typeError(trans("policy_term_required")),
  effective_date: yup.date().required(trans("effective_date_required")).typeError(trans("effective_date_required")),
  expiration_date: yup
    .date()
    .min(
      yup.ref("effective_date"),
      "Expiration Date must be after the Effective Date and not before today"
    )
    .required(trans("expiration_date_required"))
    .typeError("expiration_date_required"),

    wholeSaler: yup.string().nullable().optional(), 
  // state: yup.string().required("State is required"),
});

export function createPolicyInfoValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}
export const createCustomerCustomerValidationSchema = (trans: any) => yup.object().shape({
  first_name : yup.string().required("First Name is Required"),
  last_name : yup.string().required("First Name is Required"),
  email : yup.string().required("Email is Required"),
  customer_type : yup.string().required("Customer Type is Required"),
  address_line_1 : yup.string().required("Address 1 is Required"),
  address_line_2 : yup.string().optional().nullable(),
  zip_code  : yup.string().required("Zip Code is Required")

});

export function createCustomerCustomerValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export function createPoliciesForCustomerValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const createPoliciesForCustomerValidationSchema = (trans: any) => yup.object().shape({
  policy_number : yup.string().required("Policy Number is Required"),
  location : yup.string().required("Location is Required"),
});


export let createPolicyScheduleValidationSchema = (trans: any) => yup.object().shape({
  round_premium_to_nearest_dollar: yup.boolean().optional(),
  is_using_premium_financed_co: yup.boolean().optional(),
  premium: yup.string().required(trans("premium_required")),
  number_of_payments: yup.string().required(trans("number_of_payments_required")),

  finance_company:
    yup.string().when("is_using_premium_financed_co", {
      is: (value: any) => !!value,
      then: () =>
        yup.string().required(trans("finance_company_required")),
      otherwise: () => yup.string().nullable().optional(),
    }),

  premium_finance_charge:
    yup.string().when("is_using_premium_financed_co", {
      is: (value: any) => !!value,
      then: () =>
        yup.string().required(trans("premium_finance_charge_required")),
      otherwise: () => yup.string().nullable().optional(),
    }),

  monthly_financed_payment:
    yup.string().when("is_using_premium_financed_co", {
      is: (value: any) => !!value,
      then: () =>
        yup.string().required(trans("finance_month_required"))

          .test(
            "IsLessThanPremium",
            "Monthly payment must be less than premium",
            function (value: any) {
              const { premium } = this.parent;
              return !(parseInt(premium) < parseInt(value));
            }
          )
          .test(
            "AmountExceeded",
            "Sum of monthly premium and deposite should be less than Premium",
            function (value: any) {
              const { premium, premium_deposite } = this.parent;
              const totalAmount = parseInt(premium || 0);
              const deposite = parseInt(premium_deposite || 0);
              const due = parseInt(value || 0);
              if (deposite + due > totalAmount) {
                return false;
              }
              return true;
            }
          ),
      otherwise: () => yup.string().nullable().optional(),
    }),
  loan: yup.string().nullable().optional(),

  premium_deposite: yup
    .string()
    .optional()
    .nullable()
    .test(
      "IsLessThanPremium",
      "Premium deposite must be less than premium",
      function (value: any) {
        const { premium } = this.parent;
        return !(parseInt(premium) < parseInt(value));
      }
    ),
  monthly_deposit:
    yup.string().when("is_using_premium_financed_co", {
      is: (value: any) => !value,
      then: () =>
        yup
          .string()
          .required(trans("monthly_deposit_required"))
          .test(
            "IsLessThanPremium",
            "Monthly payment must be less than premium",
            function (value: any) {
              const { premium } = this.parent;
              return !(parseInt(premium) < parseInt(value));
            }
          )
          .test(
            "AmountExceeded",
            "Sum of monthly premium and deposite should be less than Premium",
            function (value: any) {
              const { premium, premium_deposite } = this.parent;
              const totalAmount = parseInt(premium || 0);
              const deposite = parseInt(premium_deposite || 0);
              const due = parseInt(value || 0);
              if (deposite + due > totalAmount) {
                return false;
              }
              return true;
            }
          ),
      otherwise: () => yup.string().nullable().optional(),
    }),
  installment_charge: yup.string().required(trans("installment_charge_required")),
  start_date: yup.date().required(trans("premium_start_date_required ")).typeError(trans("premium_start_date_required")),

  schedule: yup.array().of(
    yup.object().shape({
      due_date: yup
        .date()
        .required(trans("due_date_required"))
        .typeError(trans("due_date_required")),

        scheduled_amt: yup
        .number()
        .typeError(trans("scheduled_amt_required"))
        .required(trans("scheduled_amt_required")),

        reason: yup.string()
        .typeError(trans("reason_amt_required"))
        .required(trans("reason_amt_required")),

        type: yup.string()
        .typeError(trans("type_amt_required"))
        .required(trans("type_amt_required")),
    })
  ),
});

export function createPolicyScheduleValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export let FeesModalValidationSchema = (trans: any) => yup.object().shape({
  surplus_lines_tax: yup.string().optional().nullable(),
  second_injury_fund: yup.string().optional().nullable(),
  cancel_fee: yup.string().optional().nullable(),
  excess_line_tax: yup.string().optional().nullable(),
});

export function FeesModalValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export let ClientPaymentModalValidationSchema = (trans: any) => yup.object().shape({
  amount_received: yup
    .number()
    .required(trans("Amount_Received_is_required"))
    .typeError("Amount Received is required"),
  payment_method: yup
    .object()
    .required(trans("Payment_Method_is_required"))
    .typeError("Payment Method is required"),
  check: yup.string().when("payment_method", {
    is: (value: any) => {
      return value && value?.name === "CHECK";
    },
    then: () => yup.string().required(trans("Check_Number_is_required")),
    otherwise: () => yup.string().nullable(),
  }),
  amount_in_words: yup.string().when("payment_method", {
    is: (value: any) => {
      return value && value?.name === "CHECK";
    },
    then: () => yup.string().required(trans("Amount_in_Words_is_required")),
    otherwise: () => yup.string().nullable(),
  }),
  pay_to_order_of: yup.string().when("payment_method", {
    is: (value: any) => {
      return value && value?.name === "CHECK";
    },
    then: () => yup.string().required(trans("Pay_to_Order_of_is_required")),
    otherwise: () => yup.string().nullable(),
  }),
  print_draft_date: yup.string().when("payment_method", {
    is: (value: any) => {
      return value && value?.name === "CHECK";
    },
    then: () =>
      yup
        .date()
        .required(trans("Print_Draft_Date_is_required"))
        .typeError("Print Draft Date is required")
        .test(
          "notBeforeDateLicensed",
          "Print Draft Date can't be past Date",
          function (value) {
            if (!value) return true;
            return (
              // !dayjs().add(3, "month").isAfter(value) &&
              !dayjs().add(6, "month").isBefore(value)
            );
          }
        ),
    otherwise: () => yup.string().nullable(),
  }),
  signature: yup.string().when("payment_method", {
    is: (value: any) => {
      return value && value?.name === "CHECK";
    },
    then: () => yup.string().required(trans("Signature_of_is_required")),
    otherwise: () => yup.string().nullable(),
  }),
  confirm: yup.string().optional().nullable(),
  reason: yup.string().optional().nullable(),
});

export function ClientPaymentModalValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export let CarrierPaymentModalValidationSchema = (trans: any) => yup.object().shape({
  amount: yup
    .number()
    .required(trans("Amount Received is required"))
    .typeError("Amount Received is required"),
  payment_method: yup
    .object()
    .required(trans("Payment Method is required"))
    .typeError("Payment Method is required"),
  date_paid: yup
    .date()
    .required(trans("Date Paid is required"))
    .typeError("Date Paid is required"),
  check: yup.string().when("payment_method", {
    is: (value: any) => {
      return value && value?.name === "CHECK";
    },
    then: () => yup.string().required(trans("Check Number is required")),
    otherwise: () => yup.string().nullable(),
  }),
  amount_in_words: yup.string().when("payment_method", {
    is: (value: any) => {
      return value && value?.name === "CHECK";
    },
    then: () => yup.string().required(trans("Amount in Words is required")),
    otherwise: () => yup.string().nullable(),
  }),
  pay_from_order_of: yup.string().when("payment_method", {
    is: (value: any) => {
      return value && value?.name === "CHECK";
    },
    then: () => yup.string().required(trans("Pay from Order of is required")),
    otherwise: () => yup.string().nullable(),
  }),
  pay_to_order_of: yup.string().when("payment_method", {
    is: (value: any) => {
      return value && value?.name === "CHECK";
    },
    then: () => yup.string().required(trans("Pay to Order of is required")),
    otherwise: () => yup.string().nullable(),
  }),
  print_draft_date: yup.string().when("payment_method", {
    is: (value: any) => {
      return value && value?.name === "CHECK";
    },
    then: () =>
      yup
        .date()
        .required(trans("Print Draft Date is required"))
        .typeError("Print Draft Date is required")
        .test(
          "notBeforeDateLicensed",
          "Print Draft Date can't be past Date",
          function (value) {
            if (!value) return true;
            return (
              // !dayjs().add(3, "month").isAfter(value) &&
              !dayjs().add(6, "month").isBefore(value)
            );
          }
        ),
    otherwise: () => yup.string().nullable(),
  }),
  confirm: yup.string().optional().nullable(),
  reason: yup.string().optional().nullable(),
});

export function CarrierPaymentModalValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const createClaimValidationSchema = (trans: any) => yup.object().shape({
  claim_number: yup.string().required(trans("Claim_number_is_required")),
  date_of_loss: yup
    .date()
    .required(trans("Date_of_loss_is_required"))
    .typeError("Date of loss is required"),
  date_reported: yup
    .date()
    .min(
      yup.ref("date_of_loss"),
      "Reported date must be after the Date of loss"
    )
    .required(trans("Reported_date_is_required"))
    .typeError("Reported date is required"),
  other_parties_insurance_company: yup
    .object()
    .required(trans("Other_Parties_Insurance_Company_is_required"))
    .typeError("Other Parties Insurance Company is required"),
  other_parties_policy: yup.string().nullable().optional(),
  other_parties_claim: yup.string().nullable().optional(),
  type_of_loss: yup.string().nullable().optional(),
  description: yup.string().nullable().optional(),
  cat_number: yup.string().nullable().optional(),
  status: yup.string().required(),
  attorney: yup.string().nullable().optional(),
  // amount: yup.number().required(trans("Amount is required")),
  // path: yup.string().required(trans("File is required")).typeError("File is required"),
  // file_type: yup
  //   .string()
  //   .required(trans("File Type is required"))
  //   .typeError("File Type is required"),
});

export function createClaimValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export let CommissionModalValidationSchema = (trans: any) => yup.object().shape({
  commission_percentage: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .max(100, "Commission due percentage must be less than or equal to 100")
    .required(trans("Commission percentage is required")),
  minimum_commission_amount: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required(trans("Minimum commission amount is required")),
});
// .test({
//   name: "at-least-one-required",
//   message:
//     "At least one of commission percentage or minimum commission amount is required",
//   test: function (value) {
//     const { commission_percentage, minimum_commission_amount } = value;
//     if (
//       commission_percentage === null &&
//       minimum_commission_amount === null
//     ) {
//       throw this.createError({
//         path: "common_error", // Specify the key for the error
//         message:
//           "At least one of commission percentage or minimum commission amount is required",
//       });
//     }
//     return true; // Return true if the test passes
//   },
// });

export function commissionModalValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}
export const createCoverageValidationSchema = (trans: any) => yup.object().shape({
  /*Add Coverage validations*/
  type_of_change: yup.string().required(trans("Type is required")),
  premium: yup.string().required(trans("premium_required")),
  number_of_payments: yup.string().required(trans("Number of payment is required")),
  premium_deposite: yup
    .string()
    .optional()
    .nullable()
    .test(
      "IsLessThanPremium",
      "Premium deposite must be less than premium",
      function (value: any) {
        const { premium } = this.parent;
        return !(parseInt(premium) < parseInt(value));
      }
    ),
  monthly_deposit: yup
    .string()
    .required("Monthly payment is required")
    .test(
      "IsLessThanPremium",
      "Monthly payment must be less than premium",
      function (value: any) {
        const { premium } = this.parent;
        return !(parseInt(premium) < parseInt(value));
      }
    )
    .test(
      "AmountExceeded",
      "Sum of monthly premium and deposite should be less than Premium",
      function (value: any) {
        const { premium, premium_deposite } = this.parent;
        const totalAmount = parseInt(premium || 0);
        const deposite = parseInt(premium_deposite || 0);
        const due = parseInt(value || 0);
        if (deposite + due > totalAmount) {
          return false;
        }
        return true;
      }
    ),
  installment_charge: yup.string().required("Installment Charge is required"),
  start_date: yup
    .date()
    .required("Start date is required")
    .typeError("Start date is required"),
  completionDate: yup
    .date()
    .required("Completion Date is required")
    .test(
      "notBeforeDateLicensed",
      "Completion date can't be before or same as Start Date",
      function (value) {
        const dateLicensed = this.parent.start_date;
        if (!dateLicensed) return true;
        return (
          !dayjs(value).isSame(dateLicensed) &&
          !dayjs(value).isBefore(dateLicensed)
        );
      }
    )
    .typeError("Completion Date is required"),
});

export function createCoverageValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const createPaymentCoverageValidationSchema = (trans: any) => yup.object().shape({
  /*Add Coverage validations*/
  type_of_change: yup.string(),
});

export function createPaymentCoverageValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export let CommissionPaymentValidationSchema = yup.object().shape({
  effective_date: yup
    .date()
    .required("Effective date is required")
    .typeError("Effective date is required"),
  payment: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required("Amount is required"),
  check: yup.string().optional(),
  reason: yup.string().optional(),
});

export function commissionPaymentValidation(values: any) {
  return handleErrorMeg(values, CommissionPaymentValidationSchema);
}

export const createQuoteCustomerSchema = (trans: any) => yup.object().shape({
  prefix: yup.string().nullable(),
  first_name: yup.string().required(trans("first_name_required")),
  middle_name: yup.string().nullable(),
  last_name: yup.string().required(trans("last_name_required")),
  business_type: yup
    .object()
    .required(trans("business_type"))
    .typeError(trans("business_type")),
  suffix: yup.string().nullable(),
  gender: yup.string().nullable(),
  marital_status: yup.string().optional().nullable(),
  social_security: yup
    .string()
    .required(trans("social_security_required"))
    .test("is-valid-ssn", trans("Invalid_ssn"), (value) => {
      if (value) {
        return yup.string().matches(SSNPattern).isValidSync(value);
      }
      return true; // Allow empty value
    }),
  dob: yup.date().required(trans("dob_required")).typeError(trans("dob_required")),
  email: yup.string().required(trans("email_required")),
  mobile: yup.string().required(trans("phone_required")),
  website: yup.string().url().optional().nullable(),
  license_number: yup.string().required(trans("license_number_required")),
  license_state: yup
    .object()
    .required(trans("license_state"))
    .typeError(trans("license_state")),
  license_issue_date: yup.date().required(trans("license_issue")).typeError(trans("license_issue")),
  license_exp_date: yup
    .date()
    .min(
      yup.ref("license_issue_date"),
      trans("license_issue_date")
    )
    .required(trans("license_expiry_date_reqiured"))
    .typeError(trans("license_expiry_date_reqiured")),
  address_line_one: yup.string().required(trans("adress_line")),
  address_line_two: yup.string().nullable(),
  state: yup
    .object()
    .required(trans("state_required"))
    .typeError(trans("state_required")),
  city: yup.string().required(trans("city_required")),
  zipcode: yup
    .string()
    .required(trans("zip_code_required"))
    .test("is-valid-zipcode", trans("invalid_zip"), (value) => {
      if (value) {
        return yup.string().matches(usZipRegex).isValidSync(value);
      }
      return true; // Allow empty value
    }),
  address_type: yup.string().required(trans("Address_is_required")),
  // Add more fields as needed
});


export function policyNumbervalidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const policyNumberSchema = (trans: any) => yup.object().shape({
  policy_number: yup.string().required(trans("policy_number_required")),
  // captchaInput:yup.string().required(trans("Captcha_required"))
})


export function makePaymentValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const makePaymentSchema = (trans: any) => yup.object().shape({
  type_of_card: yup.string().required(trans("Mode of payment is required")),
  // amount : yup.string().required(trans("Amount is required"))

})


export function paymentPersonalInfoValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const paymentPersonalInfoSchema = (trans: any) => yup.object().shape({
  customer_name: yup.string().required(trans("Customer name is required")),
  email: yup.string().optional(),
  address: yup.string().required(trans("Address_is_required")),
  zipcode: yup.string().required(trans("zip_code_required")),
})

export function areYouNewOrExisting(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}
export const areYouNewOrExistingSchema = (trans: any) => yup.object().shape({
  new_or_old: yup.string().required(trans("New_Existing_required")),
})


export function existingCustomervalidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const existingCustomervalidationSchema = (trans: any) => yup.object().shape({
  email: yup.string().required(trans("email_required")),
})

export function newOrExistingPolicyvalidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const newOrExistingPolicyvalidationSchema = (trans: any) => yup.object().shape({
  new_or_old_policy: yup.string().required(trans("New or existing policy is required")),
})

export function newCustomervalidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const newCustomervalidationSchema = (trans: any) => yup.object().shape({
  first_name: yup.string().required(trans("first_name_required_second")),
  last_name: yup.string().required(trans("last_name_required_second")),
  email: yup.string().required(trans("email_required")),
  customer_type: yup.string().required(trans("customer_type_required")),
  address_line_1: yup.string().required(trans("Address_1_is_required")),
  address_line_2: yup.string().optional(),
  zip_code: yup.string()
  .matches(usZipRegex, trans("invalid_zip"))
  .required(trans("zip_code_required")),
})



export function newPolicyPaymentvalidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const newPolicyPaymentvalidationSchema = (trans: any) => yup.object().shape({
  location: yup.string().required(trans("Location is required")),
  amount: yup.string().required(trans("Amount is required")),
  type_of_card: yup.string().required(trans("Mode of payment is required")),
  
})





export function createQuoteCustomerValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const createQuoteEndUserSchema = (trans: any) => yup.object().shape({
  // prefix: yup.string().nullable(),
  first_name: yup
    .string()
    .max(30, "First Name should not exceed 30 characters")
    .required(trans("first_name_required_second")),
  // middle_name: yup.string().nullable(),
  last_name: yup
    .string()
    .max(30, "Last Name should not exceed 30 characters")
    .required(trans("last_name_required_second")),
  // business_type: yup.object().optional().nullable(),
  // suffix: yup.string().nullable(),
  // gender: yup.string().nullable(),
  // marital_status: yup.string().optional().nullable(),
  // social_security: yup
  //   .string()
  //   .nullable()
  //   .optional()
  //   .test("is-valid-ssn", "Invalid ssn", (value) => {
  //     if (value) {
  //       return yup.string().matches(SSNPattern).isValidSync(value);
  //     }
  //     return true; // Allow empty value
  //   }),
  // dob: yup.date().optional().nullable(),
  email: yup
    .string()
    .matches(emailRegex, { message: "Invalid email" })
    .required(trans("email_required")),
  email_type: yup.string().required(trans("email_type_is_required")),
  mobile: phoneSchema(true, "Invalid Phone Number").required(
    "Phone Number is required"
  ),
  mobile_type: yup.string().required(trans("Phone_Type_is_required")),
  // website: yup.string().url().optional().nullable(),
  // license_number: yup.string().optional().nullable(),
  // license_state: yup.object().optional().nullable(),
  // license_issue_date: yup.date().optional().nullable(),
  // license_exp_date: yup
  //   .date()
  //   .min(
  //     yup.ref("license_issue_date"),
  //     "License Issue Date must be after the License Expiry Date"
  //   )
  //   .optional()
  //   .nullable(),
  // address_line_one: yup.string().optional().nullable(),
  // address_line_two: yup.string().nullable(),
  // state: yup.object().optional().nullable(),
  // city: yup.string().optional().nullable(),
  // zipcode: yup
  //   .string()
  //   .optional()
  //   .nullable()
  //   .test("is-valid-zipcode", "Invalid Zip Code", (value) => {
  //     if (value) {
  //       return yup.string().matches(usZipRegex).isValidSync(value);
  //     }
  //     return true; // Allow empty value
  //   }),
  // address_type: yup.string().optional().nullable(),
  // Add more fields as needed
});

export function createQuoteEndUserValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export function MasterTypeValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const MasterTypeValidationSchema = (trans: any) => yup.object().shape({
  name: yup
    .string()
    .trim()
    .max(50, "Max 50 Characters")
    .required(trans("Master_Type_is_Required")),
  // is_active:yup.boolean()
});

export function CompanyValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const CompanyValidationSchema = (trans: any) => yup.object().shape({
  name: yup
    .string()
    .trim()
    .max(50, "Max 50 Characters")
    .required(trans("Insurance_Company_Name_is_required")),
  // is_active:yup.boolean()
});

export function IndustryValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const IndustryValidationSchema = (trans: any) => yup.object().shape({
  name: yup
    .string()
    .trim()
    .max(50, "Max 50 Characters")
    .required(trans("Industry_Name_is_Required")),
  // is_active:yup.boolean()
});

export function MasterOptionsValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const MasterOptionsValidationSchema = (trans: any) => yup.object().shape({
  name: yup
    .string()
    .trim()
    .max(50, "Max 50 Characters")
    .required(trans("Options_is_required"))
    .typeError("Options is required"),
  code: yup.string().trim().max(50, "Max 50 Characters").nullable().optional(),
  // type: yup
  // .object()
  // .required('Master Type is Required')
});

export const createQuotationDriverSchema = (trans: any) => yup.object().shape({
  same_as_applicant: yup.boolean().optional(),
  prefix: yup.string().required(trans("prefix_required")),
  first_name: yup.string().required(trans("first_name_required")),
  middle_name: yup.string().nullable().optional(),
  last_name: yup.string().required(trans("last_name_required")),
  suffix: yup.string().nullable().optional(),
  gender: yup.string().required(trans("gender_required")),
  dob: yup.date().required(trans("dob_required")).typeError(trans("dob_required")),
  marital_status: yup.string().required(trans("marital_status_required")),
  email: yup.string().required(trans("email_required")),
  occupation: yup.string().required(trans("occuption_required")),
  relationship: yup.string().required(trans("Relationship is Required")),
  driver_status: yup.string().required("Driver status is required"),
  military_status: yup.string().required(trans("military_status_required")),
  //is_licensed_driver: yup.string().required("Licensed driver is required"),
  date_licensed: yup
    .date()
    .required(trans("date_licensed_required"))
    .max(new Date(), "Date licensed cannot be in the future")
    .typeError(trans("date_licensed_required")),
  license_exp_date: yup
    .date()
    .required(trans("license_expiry_date_required"))
    .test(
      "SameDate",
      trans("license_expiry_date_valid"),
      function (value) {
        const { date_licensed } = this.parent;
        return !dayjs(value).isSame(date_licensed);
      }
    )
    .min(
      yup.ref("date_licensed"),
      trans("license_expiry_date__min_valid")
    ).typeError(trans("license_expiry_date_required")),
  license_number: yup.string().required(trans("license_number_required")),
  state_licensed: yup
    .object()
    .required(trans("licensed_state_required"))
    .typeError(trans("licensed_state_required")),
  tlc_license_number: yup.string().required(trans("tlc_license_number_required")),

  tlc_effective_date: yup
    .date()
    .required(trans("tlc_effective_date_required"))
    .max(new Date(), "TLC Effective Date cannot be in the future").typeError(trans("tlc_effective_date_required")),
  tlc_exp_date: yup
    .date()
    .required(trans("tlc_expiry_date_required"))
    .test(
      "SameDate",
      "TLC Expiry Date and TLC Effective Date must not be equal",
      function (value) {
        const { date_licensed } = this.parent;
        return !dayjs(value).isSame(date_licensed);
      }
    )
    .min(
      yup.ref("tlc_effective_date"),
      "TLC Expiry Date can't be before TLC Effective Date"
    ).typeError(trans("tlc_expiry_date_required")),

  hack_license_number: yup.string().required(trans("hack_license_number_required")),
  hack_effective_date: yup
    .date()
    .required(trans("hack_effective_date_required"))
    .max(new Date(), "Hack Effective Date cannot be in the future")
    .typeError(trans("hack_effective_date_required")),
  hack_exp_date: yup
    .date()
    .required(trans("hack_license_expiry_date_required"))
    .test(
      "SameDate",
      "Hack Expiry Date and Hack Effective Date must not be equal",
      function (value) {
        const { date_licensed } = this.parent;
        return !dayjs(value).isSame(date_licensed);
      }
    )
    .min(
      yup.ref("hack_effective_date"),
      "Hack Expiry Date can't be before Hack Effective Date"
    )
    .typeError(trans("hack_license_expiry_date_required")),

  website: yup.string().url().optional().nullable(),
  mobile: yup.string().required(trans("phone_required")),
  // address: yup.string().required("Address is required"),
  total_accidental_conviction_point: yup
    .string()
    .required(trans("Total Accidental Conviction point required")),
  // address_type: yup.string().required(trans("address_Type_required")),
  // address_line_one: yup.string().required(trans("Address_line_one_required")),
  address: yup.string().required(trans("Address is required")),
  // address_line_two: yup.string().nullable(),
  social_security: yup
    .string()
    .required(trans("Social Security Number is required"))
    .test("is-valid-ssn", "Invalid ssn", (value) => {
      if (value) {
        return yup.string().matches(SSNPattern).isValidSync(value);
      }
      return true; // Allow empty value
    }),
  // state: yup
  //   .object()
  //   .required(trans("state_required"))
  //   .typeError(trans("state_required")),
  // city: yup.string().required(trans("city_required")),
  // zipcode: yup
  //   .string()
  //   .required(trans("zip_code_required"))
  //   .test("is-valid-zipcode", "Invalid Zip Code", (value) => {
  //     if (value) {
  //       return yup.string().matches(usZipRegex).isValidSync(value);
  //     }
  //     return true; // Allow empty value
  //   }),
});

export function createQuotationDriverValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export function CreateQuotationVehicleBasicValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const createQuotationVehicleSchema = (trans: any) => yup.object().shape({
  // endorsement_date: yup
  //   .date()
  //   .required("Endorsement Date is required")
  //   .typeError("Endorsement Date is required"),
  vin: vinSchema(true, trans("vin")).required(trans("vin")),
  model: yup.string().required(trans("model_required")),
  make: yup.string().required(trans("make_required")),
  // model: yup.string().required("Model is required"),
  year: yup.string().required(trans("year_required")).typeError(trans("year_required")),
  number_plate: yup.string().required(trans("number_plate")),
  shl_permit: yup.string().required(trans("shl_required")),
  state_registered: yup
    .object()
    .required(trans("state_registred"))
    .typeError(trans("state_registred")),
  body_type: yup
    .string()
    .required(trans("body_type_required"))
    .typeError(trans("body_type_required")),
  vehicle_usage: yup
    .string()
    .required(trans("vechicle_usage_required"))
    .typeError(trans("vechicle_usage_required")),
  performance: yup
    .string()
    .required(trans("performance_required"))
    .typeError(trans("performance_required")),
  territory: yup
    .string()
    .required(trans("territory_required"))
    .typeError(trans("territory_required")),
  comp_symbol: yup
    .string()
    .required(trans("comp_symbol_required"))
    .typeError(trans("comp_symbol_required")),
  collison_symbol: yup
    .string()
    .required(trans("collision_symbol_required"))
    .typeError(trans("collision_symbol_required")),
  cylinders: yup
    .string()
    .required(trans("cylinders_required"))
    .typeError(trans("cylinders_required")),
  horse_power: yup.string().required(trans("horse_power_required")),
  fuel_type: yup.string().required(trans("fuel_type_required")),
  purchase_date: yup
    .date()
    .required(trans("purchase_date_required"))
    .typeError(trans("purchase_date_required")),
  class_code: yup
    .string()
    .required(trans("class_code_required"))
    .typeError(trans("class_code_required")),
  seating_capacity: yup.number().required(trans("seating_capacity_required")).typeError(trans("seating_capacity_number")),
  reg_exp_date: yup
    .date()
    .min(dayjs().startOf('day'), trans("reg_date_validation"))
    .required(trans("reg_exp_date_required"))
    .typeError(trans("reg_exp_date_required")),
  diamond_exp_date: yup
    .date()
    .required(trans("diamond_exp_date_required"))
    .typeError(trans("diamond_exp_date_required")),
  odometer: yup.string().required(trans("odameter_required")),
  vehicle: yup
    .string()
    .required(trans("vechicle_required"))
    .typeError(trans("vechicle_required")),
  zip_code: yup
    .string()
    .matches(usZipRegex, trans("invalid_zip"))
    .required(trans("zip_code_required")),
  weight: yup.string().nullable().optional(),
  safety_rating: yup.string().nullable().optional(),
  fhv_vehicle_license_number: yup.string().nullable().optional(),
  dmv_license_plate_number: yup.string().nullable().optional(),
  vehicle_permit_license_number: yup.string().nullable().optional(),
  driver_cam: yup.string().nullable().optional(),
  black_box: yup.string().nullable().optional(),
  navigation_gps: yup.string().nullable().optional(),
  bluetooth_hands_free: yup.string().nullable().optional(),
  current_auto_insurance: yup.string().nullable().optional(),
  base_name: yup.string().nullable().optional(),
  automatic_breaking: yup.string().nullable().optional(),
  lane_departure_control: yup.string().nullable().optional(),
  forward_collision_warning: yup.string().nullable().optional(),
  lane_departure_warning: yup.string().nullable().optional(),
  anti_lock_braking_system: yup.string().nullable().optional(),
  electronic_stability_control: yup.string().nullable().optional(),
  traction_control: yup.string().nullable().optional(),
  tyre_pressure_monitoring: yup.string().nullable().optional(),
  automatic_pedestrian_alerts: yup.string().nullable().optional(),
  automatic_crash_notification: yup.string().nullable().optional(),
  blind_spot_warning: yup.string().nullable().optional(),
  event_data_recorder: yup.string().nullable().optional(),
  wheel_chair_accessibility: yup.string().nullable().optional(),
});
export const createVehicleDiscountSchema = (trans: any) => yup.object().shape({
  airbags:yup.string().required("Airbag field is required"),
  anti_theft_devices: yup.string().required("Anti Theft Device field is required"),
  model_of_the_alarm: yup.string().required("Model of Alarm is required"),
  brand_of_alerm: yup.string().required("Branmd of Alarm is required"),
  usage: yup.string().required("Usage is required"),
});
export function createVehicleDiscountValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}
export const createDriverEmployeSchema = (trans: any) => yup.object().shape({
  applicant_employer:yup.string().nullable().optional(),
  years_with_company: yup.string().nullable().optional(),
  address_line_1: yup.string().nullable().optional(),
  address_line_2: yup.string().nullable().optional(),
  city: yup.string().nullable().optional(),
  state: yup.string().nullable().optional(),
  zip_code: yup.string().nullable().optional(),
  country: yup.string().nullable().optional(),
});
export function createDriverEmployeSchemaValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}
export const createVehicleDiscountDateSchema = (trans: any) => yup.object().shape({
//   process_date_not_effective_date:  yup.date()
//   .optional()
//   .nullable(),
//   date_vehicle_was_removed_from_policy: yup.date()
//   .optional()
//   .nullable()
// ,
//   effective_date_added_to_policy: yup.date()
//   .optional()
//   .nullable(),
//   effective_date_removed_from_policy: yup.date()
//   .optional()
//   .nullable(),
});
export function createVehicleDiscountDateValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}
export const createVehicleDiscountGarageSchema = (trans: any) => yup.object().shape({
  garaging_location : yup.string().required("Garaging Location is required"),
  address_line_1: yup.string().when("garaging_location", {
    is: (value: any) => value === "Yes" ,
    then: () =>
      yup
        .string().required("Address line 1 is required"),
    // .required(trans("Applicant's_Employer_is_required"))
    // .typeError("Applicant's Employer is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  address_line_2: yup.string().when("garaging_location", {
    is: (value: any) => value === "Yes" ,
    then: () =>
      yup
        .string().required("Address line 2 is required"),
    // .required(trans("Applicant's_Employer_is_required"))
    // .typeError("Applicant's Employer is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  city: yup.string().when("garaging_location", {
    is: (value: any) => value === "Yes" ,
    then: () =>
      yup
        .string().required("city is required"),
    // .required(trans("Applicant's_Employer_is_required"))
    // .typeError("Applicant's Employer is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  state: yup.string().when("garaging_location", {
    is: (value: any) => value === "Yes" ,
    then: () =>
      yup
        .string().required("State is required"),
    // .required(trans("Applicant's_Employer_is_required"))
    // .typeError("Applicant's Employer is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  zip_code: yup.string().when("garaging_location", {
    is: (value: any) => value === "Yes" ,
    then: () =>
      yup
        .string().required("ZipCode is required"),
    // .required(trans("Applicant's_Employer_is_required"))
    // .typeError("Applicant's Employer is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  country: yup.string().when("garaging_location", {
    is: (value: any) => value === "Yes" ,
    then: () =>
      yup
        .string().required("Country is required"),
    // .required(trans("Applicant's_Employer_is_required"))
    // .typeError("Applicant's Employer is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),

});
export function createVehicleDiscountGarageValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export function createQuotationPolicyValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export let createQuotationPolicyScheduleValidationSchema = (trans: any) => yup.object().shape({
  policy_state: yup
    .object()
    .required(trans("policy_state_required"))
    .typeError("State is required"),
  insurance_company: yup
    .object()
    // .required("Insurance Company is required")
    // .typeError("Insurance Company is required"),
    .optional()
    .nullable(),
  policy_type: yup
    .string()
    .required(trans("Policy_Type_is_required"))
    .typeError("Policy Type is required"),
  policy_term: yup
    .string()
    .required(trans("Policy Term is required"))
    .typeError("Policy_Term_is_required"),
  premium: yup.string().required("Premium is required"),
  effective_date: yup.date().required("Effective Date is required"),
  expiration_date: yup
    .date()
    .min(
      yup.ref("effective_date"),
      "Expiration Date must be after the Effective Date and not before today"
    )
    .required(trans("Expiration_Date_is_required"))
    .typeError("Expiration Date is required"),
  carrier_code: yup
    .object()
    .required(trans("Carrier is required"))
    .typeError("Carrier is required"),
  product_code: yup
    .object()
    .required(trans("Product_code_is_required"))
    .typeError("Product code is required"),
  sub_product_code: yup
    .object()
    .required(trans("Sub_product_code_is_required"))
    .typeError("Sub product code is required"),
  classification: yup
    .object()
    .required(trans("Classification is required"))
    .typeError("Classification is required"),
  underwriting_code: yup
    .object()
    .required(trans("Underwriting_code_is_required"))
    .typeError("Underwriting code is required"),
  inception_date: yup
    .date()
    .required(trans("Inception_date_is_required"))
    .typeError("Inception date is required"),
  producer_code: yup
    .object()
    .required(trans("Producer_code_is_required"))
    .typeError("Producer code is required"),
  payment_plan_code: yup
    .object()
    .required(trans("Payment_plan_code_is_required"))
    .typeError("Payment plan code is required"),
  creator_code: yup
    .object()
    .required(trans("Creator code is required"))
    .typeError("Creator code is required"),
});
export function CreateQuotationDocumentsValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const createQuotationDocumentsValidationSchema = (trans: any) => yup.object().shape({
  documents: yup.array().of(
    yup.object().shape({
      name: yup.string(),
      document_name: yup.string(),
      path: yup.string().when("document_name", {
        is: (value: any) => value == "Drivers License",
        then: () => yup.string().required("license_required"),
        otherwise: () => yup.string().nullable().optional(),
      }),
      file_type: yup.string().when("document_name", {
        is: (value: any) => value == "Drivers License",
        then: () => yup.string().required("License is required"),
        otherwise: () => yup.string().nullable().optional(),
      }),
      // Additional Info End
    })
  ),
});

export const importModalValidationSchema = (trans: any) => yup.object().shape({
  file: yup.mixed().required(trans("File is required")),
  isCustType: yup.boolean().optional(),
  customer_type: yup.string().when("isCustType", {
    is: (tax: boolean) => tax,
    then: () => yup.string().required(trans("customer_type_required")),
    otherwise: () => yup.string().nullable().optional(),
  }),
});
export const CustomerPolicyValidationSchema = (trans: any) => yup.object().shape({
  policy_number: yup
    .string()
    .trim()
    .max(20, "Max 20 Characters")
    .required(trans("Policy Number is required")),
  location: yup.object().required(trans("Location is required")).typeError(trans("Location is required")),
  due_amount: yup.string().required(trans("Due Amount is required")),
  broker_fee: yup.string().required(trans("Broker Fee is required")),
  who_has_to_pay_the_transaction_fee: yup.string().required(trans("Who has to pay the transaction fee is required"))
});

export function importModalValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}
export function CustomerPolicyValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

const createEmployeeSchema = yup.object().shape({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  email: yup.string().required("Email is required"),
  language: yup.string().required("Language is required"),
  mobile: phoneSchema(true, "Invalid Mobile Number").required(
    "Mobile Number is required"
  ),
  // location : yup.string().required("Location is required")
});

export function createEmployeeValidation(values: any) {
  return handleErrorMeg(values, createEmployeeSchema);
}

export const createEmployeesSchema = (trans: any) => yup.object().shape({
  first_name: yup.string().required(trans("first_name_required")),
  last_name: yup.string().required(trans("last_name_required")),
  email: yup.string().required(trans("email_required")),
  // language: yup.string().required(trans("Language_is_required")),
  mobile: phoneSchema(true, "Invalid Mobile Number").required(
    trans("Mobile_Number_is_required")
  ),
  // location: yup.object().required("Location is required"),
});

export function createEmployeesValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const createManualInvoiceSchema = (trans: any) => yup.object().shape({
  written_premium: yup.string().required(trans("Written Premium is required")),
  installation_charge_and_fee: yup.string().required(trans("Installation Charge and Fee is required")),
  vehicle_fee: yup.string().required(trans("Vehicle fee is required")),
  credits: yup.string().required(trans("Credits fee is required")),
  outstanding_balance: yup.string().required(trans("Outstanding Balance is required")),
  due_date : yup.date().required(trans("Due Date is required")),
  type:  yup.string().required(trans("Type is required")),
  past_due : yup.string().required(trans("Past Due is required")),
  current_due : yup.string().required(trans("Current Due is required")),
  total_due : yup.string().required(trans("Total Due is required")),
  is_showagency_name : yup.boolean().optional(),
  is_show_producer_name : yup.boolean().optional(),
});

export function createInvoiceValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const createLocationSchema = (trans: any) => yup.object().shape({
  name: yup
    .string()

    .required(trans("Location Name is required")),
  code: yup.string().required(trans("Location code is required")),
  location_url: yup.string().nullable(),
  address_line_one: yup.string().optional().nullable(),
  address_line_two: yup.string().optional().nullable(),
  state: yup.object().nullable().optional(),
  city: yup.string().optional().nullable(),
  zip_code: yup.string().optional().nullable(),
});
export const createWholesalerSchema = (trans: any) => yup.object().shape({
  name: yup
    .string()

    .required("Wholesaler Name is required"),
  address_line_1: yup.string().nullable().optional(),
  address_line_2: yup.string().nullable().optional(),
  state: yup.object().nullable().optional(),
  city: yup.string().nullable().optional(),
  zip_code: yup.string().nullable().optional(),
  fax: yup.string().nullable().optional(),
  contact_name: yup.string().required("Contact Name is Required"),
  bank_account: yup.string().required("Bank account is required"),
  phone: yup.string().nullable().optional(),
  email: yup.string().required("Email is Required")
});

export function profileValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const profileValidationSchema = (trans: any) => yup.object().shape({
  first_name: yup.string().required(trans("first_name_required")),
  last_name: yup.string().required(trans("last_name_required")),
  email: yup.string().required(trans("email_required")),
  language: yup.string().required(trans("Language_is_required")),
  mobile: phoneSchema(true, trans("Invalid Mobile Number")).required(
    trans("Mobile_Number_is_required")
  ),
  address: yup.string().required(trans("Address_is_required")),
  zipcode: yup.string().required(trans("Zipcode_is_required")),
  cc_percentage: yup.string().required(trans("Credit Card Percentage is Required")),
  dc_percentage: yup.string().required(trans("Debit Card Percentage is Required")),
  ach_flat_value: yup.string().required(trans("ACH Value is Required")),

})


export function createLocationValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}
export function createWholesalerValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}
export function CreateReconciliationValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const createReconciliationValidationSchema = (trans: any) => yup.object().shape({
  path: yup.string().required(trans("Document_is_required")),
  file_type: yup.string().required(trans("file_type_required")),
});

export function udpateLandingPageValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const udpateLandinPageValidationSchema = (trans: any) => yup.object().shape({
  path: yup.string().required(trans("Image is required")),
  file_type: yup.string().required(trans("File Type is required")),
  tag_line: yup.string().required(trans("Tag Line is required")),
  header_contact: yup.string().required(trans("Header Contact is required")),
  header_email: yup
    .string()
    .required(trans("Header Email is required"))
    .trim()
    .matches(emailRegex, trans("Invalid email id")),
  banner_heading: yup.string().required(trans("Banner Heading is required")),
  banner_text: yup.string().required(trans("Banner text is required")),
  what_we_do_section: yup
    .string()
    .required(trans("what we can do section is required")),
  address_section: yup.string().required(trans("Address Section is required")),
  license: yup.string().required(trans("License Number is required")),
  testimonials1_content: yup
    .string()
    .required("Testimonals 1 is required")
    .max(200, "Maximum 200 characters only"),
  testimonials2_content: yup
    .string()
    .required("Testimonals 2 is required")
    .max(200, "Maximum 200 characters only"),
  testimonials3_content: yup
    .string()
    .required("Testimonals 3 is required")
    .max(200, "Maximum 200 characters only"),
  testimonials1_avatar: yup
    .string()
    .required("Avatar 1 is required")
    .max(25, "Maximum 25 characters only"),
  testimonials2_avatar: yup
    .string()
    .required("Avatar 2 is required")
    .max(25, "Maximum 25 characters only"),
  testimonials3_avatar: yup
    .string()
    .required("Avatar 3 is required")
    .max(25, "Maximum 25 characters only"),
  testimonials1_company: yup
    .string()
    .required("Company 1 is required")
    .max(20, "Maximum 20 characters only"),
  testimonials2_company: yup
    .string()
    .required("Company 2 is required")
    .max(20, "Maximum 20 characters only"),
  testimonials3_company: yup
    .string()
    .required("Company 3 is required")
    .max(20, "Maximum 20 characters only"),
  theme_color: yup.string().required(trans("Theme color is required")),
  button_background_color: yup.string().required(trans("Button color is required")),
});

export function paymentConfigPageValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const paymentConfigPageValidationSchema = (trans: any) => yup.object().shape({
  merchant_id: yup.string().required(trans("Merchant id is required")),
  merchant_user_id: yup.string().required(trans("Merchant user id is required")),
  payment_url: yup.string().required(trans("Payment URL is required")),
  ach_pin: yup.string().required(trans("ach pin is required")),
  cc_pin: yup.string().required(trans("CC pin is required")),
  // surcharge: yup
  //   .number()
  //   .typeError("Surcharge is required")
  //   .required("Surcharge is required")
  //   .min(0.01, "Surcharge cannot be zero or negative")
  //   .max(5, "Surcharge must be five or less"),
});

export function emailConfirgurationPagevalidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const emailConfirgurationPagevalidationSchema = (trans: any) => yup.object().shape({
  email: yup.string().required(trans("email_required")),
  emailPassword: yup.string().required(trans("Email_Password_is_required")),
  emailHost: yup.string().required(trans("Email_Host_is_required")),
  emailPort: yup.number().required(trans("Email_Port_is_required")),
  fromEmail: yup.string().required(trans("From_Email_is_required")),
});


export function smsConfirgurationPagevalidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const smsConfirgurationPagevalidationSchema = (trans: any) => yup.object().shape({
  twilio_accountSid: yup.string().required("Twillio Account Sid is required"),
  twilio_authToken: yup.string().required("Twillio Auth Token is required"),
  twilio_fromNumber: yup.string().required("Twillio Phone Number is required"),
})


export function posIntegrationPagevalidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const posIntegrationPagevalidationSchema = (trans: any) => yup.object().shape({
  username: yup.string().required("Username is required"),
  password: yup.string().required("Password is required"),
})


export function createFeeValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const createFeeValidationSchema = (trans: any) => yup.object().shape({
  name: yup.string().required("Fee Name is required"),
  recurring_fee: yup.string().optional().nullable(),
  apply_to_all_coverage: yup.boolean().optional(),
  feeCoverageTypes: yup.array().when("apply_to_all_coverage",{
    is:(val:any) => val, 
    then: () => yup.array().optional(),
    otherwise: () => yup.array().min(1, "At least one Fee Coverage Type is required") // Ensures at least one item
    .required("Fee Coverage Types are required"), // Adds an additional layer of validation
  })
})

export function createAgencyCommissionValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const createAgencyCommissionValidationSchema = (trans: any) => yup.object().shape({
  insuranceCompany: yup.string().nullable().optional(),
  state: yup.string().optional().nullable(),
  policy_type: yup.string().optional().nullable(),
  whole_saler: yup.string().optional().nullable(), 
  starting_effective_date:yup.string().optional().nullable(), 

})

export function createFinanceCompanyValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const createFinanceCompanyValidationSchema = (trans: any) => yup.object().shape({
  name: yup.string().required("Finance Company is required"),
  address_line_1: yup.string().required("Address 1 is required"),
  address_line_2: yup.string().required("Address 2 is required"),
  city: yup.string().optional().nullable(),
  zip_code: yup.string().optional().nullable(),
  state: yup.string().optional().nullable(),
  phone: yup.string().optional().nullable(),
  fax: yup.string().optional().nullable(),
  email: yup.string().optional().nullable(),
  contact_name: yup.string().optional().nullable(),
  account: yup.string().optional().nullable(),
  website: yup.string().optional().nullable(),

})

export function ReplaceDriverValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}


export const ReplaceDriverValidationSchema = (trans: any) => yup.object().shape({
  old_driver_id: yup.string().required("Old driver is required"),
  new_driver_id: yup.string().required("New driver is required"),

})

export function ReplaceVehicleValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}


export const ReplaceVehicleValidationSchema = (trans: any) => yup.object().shape({
  old_vehicle_id: yup.string().required("Old Vehicle is required"),
  new_vehicle_id: yup.string().required("New Vehicle is required"),

})

export function createReferenceValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const createReferenceValidationSchema = (trans: any) => yup.object().shape({
  reference_name: yup.string().nullable().optional(),
  reference_type: yup.string().optional().nullable(),
  of_agent: yup.string().optional().nullable(),
  date_terminated: yup.string().optional().nullable(),
  agency_name: yup.string().optional().nullable(),
  address_line_1: yup.string().optional().nullable(),
  address_line_2: yup.string().optional().nullable(),
  city: yup.string().optional().nullable(),
  state: yup.string().optional().nullable(),
  zip_code: yup.string().optional().nullable(),
  phone: yup.string().optional().nullable(),
  fax: yup.string().optional().nullable(),
  email: yup.string().optional().nullable(),
  agency_code: yup.string().optional().nullable(),
  license_number: yup.string().optional().nullable(),
  tax_id_number: yup.string().optional().nullable(),

})




export function createReferenceContactValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}
export function createRoldexContactValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}


export const createReferenceContactValidationSchema = (trans: any) => yup.object().shape({
  contact_name: yup.string().nullable().optional(),
  phone: yup.string().optional().nullable(),
  phone_type: yup.string().optional().nullable(),
  title: yup.string().optional().nullable(),
})
export const createRolodexContactValidationSchema = (trans: any) => yup.object().shape({
  prefix: yup.string().required("Prefix is required."),
  suffix: yup.string().nullable(), // Optional
  title: yup.string().nullable(), // Optional
  first_name: yup.string().required("First name is required."),
  last_name: yup.string().required("Last name is required."),
  dob: yup.string().nullable(), // Optional
  website: yup.string().nullable(), // Optional but must be valid if provided
  emails: yup.string().nullable(), // Optional array of valid emails
  phones: yup.string().nullable()

})



export function addAcidentValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const addAcidentValidationSchema = (trans: any) => yup.object().shape({
  description: yup.string().required("description is required"),
  date: yup.string().required("date is required"),
  state: yup.string().required("state is required"),
  non_chargeable_code: yup.string().required("non_chargeable_code is required"),
  amount: yup.string().required("amount is required"),
});


export function createQuickReceiptValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}


export const createQuickReceiptValidationSchema = (trans: any) => yup.object().shape({
  client_type: yup.string().required("client Type is required"),
  date_received:yup.string().optional().nullable(),
  time_received:yup.string().optional().nullable(),
  receipt_number:yup.string().optional().nullable(),
  first_name:yup.string().optional().nullable(),
  last_name:yup.string().optional().nullable(),
  address:yup.string().optional(),  
  state:yup.string().optional(),
  city:yup.string().optional(),
  zip_code:yup.string().optional(),
  work_phone:yup.string().optional(),
  cell_phone:yup.string().optional(),
  email:yup.string().optional(),
  
});

export function voidReasonValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const voidReasonValidationSchema = (trans: any) => yup.object().shape({
  void_reason: yup.string().required("Void Reason is required"),
  
});

export function quotationValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const quotationValidationSchema = (trans: any) => yup.object().shape({
  entity_type: yup.string().required("Entity Type is required"),
  first_name: yup.string().when('entity_type', (entity_type:any, schema) => {
    return entity_type === 'Individual'
      ? schema.required('First Name is required')
      : schema.notRequired();
  }),
  last_name: yup.string().when('entity_type', (entity_type:any, schema) => {
    return entity_type === 'Individual'
      ? schema.required('Last Name is required')
      : schema.notRequired();
  }),
  company_name: yup.string().when('entity_type', (entity_type:any, schema) => {
    return entity_type === 'Corporation'
      ? schema.required('Company Name is required')
      : schema.notRequired();
  }),
  email_type: yup.string().required("Email Type is required"),
  email: yup.string().required("Email is required"),
  is_primary_email: yup.boolean().optional(),
  phone_type: yup.string().required("Phone Type is required"),
  phone_number: yup.string().required("Phone Number is required"),
  is_primary_phone: yup.string().optional(),
});


export function voidRecepitValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const voidReceiptValidationSchema = (trans: any) => yup.object().shape({
  void_receipt: yup.string().required("Void Reason is required"),
  
});
export const sendEmailValidationSchema = (trans: any) => yup.object().shape({
  subject: yup.string().required("Subject is required"),
  content : yup.string().required("Content is required"),
  to : yup.string()
  .matches(emailRegex, trans("Invalid email id")).required('To is required')
});


export function sendEmailValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}


export function createTestimonialValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}
export const createTestimonialValidationSchema = (trans: any) => yup.object().shape({
  testimonial: yup
    .string()
    .required(trans("Testimonial description is required"))
    .max(200, "Maximum 200 characters only"),
  avatarName: yup
    .string()
    .required(trans("Name is required"))
    .max(20, "Maximum 20 characters only"),
  avatarCompany: yup
    .string()
    .required(trans("Company Name is required"))
    .max(20, "Maximum 20 characters only"),
  path: yup.string().required(trans("Avatar Image is required")),
  file_type: yup.string().required(trans("File Type is required")),
});

export function createQuotationTaxValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export let createQuotationTaxValidationSchema = (trans: any) => yup.object().shape({
  tax_id_type_cd: yup
    .object()
    .required(trans("Tax_Type_is_required"))
    .typeError("Tax Type is required"),
  tax_id: yup.string().when("tax_id_type_cd", {
    is: (tax: any) => tax?.name == "Tax Identification Number",
    then: () => yup.string().required(trans("tax_id_required")),
    otherwise: () => yup.string().nullable().optional(),
  }),
  social_security: yup.string().when("tax_id_type_cd", {
    is: (tax: any) => tax?.name == "Social Security Number",
    then: () =>
      yup
        .string()
        .required(trans("social_security_required"))
        .test("is-valid-ssn", "Invalid ssn", (value) => {
          if (value) {
            return yup.string().matches(SSNPattern).isValidSync(value);
          }
          return true; // Allow empty value
        }),
    otherwise: () => yup.string().nullable().optional(),
  }),

  recipients1099: yup.boolean().optional(),

  recipients1099_recieve_date: yup.string().when("recipients1099", {
    is: (val: any) => val,
    then: () =>
      yup
        .date()
        .required("Recipients 1099 Recieve Date is required")
        .typeError("Recipients 1099 Recieve Date is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  recipients1099_date_from: yup.string().when("recipients1099", {
    is: (val: any) => val,
    then: () =>
      yup
        .date()
        .required("Recipients 1099 From Date is required")
        .typeError("Recipients 1099 From Date is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  recipients1099_date_to: yup.string().when("recipients1099", {
    is: (val: any) => val,
    then: () =>
      yup
        .date()
        .min(
          yup.ref("recipients1099_date_from"),
          "To Date must be after the From Date"
        )
        .typeError("Recipients 1099 To Date is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),

  w9_recieved: yup.boolean().optional(),

  w9_recieved_date: yup.string().when("w9_recieved", {
    is: (val: any) => val,
    then: () =>
      yup
        .date()
        .required("W9 Recipients Recieve Date is required")
        .typeError("W9 Recipients Recieve Date is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  w9_recieved_from: yup.string().when("w9_recieved", {
    is: (val: any) => val,
    then: () =>
      yup
        .date()
        .required("W9 Recipients From Date is required")
        .typeError("W9 Recipients From Date is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  w9_recieved_to: yup.string().when("w9_recieved", {
    is: (val: any) => val,
    then: () =>
      yup
        .date()
        .min(yup.ref("w9_recieved_from"), "To Date must be after the From Date")
        .typeError("W9 Recipients To Date is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  nsf_restricted: yup.boolean().optional(),
});

export function RemainderValidation(values: any) {
  return handleErrorMeg(values, RemainderValidationSchema);
}
export const RemainderValidationSchema = (trans: any) => yup.object().shape({
  remainder_name: yup
    .string()
    .trim()
    .max(50, "Max 50 Characters")
    .required("Remainder Name is Required"),
  no_of_days: yup
    .string()
    .required("Number of Days is Required")
    .matches(
      /^\d+(,\d+)*$/,
      "The input should contain only numbers separated by commas, with no consecutive commas."
    ),
  heading: yup
    .string()
    .trim()
    .max(50, "Max 50 Characters")
    .required("Heading is Required"),
  custom_message: yup
    .string()
    .max(5000, "Max 5000 Characters")
    .required("Custom Message is Required"),
  type: yup.string().required(trans("Type is Required")),
  // is_active:yup.boolean()
});

export let ChequeValidationSchema = (trans: any) => yup.object().shape({
  check: yup.string().optional().nullable(),
  amount_in_words: yup.string().optional().nullable(),
  pay_to_order_of: yup.string().optional().nullable(),
  print_draft_date: yup.string().optional().nullable(),
  signature: yup.string().optional().nullable(),
  confirm: yup.string().optional().nullable(),
  reason: yup.string().optional().nullable(),
  cheque_status: yup
    .string()
    .required(trans("Status_is_Required"))
    .typeError("Status is Required"),
  description: yup.string().optional().nullable(),
  cheque_drop_date: yup
    .date()
    .required(trans("Date_is_required"))
    // .min(yup.ref("print_draft_date"), "Date must be after the Print Draft Date")
    .typeError("Date is required"),
});

export function ChequeValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export function PolicyCancelValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const policyCancelSchema = (trans: any) => yup.object().shape({
  producer_name: yup
    .string()
    .trim()
    .max(50, "Max 50 Characters")
    .required(trans("Producer_Name_is_required")),

  // producer_address: yup
  //   .string()
  //   .trim()
  //   .max(100, "Max 100 Characters")
  //   .required("Producer Address is required"),
  producer_phone: phoneSchema(true, "Invalid Phone Number").required(
    trans("Phone_is_required")
  ),
  code: yup
    .string()
    .trim()
    .max(20, "Max 20 Characters")
    // .required("Code is required"),
    .optional()
    .nullable(),
  sub_code: yup
    .string()
    .trim()
    .max(20, "Max 20 Characters")
    .optional()
    .nullable(),
  agency_customer_id: yup
    .string()
    .trim()
    .max(30, "Max 30 Characters")
    .required(trans("Agency_Customer_ID_is_required")),
  company_name: yup
    .string()
    .trim()
    .max(50, "Max 50 Characters")
    .required("Compan_Name_is_required"),
  // company_address: yup
  //   .string()
  //   .trim()
  //   .max(100, "Max 100 Characters")
  //   .required("Company Address is required"),
  nac_address_line_one: yup
    .string()
    .trim()
    .max(100, "Max 100 Characters")
    .required(trans(" Address_is_required")),
  producer_address_line_one: yup
    .string()
    .trim()
    .max(100, "Max 100 Characters")
    .required(trans("Address_is_required")),
  insured_address_line_one: yup
    .string()
    .trim()
    .max(100, "Max 100 Characters")
    .required(trans(" Address_is_required")),
  party_address_line_one: yup
    .string()
    .trim()
    .max(100, "Max 100 Characters")
    .required(trans(" Address_is_required")),
  naic_code: yup
    .string()
    .trim()
    .max(10, "Max 10 Characters")
    .optional()
    .nullable(),
  insured_name: yup
    .string()
    .trim()
    .max(50, "Max 50 Characters")
    .required(trans("Insured_Name_is_required")),
  // insured_address: yup
  //   .string()
  //   .trim()
  //   .max(100, "Max 100 Characters")
  //   .required("Insured Address is required"),
  policy_number: yup
    .string()
    .trim()
    .max(20, "Max 20 Characters")
    .required(trans("Policy_Number_is_required")),
  cancellation_date: yup
    .date()
    .required(trans("Cancellation_Date_is_required"))
    .typeError("Cancellation Date is required"),
  cancellation_time: yup
    .date()
    .required(trans("Cancellation_Time_is_required"))
    .typeError("Cancellation Time is required"),
  // effective_date: yup
  //   .date()
  //   .required("Effective Date is required"),
  effective_date_of_cancellation: yup
    .date()
    .required(trans("Effective_Date_is_required"))
    .typeError("Effective Date is required"),
  expiration_date: yup
    .date()
    .required(trans("Expiration_Date_is_required"))
    .typeError("Expiration Date is required"),
  cancellation_request_policy_release: yup
    .string()
    .trim()
    .max(100, "Max 100 Characters")
    .required(trans("Cancellation_Request/Policy_Release_is_required")),
  signature_of_witness_path: yup
    .string()
    .required(trans("Signature_of_Witness_is_required")),
  signature_of_witness_type: yup
    .string()
    .required(trans("Signature_of_Witness_is_required")),
  date_witness: yup
    .date()
    .required(trans("Date (Witness)_is_required"))
    .typeError("Date (Witness) is required"),
  signature_of_the_name_insured_path: yup
    .string()
    .required(trans("Signature_of_Named_Insured_is_required")),
  signature_of_the_name_insured_type: yup
    .string()
    .required(trans("Signature_of_Named_Insured_is_required")),
  date_named_insured: yup
    .date()
    .required(trans("Date (Named Insured)_is_required"))
    .typeError("Date (Named Insured) is required"),
  authorized_signature_path: yup
    .string()
    .required(trans("Authorized_Signature_is_required")),
  authorized_signature_type: yup.string().optional(),
  title: yup
    .string()
    .trim()
    .max(50, "Max 50 Characters")
    .required(trans("Title_is_required")),
  date_authorized: yup
    .date()
    .required(trans("Date (Authorized)_is_required"))
    .typeError("Date (Authorized) is required"),
  additional_interest_name: yup
    .string()
    .trim()
    .max(50, "Max 50 Characters")
    .optional()
    .nullable(),
  additional_interest_address_line_one: yup
    .string()
    .trim()
    .max(100, "Max 100 Characters")
    .optional()
    .nullable(),
  type_of_additional_interest: yup
    .string()
    .trim()
    .max(50, "Max 50 Characters")
    .optional()
    .nullable(),
  reason_for_cancellation: yup
    .string()
    .trim()
    .max(100, "Max 100 Characters")
    .required(trans("Reason_for_Cancellation_is_required")),
  info: yup
    .string()
    .trim()
    .max(100, "Max 100 Characters")
    .when("reason_for_cancellation", {
      is: (val: any) => val === "Other",
      then: () => yup.string().required(trans("Info_is_Required")),
      otherwise: () => yup.string().nullable().optional(),
    }),
  company_rewritten: yup
    .string()
    .trim()
    .max(50, "Max 50 Characters")
    .when("reason_for_cancellation", {
      is: (val: any) => val === "Rewritten",
      then: () => yup.string().required(trans("Company (Rewritten)_is_required")),
      otherwise: () => yup.string().nullable().optional(),
    }),
  policy_number_rewritten: yup
    .string()
    .trim()
    .max(20, "Max 20 Characters")
    .when("reason_for_cancellation", {
      is: (val: any) => val === "Rewritten",
      then: () =>
        yup.string().required(trans("Policy Number (Rewritten)_is_required")),
      otherwise: () => yup.string().nullable().optional(),
    }),
  effective_date_rewritten: yup
    .date()
    .typeError("Effective Date is required")
    .when("reason_for_cancellation", {
      is: (val: any) => val === "Rewritten",
      then: () =>
        yup
          .date()
          .required(trans("Effective_Date_is_required"))
          .typeError("Effective Date is required"),
      otherwise: () =>
        yup
          .date()
          .nullable()
          .optional()
          .typeError("Effective Date is required"),
    }),
  method_of_cancellation: yup
    .string()
    .trim()
    .max(50, "Max 50 Characters")
    .required(trans("Method_of_Cancellation_is_required")),
  full_term_premium: yup.number().required(trans("Full Term Premium_is_required")),
  unearned_factor: yup.number().required(trans("Unearned Factor_is_required")),
  returned_premium: yup.number().required(trans("Returned Premium_is_required")),
  remarks: yup
    .string()
    .trim()
    .max(200, "Max 200 Characters")
    .required(trans("Remarks_are_required")),
  loss_payee_mortgagee_lienholder: yup
    .string()
    .trim()
    .max(50, "Max 50 Characters")
    .required(trans("Loss_Payee/Mortgagee/Lienholder_is_required")),
  party_name: yup
    .string()
    .trim()
    .max(50, "Max 50 Characters")
    .required(trans("Party’s Name_is_required")),
  // party_address: yup
  //   .string()
  //   .trim()
  //   .max(100, "Max 100 Characters")
  //   .required("Party’s Address is required"),
  producer_signature_path: yup
    .string()
    .required(trans("Producer Signature_is_required")),
  producer_signature_type: yup
    .string()
    .required(trans("Producer Signature_is_required")),
  date_producer: yup
    .date()
    .required((trans("Date (Producer)_is_required")))
    .typeError("Date (Producer) is required"),
  // address_line_one: yup.string().required("Address is required"),
  address_line_two: yup.string().nullable(),
  insured_address_line_two: yup.string().nullable(),
  party_address_line_two: yup.string().nullable(),
  nac_address_line_two: yup.string().nullable(),
  // producer_address_line_two: yup.string().nullable(),
  // state: yup
  // .object()
  // .required("State is required")
  // .typeError("State is required"),
  party_state: yup
    .object()
    .required(trans("State_is_required"))
    .typeError("State is required"),
  nac_state: yup
    .object()
    .required(trans("State_is_required"))
    .typeError("State is required"),
  insured_state: yup
    .object()
    .required(trans("State_is_required"))
    .typeError("State is required"),
  producer_state: yup
    .object()
    .required(trans("State_is_required"))
    .typeError("State is required"),
  // city: yup.string().required("City is required"),
  party_city: yup.string().required(trans("City_is_required")),
  nac_city: yup.string().required(trans("City_is_required")),
  insured_city: yup.string().required(trans("City_is_required")),
  producer_city: yup.string().required(trans("City_is_required")),
  // zipcode: yup
  //   .string()
  //   .test("is-valid-zipcode", "Invalid Zip Code", (value) => {
  //     if (value) {
  //       return yup.string().matches(usZipRegex).isValidSync(value);
  //     }
  //     return true; // Allow empty value
  //   }).required("Zipcode is required"),
  party_zipcode: yup
    .string()
    .test("is-valid-zipcode", "Invalid Zip Code", (value) => {
      if (value) {
        return yup.string().matches(usZipRegex).isValidSync(value);
      }
      return true; // Allow empty value
    })
    .required(trans("Zipcode_is_required")),
  insured_zipcode: yup
    .string()
    .test("is-valid-zipcode", "Invalid Zip Code", (value) => {
      if (value) {
        return yup.string().matches(usZipRegex).isValidSync(value);
      }
      return true; // Allow empty value
    })
    .required(trans("Zipcode_is_required")),
  nac_zipcode: yup
    .string()
    .test("is-valid-zipcode", "Invalid Zip Code", (value) => {
      if (value) {
        return yup.string().matches(usZipRegex).isValidSync(value);
      }
      return true; // Allow empty value
    })
    .required(trans("Zipcode_is_required")),
  producer_zipcode: yup
    .string()
    .test("is-valid-zipcode", "Invalid Zip Code", (value) => {
      if (value) {
        return yup.string().matches(usZipRegex).isValidSync(value);
      }
      return true; // Allow empty value
    })
    .required(trans("Zipcode_is_required")),
});

export let createCompanyBankValidationSchema = (trans: any) => yup.object().shape({
  insurance_company: yup.string().required(trans("Insurance_Company_is_required")),
  bank: yup.string().required(trans("Bank_is_required")),
  routing_no: yup.number().required(trans("Routing_No_is_required")),
  account_no: yup.number().required(trans("Account_No_is_required")),
  account_type: yup.string().required(trans("Account_Type_is_required")),
  starting_check_no: yup.string().required(trans("Starting_Check_no_is_required")),
  name_of_account: yup.string().required(trans("Account_Name_is_required")),
  entity_type: yup.string().required(trans("Entity_type_is_required")),
  address: yup.string().required(trans("Address_is_required")),
});

export function createCompanyBankValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export let leadModalValidationSchema = (trans: any) => yup.object().shape({
  lead: yup.string().trim().min(7).required(trans("Lead number is required")),
});

export function leadModalValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export function CreateCompanyCommissionValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const createCompanyCommissionValidationSchema = (trans: any) => yup.object().shape({
  name: yup
    .object()
    .required(trans("Insurance_Company_Name_is_required"))
    .typeError(trans("Insurance_Company_Name_is_required")),
  commission: yup.array().of(
    yup.object().shape({
      percentage_of_payment: yup
        .number()
        .typeError("Percentage of Payment is required")
        .required(trans("Percentage_of_Payment_is_required"))
        .min(0.01, "Percentage of Payment cannot be negative")
        .max(100, "Percentage of Payment must be 100 or less"),

      percentage_of_commission: yup
        .number()
        .typeError("Percentage of Commission is required")
        .required(trans("Percentage_of_Commission_is_required"))
        .min(0, "Percentage of Commission cannot be negative")
        .max(100, "Percentage of Commission must be 100 or less"),
    })
  ),
});

export let CommissionsModalValidationSchema = (trans: any) => yup.object().shape({
  check: yup.string().required(trans("Check Number is required")),
  amount_in_words: yup.string().required(trans("Amount in Words is required")),
  pay_to_order_of: yup.string().required(trans("Pay to Order of is required")),
  print_draft_date: yup
    .string()
    .required("Print Draft Date is required")
    .typeError("Print Draft Date is required")
    .test(
      "notBeforeDateLicensed",
      "Print Draft Date can't be past Date",
      function (value) {
        if (!value) return true;
        return (
          // !dayjs().add(3, "month").isAfter(value) &&
          !dayjs().add(6, "month").isBefore(value)
        );
      }
    ),
  // signature: yup.string().required("Signature of is required"),
  confirm: yup.string().optional().nullable(),
  reason: yup.string().optional().nullable(),
});

export function CommisionModalValidation(values: any, schema: any) {
  return handleErrorMeg(values, CommissionsModalValidationSchema);
}

export let createPolicyCliamScheduleValidationSchema = (trans: any) => yup.object().shape({
  vehicle: yup.object().required(trans("Vehicle_is_required")),
  driver: yup.object().required(trans("Driver_is_required")),
});

export function createPolicyCliamScheduleValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}
export let createDocusignFormValidationSchema = (trans: any) => yup.object().shape({
  formType: yup.object().required(trans("Form Type is required")),
  fileName: yup.string().required(trans("File Name is required")),
  sendEmail: yup.string().required(trans("Send a copy to mail is required")),
});

export function createDocusignFormValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export let createDocusignFormEditValidationSchema = (trans: any) => yup.object().shape({
  fileName: yup.string().required(trans("File Name is required")),
});

export function createDocusignFormEditValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export let createLocationBankValidationSchema = yup.object().shape({
  // insurance_company: yup.string().required("Insurance Company is required"),
  bank: yup.string().required("Bank is required"),
  routing_no: yup.number().required("Routing No is required"),
  account_no: yup.number().required("Account No is required"),
  account_type: yup.string().required("Account Type is required"),
  starting_check_no: yup.string().required("Starting Check no is required"),
  name_of_account: yup.string().required("Account Name is required"),
  entity_type: yup.string().required("Entity Type is required"),
  address: yup.string().required("Address is required"),
});

export function createLocationBankValidation(values: any) {
  return handleErrorMeg(values, createLocationBankValidationSchema);
}

export function CreateAmountPaidValidation(values: any, schema: any) {
  return handleErrorMeg(values, schema);
}

export const createAmountPaidValidationSchema = (trans: any) => yup.object().shape({
  advance_payments: yup.array().of(
    yup.object().shape({
      amount_received: yup
        .number()
        .typeError(trans("Amount_Received_is_required"))
        .required(trans("Amount_Received_is_required")),

      paymentMethodId: yup
        .string()
        .typeError(trans("Payment_Method_is_required"))
        .required(trans("Payment_Method_is_required")),

      date_paid: yup.date()
        .typeError(trans("Date_Paid_is_required"))
        .required(trans("Date_Paid_is_required"))
    })
  ),
});


export const createContactSchema = (trans: any) => yup.object().shape({
  title: yup.string().required(trans("Title_is_required")),
  prefix: yup.string().nullable().optional(),
  first_name: yup.string().required(trans("first_name_required")),
  middle_name: yup.string().nullable().optional(),
  last_name: yup.string().required(trans("last_name_required")),
  suffix: yup.string().nullable().optional(),
  gender: yup.string().nullable().optional(),
  dob: yup.date().optional().nullable(),
  marital_status: yup.string().optional().nullable(),
  email: yup.string().trim().optional().nullable(),
  date_terminated: yup.date().nullable().optional(),
  date_employed: yup.date().nullable().optional(),
  social_security: yup
    .string()
    .nullable()
    .optional()
    .test("is-valid-ssn", trans("ssn_invalid"), (value) => {
      if (value) {
        return yup.string().matches(SSNPattern).isValidSync(value);
      }
      return true; // Allow empty value
    }),

  address_line_1: yup.string().nullable().optional(),
  address_line_2: yup.string().nullable().optional(),
  city: yup.string().nullable().optional(),
  state: yup.string().nullable().optional(),
  zip_code: yup.string()
    .nullable()
    .optional()
    .test('is-valid-zip', 'Invalid Zip Code', function (value) {
      // Check if the value is provided, then validate
      if (value) {
        return usZipRegex.test(value);
      }
      return true; // If no value, return true (valid)
    }),
  work_phone: phoneSchema(true, "Invalid Mobile Number").nullable().optional(),
  cell_phone: phoneSchema(true, "Invalid Mobile Number").nullable().optional(),
  home_phone: phoneSchema(true, "Invalid Mobile Number").nullable().optional(),
  relationship_to_insured: yup.string().required(trans("relationship_to_insured_required")),
});


export const createChangeRequestDriverSchema = (trans: any) => yup.object().shape({
  type_of_change: yup
  .object()
  .required(trans("type_of_change_required"))
  .typeError(trans("type_of_change_required")),
  effective_date_of_change: yup.date().required(trans("effective_date_change_required")).typeError(trans("effective_date_change_required")),
  driver: yup.object()
  .when("type_of_change", {
    is: (val: any) => val?.name !== "Add Driver",
    then: () =>
    yup.object().required(trans("Driver_is_required")),
    otherwise: () => yup.object().nullable().optional(),
  }),
  
  prefix: yup.string().nullable().optional(),
  first_name: yup.string()
  .when("type_of_change", {
    is: (val: any) => val?.name !== "Delete Driver",
    then: () =>
    yup.string().required(trans("first_name_required")),
    otherwise: () => yup.string().nullable().optional(),
  }),
  middle_name: yup.string().nullable().optional(),
  last_name: yup.string()
  .when("type_of_change", {
    is: (val: any) => val?.name !== "Delete Driver",
    then: () =>
    yup.string().required(trans("last_name_required")),
    otherwise: () => yup.string().nullable().optional(),
  }),
  suffix: yup.string().nullable().optional(),
  gender: yup.string()
  .when("type_of_change", {
    is: (val: any) => val?.name !== "Delete Driver",
    then: () =>
    yup.string().required(trans("gender_required")),
    otherwise: () => yup.string().nullable().optional(),
  }),
  marital_status: yup.string().nullable().optional(),
  dob: yup.date()
  .when("type_of_change", {
    is: (val: any) => val?.name !== "Delete Driver",
    then: () =>
    yup.string().required(trans("dob_required")).typeError(trans("dob_required")),
    otherwise: () => yup.string().nullable().optional(),
  }),
  
  occupation: yup.string()
  .when("type_of_change", {
    is: (val: any) => val?.name !== "Delete Driver",
    then: () =>
    yup.string().required(trans("occuption_required")),
    otherwise: () => yup.string().nullable().optional(),
  }),
  date_licensed: yup.string()
    .when("type_of_change", {
      is: (val: any) => val?.name !== "Delete Driver",
      then: () =>
      yup.date()
    .required(trans("date_licensed_required"))
    .max(new Date(), "Date licensed cannot be in the future")
    .typeError(trans("date_licensed_required")),
      otherwise: () => yup.string().nullable().optional(),
    }),
  license_number: yup.string()
  .when("type_of_change", {
    is: (val: any) => val?.name !== "Delete Driver",
    then: () =>
    yup.string().required(trans("license_number_required")),
    otherwise: () => yup.string().nullable().optional(),
  }),
  state_licensed: yup.object()
  .when("type_of_change", {
    is: (val: any) => val?.name !== "Delete Driver",
    then: () =>
    yup.object()
    .required(trans("licensed_state_required"))
    .typeError(trans("licensed_state_required")),
    otherwise: () => yup.object().nullable().optional(),
  }),
  
  social_security: yup.string()
  .when("type_of_change", {
    is: (val: any) => val?.name !== "Delete Driver",
    then: () => yup.string()
    .required(trans("Social Security Number is required"))
    .test("is-valid-ssn", "Invalid ssn", (value) => {
      if (value) {
        return yup.string().matches(SSNPattern).isValidSync(value);
      }
      return true; // Allow empty value
    }),
    otherwise: () => yup.string().nullable().optional(),
  }),
  relationship_to_insured: yup.string().nullable().optional(),
  defensive_driving_date: yup.string().nullable().optional(),
});

export const createChangeRequestVehicleSchema = (trans: any) => yup.object().shape({

  type_of_change: yup
  .object()
  .required(trans("type_of_change_required"))
  .typeError(trans("type_of_change_required")),

  effective_date_of_change: yup.date().required(trans("effective_date_change_required")).typeError(trans("effective_date_change_required")),

  vehicles: yup.object()
  .when("type_of_change", {
    is: (val: any) => val?.name !== "Add Vehicle",
    then: () =>
    yup.object().required(trans("Vehicle_is_required")),
    otherwise: () => yup.object().nullable().optional(),
  }),

  vin: yup.string()
  .when("type_of_change", {
    is: (val: any) => val?.name !== "Delete Vehicle",
    then: () =>
    vinSchema(true, trans("vin")).required(trans("vin")),
    otherwise: () => yup.string().nullable().optional(),
  }),
  
  model: yup.string()
  .when("type_of_change", {
    is: (val: any) => val?.name !== "Delete Vehicle",
    then: () =>
    yup.string().required(trans("model_required")),
    otherwise: () => yup.string().nullable().optional(),
  }),
 
  make: yup.string()
  .when("type_of_change", {
    is: (val: any) => val?.name !== "Delete Vehicle",
    then: () =>
    yup.string().required(trans("make_required")),
    otherwise: () => yup.string().nullable().optional(),
  }),
  
  year: yup.string()
  .when("type_of_change", {
    is: (val: any) => val?.name !== "Delete Vehicle",
    then: () =>
    yup.string().required(trans("year_required")).typeError(trans("year_required")),
    otherwise: () => yup.string().nullable().optional(),
  }),
  
  number_plate: yup.string()
  .when("type_of_change", {
    is: (val: any) => val?.name !== "Delete Vehicle",
    then: () =>
    yup.string().required(trans("number_plate")),
    otherwise: () => yup.string().nullable().optional(),
  }),
  
  shl_permit: yup.string()
  .when("type_of_change", {
    is: (val: any) => val?.name !== "Delete Vehicle",
    then: () =>
    yup.string().required(trans("shl_required")),
    otherwise: () => yup.string().nullable().optional(),
  }),
  state_registered: yup.object()
  .when("type_of_change", {
    is: (val: any) => val?.name !== "Delete Vehicle",
    then: () =>
    yup.object()
    .required(trans("state_registred"))
    .typeError(trans("state_registred")),
    otherwise: () => yup.string().nullable().optional(),
  }),
  body_type: yup.string()
  .when("type_of_change", {
    is: (val: any) => val?.name !== "Delete Vehicle",
    then: () =>
    yup.string()
    .required(trans("body_type_required"))
    .typeError(trans("body_type_required")),
    otherwise: () => yup.string().nullable().optional(),
  }),
  vehicle_usage: yup.string()
  .when("type_of_change", {
    is: (val: any) => val?.name !== "Delete Vehicle",
    then: () =>
    yup.string()
    .required(trans("vechicle_usage_required"))
    .typeError(trans("vechicle_usage_required")),
    otherwise: () => yup.string().nullable().optional(),
  }),
  performance: yup.string()
  .when("type_of_change", {
    is: (val: any) => val?.name !== "Delete Vehicle",
    then: () =>
    yup.string()
    .required(trans("performance_required"))
    .typeError(trans("performance_required")),
    otherwise: () => yup.string().nullable().optional(),
  }),
  territory: yup.string()
  .when("type_of_change", {
    is: (val: any) => val?.name !== "Delete Vehicle",
    then: () =>
    yup.string()
    .required(trans("territory_required"))
    .typeError(trans("territory_required")),
    otherwise: () => yup.string().nullable().optional(),
  }),
  comp_symbol: yup.string()
  .when("type_of_change", {
    is: (val: any) => val?.name !== "Delete Vehicle",
    then: () =>
    yup.string()
    .required(trans("comp_symbol_required"))
    .typeError(trans("comp_symbol_required")),
    otherwise: () => yup.string().nullable().optional(),
  }),
  collison_symbol: yup.string()
  .when("type_of_change", {
    is: (val: any) => val?.name !== "Delete Vehicle",
    then: () =>
    yup.string()
    .required(trans("collision_symbol_required"))
    .typeError(trans("collision_symbol_required")),
    otherwise: () => yup.string().nullable().optional(),
  }),
  cylinders: yup.string()
  .when("type_of_change", {
    is: (val: any) => val?.name !== "Delete Vehicle",
    then: () =>
    yup.string()
    .required(trans("cylinders_required"))
    .typeError(trans("cylinders_required")),
    otherwise: () => yup.string().nullable().optional(),
  }),
  horse_power: yup.string()
  .when("type_of_change", {
    is: (val: any) => val?.name !== "Delete Vehicle",
    then: () =>
    yup.string()
    .required(trans("horse_power_required")),
    otherwise: () => yup.string().nullable().optional(),
  }),
  // fuel_type: yup.string()
  // .when("type_of_change", {
  //   is: (val: any) => val?.name !== "Delete Vehicle",
  //   then: () =>
  //   yup.string()
  //   .required(trans("fuel_type_required")),
  //   otherwise: () => yup.string().nullable().optional(),
  // }),
  purchase_date: yup.date()
  .when("type_of_change", {
    is: (val: any) => val?.name !== "Delete Vehicle",
    then: () =>
    yup.string() 
    .required(trans("purchase_date_required"))
    .typeError(trans("purchase_date_required")),
    otherwise: () => yup.string().nullable().optional(),
  }),
  class_code: yup.string()
  .when("type_of_change", {
    is: (val: any) => val?.name !== "Delete Vehicle",
    then: () =>
    yup.string()
    .required(trans("class_code_required"))
    .typeError(trans("class_code_required")),
    otherwise: () => yup.string().nullable().optional(),
  }),
  vehicle: yup.string()
  .when("type_of_change", {
    is: (val: any) => val?.name !== "Delete Vehicle",
    then: () =>
    yup.string()
    .required(trans("vechicle_required"))
    .typeError(trans("vechicle_required")),
    otherwise: () => yup.string().nullable().optional(),
  }),
});


export let createEndorsementScheduleValidationSchema = (trans: any) => yup.object().shape({
  schedule: yup.array().of(
    yup.object().shape({
      due_date: yup
        .date()
        .required(trans("due_date_required"))
        .typeError(trans("due_date_required")),

        scheduled_amt: yup
        .number()
        .typeError(trans("scheduled_amt_required"))
        .required(trans("scheduled_amt_required")),

        reason: yup.string()
        .typeError(trans("reason_amt_required"))
        .required(trans("reason_amt_required")),

        type: yup.string()
        .typeError(trans("type_amt_required"))
        .required(trans("type_amt_required")),
    })
  )
});

export let ClientMultiPaymentModalValidationSchema = (trans: any) => yup.object().shape({
  total: yup.number().optional().nullable(),
  payment: yup.array().of(
    yup.object().shape({
    amount_received: yup
      .number()
      .required(trans("Amount_Received_is_required"))
      .typeError("Amount Received is required"),
    payment_method: yup
      .object()
      .required(trans("Payment_Method_is_required"))
      .typeError("Payment Method is required"),
    check: yup.string().when("payment_method", {
      is: (value: any) => {
        return value && value?.name === "CHECK";
      },
      then: () => yup.string().required(trans("Check_Number_is_required")),
      otherwise: () => yup.string().nullable(),
    }),
    amount_in_words: yup.string().when("payment_method", {
      is: (value: any) => {
        return value && value?.name === "CHECK";
      },
      then: () => yup.string().required(trans("Amount_in_Words_is_required")),
      otherwise: () => yup.string().nullable(),
    }),
    pay_to_order_of: yup.string().when("payment_method", {
      is: (value: any) => {
        return value && value?.name === "CHECK";
      },
      then: () => yup.string().required(trans("Pay_to_Order_of_is_required")),
      otherwise: () => yup.string().nullable(),
    }),
    print_draft_date: yup.string().when("payment_method", {
      is: (value: any) => {
        return value && value?.name === "CHECK";
      },
      then: () =>
        yup
          .date()
          .required(trans("Print_Draft_Date_is_required"))
          .typeError("Print Draft Date is required")
          .test(
            "notBeforeDateLicensed",
            "Print Draft Date can't be past Date",
            function (value) {
              if (!value) return true;
              return (
                // !dayjs().add(3, "month").isAfter(value) &&
                !dayjs().add(6, "month").isBefore(value)
              );
            }
          ),
      otherwise: () => yup.string().nullable(),
    }),
    signature: yup.string().when("payment_method", {
      is: (value: any) => {
        return value && value?.name === "CHECK";
      },
      then: () => yup.string().required(trans("Signature_of_is_required")),
      otherwise: () => yup.string().nullable(),
    }),
    confirm: yup.string().optional().nullable(),
    reason: yup.string().optional().nullable(),
})
)
});
