import { Box, Button, FormControl, Grid, IconButton, InputAdornment, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useTheme } from '@mui/material';
import FormLayout from 'component/form';
import { commonService } from 'forms/helpers/common.service';
import { InputField, SelectField } from 'forms/hoc/formfield';
import useForm from 'forms/hooks/useForm';
import { createPoliciesForCustomerValidation, createPoliciesForCustomerValidationSchema } from 'forms/hooks/validateRules';
import MainContainerWrapper from 'layout/wrappers/MainContainerWrapper';
import React, { useEffect, useState } from 'react'
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Success } from 'forms/helpers/toast.helper';
import { handleKeyDecimalPress } from 'forms/hooks/customHook';

function CreatePoliciesForCustomer({
    isEdit,
    isEditId,
    isBack
}: createPoliciesForCustomerType) {


    const { t } = useTranslation();
    const theme = useTheme();

    const { id } = useParams()
    const [loading, setLoading] = useState<boolean>(false);

    const [location, setLocation] = useState<any>([]);
    const [inputs, setInputs] = useState<any>([]);

    const createCustomerForPolicies = createPoliciesForCustomerValidationSchema(t)



    const headers = [
        { scheduled_amount: "Amount Due" },
        { broker_fee: "Broker Fee" },
        { amount_received: "Amount Received" },
        { action: "Action" }
    ];

    const transactionFeeList = [
        { name: 'Driver', value: 'Driver' },
        { name: 'Broker', value: 'Broker' },

    ]

    const addInput = () => {
        setInputs([...inputs, {
            scheduled_amount: null,
            broker_fee: null,
            who_has_to_pay_the_transaction_fee: "Driver",
            amount_received: "0.00",
            isNew: true // Mark as a newly added input

        }]); // Add a new input object
    };

    // Update the value of a specific input
    const handleInputChange = (index: number, key: string, value: any) => {
        const updatedInputs: any = [...inputs];
        updatedInputs[index][key] = value; // Update the specific key (description/amount)
        setInputs(updatedInputs);
    };


    // Remove a specific input field
    const removeInput = (index: any) => {
        const updatedInputs = inputs.filter((_: any, i: any) => i !== index);
        setInputs(updatedInputs);
    };


    useEffect(() => {
        getLocationList()
        if (isEdit) {
            getCustomerPoliciesOne()
        }
    }, [])

    const getLocationList = () => {
        commonService.getServices(`location/get-all-location`)
            .then((res: any) => {
                const locData = res?.data?.data?.results.map((e: any) => {
                    return {
                        name: e?.name,
                        value: e?.id
                    }
                })
                setLocation(locData)
            })
            .catch((err: any) => { })
    }


    const formSubmit = () => {

        const api = isEdit ? `excel-policy/update-policy` : `excel-policy/create-policy`
        const method = isEdit ? "patch" : "post"

        const bodyData = isEdit ? {
            ...values,
            id: isEditId,
            excel_transactions: inputs
        } :
            {
                ...values,
                customer: id,
                excel_transactions: inputs

            }

        commonService.postPutService(api, bodyData, method)
            .then((res: any) => {
                Success(res?.data?.message)
                isBack()
            })
            .catch((err: any) => { })
    }


    const {
        errors,
        values,
        handleChange,
        handleSubmit,
        setPatchValue,
        setUpdateValue,
    } = useForm({}, formSubmit, createPoliciesForCustomerValidation, createCustomerForPolicies);

    console.log("errs", errors);

    const getCustomerPoliciesOne = () => {
        commonService.getServices(`excel-policy/policy/${isEditId}`)
            .then((res: any) => {
                const data = res?.data?.data
                const patchData = {
                    policy_number: data?.policy_number,
                    location: data?.location?.id ?? null,
                }
                setPatchValue(patchData)

                const transactionFeeData = data?.excel_transactions.map((i: any) => {
                    return {
                        id: i?.id,
                        scheduled_amount: i?.scheduled_amount,
                        broker_fee: i?.broker_fee,
                        who_has_to_pay_the_transaction_fee: i?.who_has_to_pay_the_transaction_fee,
                        amount_received: i?.amount_received,
                        amount_type: i?.amount_type,
                    }
                })
                setInputs(transactionFeeData)

            })
    }

    const handleBack = () => {
        isBack()
    }


    console.log("innnnnn", inputs);


    return (

        <MainContainerWrapper
            headingText={!isEdit ? `Create Policy` : `Edit Policy`}
        >
            <Box sx={{ p: 2, height: "80vh", width: "100%" }}>
                <FormLayout
                    handleSubmit={handleSubmit}
                    isBackButton
                    backButtonClick={handleBack}
                    backButtonText={t("Cancel")}
                    isSaveButton
                >
                    <Grid container columnSpacing={2}>
                        <Grid item xs={12} lg={4}>
                            <InputField
                                fieldName={t("Policy Number")}
                                values={values?.policy_number || ""}
                                name="policy_number"
                                errors={errors?.policy_number}
                                handleChange={handleChange}
                                id="policy_number"
                                disabled={isEdit ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SelectField
                                fieldName={t("Location")}
                                values={values?.location || ""}
                                name="location"
                                errors={errors?.location}
                                handleChange={handleChange}
                                options={location || []}
                                id="location"
                                disabled={isEdit ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                        </Grid>
                        <Grid item xs={12} lg={12}>

                            <Box mt={5} py={2} pl={2} bgcolor={theme?.palette?.grey[200]}>
                                <Typography variant="h3" textAlign={"center"}>
                                    {"Account Statement"}
                                </Typography>
                            </Box>
                            <Box sx={{ textAlign: "end", py: 2, mr: 1.3 }}>
                                <Button
                                    onClick={addInput}
                                >
                                    Add
                                </Button>
                            </Box>
                            <Box sx={{ px: 2 }}>
                                <TableContainer
                                    sx={{
                                        border: '1px solid black', // Table border
                                        backgroundColor: '#f9f9f9', // Table background color
                                    }}
                                >
                                    <Table
                                        stickyHeader
                                        aria-labelledby="tableTitle"
                                        size="medium"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                {headers.map((header, index) => (
                                                    <TableCell
                                                        key={index}
                                                        align="center"
                                                        sx={{
                                                            backgroundColor: "#34aadc"
                                                        }}
                                                    >
                                                        <Typography variant="h4" fontWeight="bold">
                                                            {header.scheduled_amount || header.broker_fee || header?.amount_received || header.action}
                                                        </Typography>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {inputs.map((input: any, index: any) => (
                                                <TableRow
                                                    key={index}
                                                    sx={{
                                                        backgroundColor: index % 2 === 0 ? '#ffffff' : '#f5f5f5', // Alternate row background
                                                        // backgroundColor : input?.amount_type === "CREDIT" ? "green" : "#ffffff"
                                                    }}
                                                >
                                                    {/* Amount column with input */}
                                                    <TableCell
                                                        align="center"
                                                    >
                                                        <TextField
                                                            value={input.scheduled_amount}
                                                            onKeyPress={(e) => {
                                                                handleKeyDecimalPress(e);
                                                              }}
                                                            onChange={(e) => {
                                                                const value = e.target.value;
                                                                handleInputChange(index, "scheduled_amount", value); // Update state if valid
                                                            }}
                                                            onBlur={() => handleInputChange(index, "scheduled_amount", parseFloat(input.scheduled_amount || 0).toFixed(2))}
                                                            placeholder={`Amount Due ${index + 1}`}
                                                            variant="outlined"
                                                            type="text"
                                                            size="small"
                                                            disabled={isEdit && !input?.isNew}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        $
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        align="center"
                                                    >
                                                        <TextField
                                                            value={input.broker_fee}
                                                            onKeyPress={(e) => {
                                                                handleKeyDecimalPress(e);
                                                              }}
                                                            onChange={(e) => {
                                                                const value = e.target.value;
                                                                // Allow only numeric values
                                                                    handleInputChange(index, "broker_fee", value); // Update state if valid
                                                            }}
                                                            onBlur={() => handleInputChange(index, "broker_fee", parseFloat(input.broker_fee || 0).toFixed(2))}
                                                            placeholder={`Broker Fee ${index + 1}`}
                                                            variant="outlined"
                                                            type="text"
                                                            size="small"
                                                            disabled={isEdit && !input?.isNew}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        $
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </TableCell>

                                                    <TableCell
                                                        align="center"
                                                    >
                                                        <Typography>
                                                            {input?.amount_received === "0.00" ? "-" : input?.amount_received}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell
                                                        align="center"
                                                    >
                                                        <IconButton
                                                            color="error"
                                                            onClick={() => removeInput(index)}
                                                        >
                                                            <RemoveCircleIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>


                            </Box>
                        </Grid>
                    </Grid>
                </FormLayout>
            </Box>
        </MainContainerWrapper>
    )
}

export default CreatePoliciesForCustomer


type createPoliciesForCustomerType = {
    isEdit: boolean
    isEditId: string
    isBack?: any
}