import { Box } from '@mui/material'
import deleteAlert from 'component/HOC/deleteAlert'
import EnhancedTable from 'component/tables/selectableTable'
import { commonService } from 'forms/helpers/common.service'
import { Success } from 'forms/helpers/toast.helper'
import MainContainerWrapper from 'layout/wrappers/MainContainerWrapper'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { setReferenceID } from 'store/slice/reference.slice'
import { useAppDispatch } from 'store/store'

function RefernceList() {

  const { t } = useTranslation()

  const financeCompanyListHeaderCell = [

    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: t("Name"),
      isSort: true,
    },
    {
      id: "email",
      numeric: false,
      disablePadding: true,
      label: t("email"),
      isSort: true,
      isBold: true,
    },
    {
      id: "contact_name",
      numeric: false,
      disablePadding: false,
      label: t("contact_name"),
      isSort: true,
    },
    {
      id: "account",
      numeric: false,
      disablePadding: false,
      label: t("account"),
      isSort: true,
    },
    {
      id: "website",
      numeric: false,
      disablePadding: false,
      label: t("website"),
      isSort: false,
    },
    {
      id: "action",
      numeric: false,
      disablePadding: false,
      label: t("Action"),
      isSort: false,
    },

  ]



  let [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  let [order, setOrder] = useState<"DESC" | "ASC">("DESC");
  let [orderBy, setOrderBy] = useState("created_at");
  const [count, setCount] = useState<any>();
  const [rows, setRows] = useState<any>();
  const [initailLoad, setInitailLoad] = useState(true);
  const [isLoading, setIsLoading] = useState<any>(false);
  const navigate = useNavigate()

  const dispatch = useAppDispatch();

  const handleCreateReference = () => {
    dispatch(setReferenceID(null))
    navigate(`create`)
  }

  const handleAction = (id: string, action: string) => {
    if (action === "edit") {
      navigate(`/settings/reference/edit/${id}`)
      dispatch(setReferenceID(id))
    } else if (action === "delete") {
      handleDelete(id)
    }
  }

  const handleDelete = (id: string) => {
    deleteAlert({
      title: t("Delete"),
      message: t("Are You sure?"),
      confirmButtonText: t("Yes"),
      onConfirmed: deleteAction,
      data: id,
    });
  };


  const deleteAction = (id: string) => {
    commonService
      .deleteService(`/reference/${id}`)
      .then((res: any) => {
        Success(res?.data?.message);
        getTableData();
      })
      .catch((err) => {

      });
  };

  useEffect(() => {
    getTableData()
  }, [])

  const getTableData = () => {
    commonService.getServiceWithParams(`reference`, {})
      .then((res: any) => {
        const referernceData = res?.data?.data?.results.map((val: any) => {
          return {
            id: val.id,
            name: val.reference_name,
            email: val.email,
            contact_name: val.agency_name,
            account: val.agency_code,
            website: val.license_number,
          }
        })
        setRows(referernceData)
      }).catch((err: any) => { }
      )
  }

  return (
    <>
      <MainContainerWrapper
        headingText='Reference List'
        isCreateButton
        isBackButton={true}
        buttonText={t("Add Reference")}
        buttonIcon={<span>&#43;</span>}
        handleClick={handleCreateReference}
      >

        <EnhancedTable
          headCells={financeCompanyListHeaderCell}
          rows={rows}
          rowsPerPage={rowsPerPage}
          page={page}
          total={count}
          isEdit={true}
          actionFunction={handleAction}
          isDelete={true}
        />
      </MainContainerWrapper>
    </>
  )
}

export default RefernceList

