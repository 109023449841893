import { debounce } from '@mui/material';
import FeeMasterViewModel from 'component/helper-modals/FeeMasterView.model';
import EnhancedTable from 'component/tables/selectableTable';
import { commonService } from 'forms/helpers/common.service';
import { errorMessage } from 'forms/helpers/function.helper';
import { Failed } from 'forms/helpers/toast.helper';
import MainContainerWrapper from 'layout/wrappers/MainContainerWrapper';
import React, { useEffect, useState, useTransition } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom';
import { string } from 'yup';

function FeeList() {

    const { t } = useTranslation()

    const financeCompanyListHeaderCell = [

        {
            id: "name",
            numeric: false,
            disablePadding: false,
            label: t("Name of Fee"),
            isSort: false,
        },
        {
            id: "recurring_fee",
            numeric: false,
            disablePadding: false,
            label: t("recurring_fee"),
        },
        {
            id: "agency_fee",
            numeric: false,
            disablePadding: false,
            label: t("agency_fee"),
            isSort: false,
        },
        {
            id: "coverage_types",
            numeric: false,
            disablePadding: false,
            label: t("Coverage Types"),
            isSort: false,
        },
        {
            id: "action",
            numeric: false,
            disablePadding: false,
            label: t("Action"),
            isSort: false,
        },

    ]

    let [search, setSearch] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    let [order, setOrder] = useState<"DESC" | "ASC">("DESC");
    let [orderBy, setOrderBy] = useState("created_at");
    const [count, setCount] = useState<any>();
    const [rows, setRows] = useState<any>();
    const [initailLoad, setInitailLoad] = useState(true);
    const [isLoading, setIsLoading] = useState<any>(false);
    const [viewFee, setViewFee] = useState<boolean>(false)
    const [feeMasterId, setFeeMasterId] = useState<string>("")
    const navigate = useNavigate();

    const title = `${t("Fee List")}`

    useEffect(() => {
        getTableData()
    }, [rowsPerPage, page, orderBy, order])



    const searchHandler = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        search = e.target.value;
        setSearch(search);
    };

    useEffect(() => {
        const delay = 500; // Debounce delay in milliseconds
        const performSearch = (text: string) => {
            if (initailLoad) return;
            if (page == 0) getTableData();
            else {
                setPage(0);
            }
        };

        const debouncedSearch = debounce(performSearch, delay);
        const timeoutId = setTimeout(() => debouncedSearch(search), delay);
        return () => {
            clearTimeout(timeoutId);
        };
    }, [search]);


    const getTableData = () => {
        commonService
            .getServiceWithParams(`fee/get-all-fee`,
                {
                    per_page: rowsPerPage,
                    page: page + 1,
                    search: search ?? "",
                    sort_order: order,
                    sort_by: orderBy,

                }).then((res: any) => {
                    const tableData = res?.data?.data?.results.map((i: any) => {
                        const feeCoverageTypes = i?.feeCoverageTypes?.map((type: any) => type.policy_type?.values) || [];

                        return {
                            id: i?.id,
                            name: i?.name,
                            type: i?.type,
                            recurring_fee: i?.recurring_fee === true ? "True" : "False",
                            agency_fee: i?.agency_fee === true ? "True" : "False",
                            coverage_types: 
                            // i?.feeCoverageTypes?.map((type:any) => type.policy_type?.values).join(", ") || "All Coverages Type",
                            feeCoverageTypes.length > 2
                            ? feeCoverageTypes.slice(0, 3).join(", ") + ", ..."
                            : feeCoverageTypes.join(", ") || "All Coverages Type",
    

                        };
                    })
                    setRows(tableData)
                    setCount(res?.data.data?.pagination?.total);
                    setIsLoading(false);
                    setInitailLoad(false);
                }).catch((err: any) => {
                    Failed(
                        errorMessage(err, "Something went wrong, please try again later")
                    )
                })
    }

    const handleCreateFee = () => {
        navigate(`create`)
    }

        const handleAction = (id: string, action: string) => {
        if (action === "edit") {
            navigate(`/settings/fee/edit/${id}`)
        } else if (action === "view") {
            // setViewFee(true)
            // setFeeMasterId(id)
            navigate(`/settings/fee/view/${id}`)
        }
    }

    return (
        <>
            {viewFee && <FeeMasterViewModel
                open={viewFee}
                handleClose={() => setViewFee(false)}
                feeMasterId={feeMasterId}
            />}
            <MainContainerWrapper
                headingText={title}
                isCreateButton
                isBackButton={true}
                buttonText={t("Create")}
                buttonIcon={<span>&#43;</span>}
                handleClick={handleCreateFee}
                isSearch={true}
                search={search}
                handleSearch={searchHandler}
                searchPlaceHolder={t("Search")}
                handleSearchClear={() => setSearch("")}

            >
                <EnhancedTable
                    headCells={financeCompanyListHeaderCell}
                    rows={rows}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    total={count}
                    isView={true}
                    isEdit={true}
                    actionFunction={handleAction}
                />

            </MainContainerWrapper>
        </>
    )
}

export default FeeList