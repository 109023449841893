import { Box, Grid } from '@mui/material'
import FormLayout from 'component/form'
import { commonService } from 'forms/helpers/common.service'
import { Failed, Success } from 'forms/helpers/toast.helper'
import { InputField, PhoneField, SelectField } from 'forms/hoc/formfield'
import useForm from 'forms/hooks/useForm'
import { createReferenceContactValidation, createReferenceContactValidationSchema } from 'forms/hooks/validateRules'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppSelector } from 'store/store'

function ReferenceContact({
    handleNextTab,
    isReferenceEditType,
    editReferenceContactId,
}: ReferenceContactType) {

    const initialValues = {
        contact_name: null,
        phone: null,
        phone_type: null,
        title: null,
    }

    const { referenceID } = useAppSelector((state) => state.reference)


    console.log("referrrrrrrrrrrrrr", referenceID);

    const [phonetype, setPhoneType] = useState<any>();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();





    useEffect(() => {

        getMaster()

        if (isReferenceEditType === true) {
            getReferenceContactData()
        }
    }, [])


    const getReferenceContactData = () => {
        commonService
            .getServices(`reference-contact/${referenceID}/contact/${editReferenceContactId}`)
            .then((res: any) => {
                console.log("asasa", res?.data?.data?.phone_type);

                const getContactData = {
                    contact_name: res?.data?.data?.contact_name,
                    phone: res?.data?.data?.phone,
                    phone_type: res?.data?.data?.phone_type?.id,
                    title: res?.data?.data?.title,
                }
                setPatchValue(getContactData)
            }).catch((err: any) => {
                Failed("Something went wrong, please try again later");
            })

    }

    function getMaster() {
        let params = new URLSearchParams();
        params.append("types", `Phone Type`);


        commonService
            .getServices(`/master/get-all-master/types?${params.toString()}`)
            .then((res) => {
                console.log(res?.data?.data);

                const PhoneType = res?.data?.data["Phone Type"];
                if (PhoneType) {
                    console.log("PhoneType");
                    setPhoneType(PhoneType);
                }


            })
            .catch((err) => {
                console.log("err", err);
            });
    }




    const createReferanceContactT = createReferenceContactValidationSchema(t)

    const formSubmit = () => {


        const api: string = isReferenceEditType ? `reference-contact/${referenceID}/contact/${editReferenceContactId}` : `reference-contact/${referenceID}`

        const Payload: any = isReferenceEditType ? { ...values } : { ...values }

        const Method = isReferenceEditType ? "patch" : "post"

        commonService
            .postPutService(api, Payload, Method)
            .then((res: any) => {
                Success(res?.data?.message);
                handleNextTab();
            })
            .catch((err: any) => {
                setLoading(false);
                Failed("Something went wrong, please try again later");
            })
    }

    const {
        errors,
        values,
        handleChange,
        handleSubmit,
        setPatchValue,
        setUpdateValue,
        setUpdateFormValue,
    } = useForm(initialValues, formSubmit, createReferenceContactValidation, createReferanceContactT);


    return (
        <>
            <Box sx={{ p: 3, height: "auto", width: "100%" }}>
                <FormLayout
                    handleSubmit={handleSubmit}
                    isSaveButton>
                    <>
                        <Grid container columnSpacing={2}>
                            <Grid item xs={12} lg={4}>
                                <InputField
                                    fieldName={t("contact_name")}
                                    values={values?.contact_name || ""}
                                    name="contact_name"
                                    errors={errors?.contact_name}
                                    handleChange={handleChange}
                                    id="contact_name"
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <PhoneField
                                    fieldName={t("phone")}
                                    value={values?.phone || ""}
                                    name="phone"
                                    errors={errors?.phone}
                                    handleChange={handleChange}
                                    id="phone"
                                    isRequired={false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <SelectField
                                    fieldName={t("phone_type")}
                                    values={values?.phone_type || ""}
                                    name="phone_type"
                                    errors={errors?.phone_type}
                                    handleChange={handleChange}
                                    options={phonetype || []}
                                    id="phone_type"
                                    isRequired={false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <InputField
                                    fieldName={t("title")}
                                    values={values?.title || ""}
                                    name="title"
                                    errors={errors?.title}
                                    handleChange={handleChange}
                                    id="title"
                                />
                            </Grid>
                        </Grid>
                    </>
                </FormLayout>

            </Box>
        </>
    )
}

export default ReferenceContact


type ReferenceContactType = {
    handleNextTab: any,
    isReferenceEditType?: any,
    editReferenceContactId: string,
    
}

function setLoading(arg0: boolean) {
    throw new Error('Function not implemented.')
}
