import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material'
import { getDefaultReferenceDate } from '@mui/x-date-pickers/internals'
import FormLayout from 'component/form'
import Loading from 'container/loading'
import dayjs from 'dayjs'
import { commonService } from 'forms/helpers/common.service'
import { errorMessage } from 'forms/helpers/function.helper'
import { Failed, Success } from 'forms/helpers/toast.helper'
import { InputDatePicker, InputField, SelectField } from 'forms/hoc/formfield'
import useForm from 'forms/hooks/useForm'
import { createAgencyCommissionValidation, createAgencyCommissionValidationSchema } from 'forms/hooks/validateRules'
import MainContainerWrapper from 'layout/wrappers/MainContainerWrapper'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

function AgencyCommissionCreate() {

    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [state, setState] = useState<any>();
    const [reference, setReference] = useState<any>();
    const { id } = useParams();
    const { t } = useTranslation()
    const [wholeSaler, setWholeSaler] = useState<any>()
    const [policyType, setPolicyType] = useState<any>()
    const [InsuranceCompany, setInsuranceCompany] = useState<any>()
    const [agency_commission, setAgencyCommission] = useState<boolean>(false)
    const [carrier_gets_full_premium, setCarrierGetsFullPremium] = useState<boolean>(false);
    const [carrier_gets_net_premium, setCarrierGetsNetPremium] = useState<boolean>(false);
    const createAgencyCommissionT = createAgencyCommissionValidationSchema(t)


    useEffect(() => {

        getState()
        getMaster()
        getWholeSaler()
        getInsuranceData()
        getReferenceData()
        if (id) {
            getAgencyCommissionData()
        }
    }, [])


    const getState = () => {
        commonService
            .getServices(`/state`)
            .then((res: any) => {
                setState(res?.data?.data);
            })
            .catch((err) => { });
    };

    const getInsuranceData = () => {
        commonService.getServices(`insurance-company`)
            .then((res: any) => {
                setInsuranceCompany(res?.data?.data)
            }).catch((err: any) => { })
    }
    const getAgencyCommissionData = () => {
        commonService.getServices(`/commission-settings/${id}`).
            then((res: any) => {
                const data = res?.data?.data
                const patchData = {
                    ...data,
                    insuranceCompany: data?.insuranceCompany?.id,
                    state: data?.state?.id,
                    policy_type: data?.policy_type?.id,
                    whole_saler: data?.whole_saler?.id,
                    sub_agent: data?.sub_agent?.id,
                    starting_effective_date: dayjs(data?.starting_effective_date),
                }
                setPatchValue(patchData)
                if (data?.agency_commission) {
                    setAgencyCommission(true)
                }
                if (data?.carrier_gets_full_premium) {
                    setCarrierGetsFullPremium(true)
                }
                if (data?.carrier_gets_net_premium) {
                    setCarrierGetsNetPremium(true)
                }
            })
            .catch((err: any) => { })
    }

    const getMaster = async () => {
        setLoading(true);
        try {
            let params = new URLSearchParams();
            params.append("types", "Bank");
            const masterData: any = await commonService.getServices(
                `/master?${params.toString()}`
            );
            if (!!masterData?.data) {
                const data = masterData?.data?.data["Bank"];
                setPolicyType(data);
            }
            console.log("Loading policy", policyType);
        } catch (err) {
        } finally {
            setLoading(false);
        }
    }

    const getReferenceData = () => {
        commonService.getServices(`reference`)
            .then((res: any) => {
                const referenceData = res?.data?.data?.results.map((e: any) => {
                    return {
                        name: e?.reference_name,
                        value: e?.id
                    }
                })
                setReference(referenceData)
            }).catch((err: any) => { })
    }



    const getWholeSaler = () => {
        commonService.getServices(`/whole-salers`).
            then((res: any) => {
                const wholeSalers = res?.data?.data?.results.map((e: any) => {
                    return {
                        name: e?.name,
                        value: e?.id
                    }
                })
                setWholeSaler(wholeSalers);
            }).catch((err: any) => { })
    }

    const formSubmit = () => {
        const method = id ? "patch" : "post"
        const api = id ? `/commission-settings/${id}` : `commission-settings`

        const bodyData:any =  {
            ...values,
            agency_commission: agency_commission,
            carrier_gets_full_premium: carrier_gets_full_premium,
            carrier_gets_net_premium: carrier_gets_net_premium,
        } 
        // console.log(bodyData,"bodyData")
        if(id) {
            delete bodyData?.created_by
            delete bodyData?.created_at
            delete bodyData?.deletedAt
            delete bodyData?.updated_at
        }
        commonService
            .postPutService(api, bodyData, method)
            .then((res: any) => {
                Success(res?.data?.message);
                navigate(-1)

            }).catch((err: any) => {
                Failed(
                    errorMessage(err, err?.error)
                );
            })
    }


    const {
        errors,
        values,
        handleChange,
        handleSubmit,
        setPatchValue,
        setUpdateFormValue,
    } = useForm({}, formSubmit, createAgencyCommissionValidation, createAgencyCommissionT);

    const handlesetAgencyCommissionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        setAgencyCommission(checked)
    }
    const handlePCarrierGetsFullPremium = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        setCarrierGetsFullPremium(checked)
    }
    const handlearrierGetsNetPremium = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        setCarrierGetsNetPremium(checked)
    }
    const handleBack = () => {
        navigate(-1)
    }


    return (
        <MainContainerWrapper
            headingText={!id ? t('Agency Commission Create') : t('Agency Commission Edit')}
            isBackButton={true}
        >
            <>
                {loading && <Loading />}
                <Box sx={{ p: 3, height: "80vh", width: "100%" }}>
                    <FormLayout
                        handleSubmit={handleSubmit}
                        isBackButton
                        backButtonClick={handleBack}
                        backButtonText={t("Cancel")}
                        isSaveButton
                    >
                        <Grid container columnSpacing={2}>
                            <Grid item xs={12} lg={4}>
                                <SelectField
                                    fieldName={t("insuranceCompany")}
                                    values={values?.insuranceCompany || null}
                                    name="insuranceCompany"
                                    errors={errors?.insuranceCompany}
                                    handleChange={handleChange}
                                    options={InsuranceCompany || []}
                                    id="insuranceCompany"
                                    isRequired={false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <SelectField
                                    fieldName={t("State")}
                                    values={values?.state || null}
                                    name="state"
                                    errors={errors?.state}
                                    handleChange={handleChange}
                                    options={state || []}
                                    id="state"
                                    isRequired={false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <SelectField
                                    fieldName={t("policy_type")}
                                    values={values?.policy_type || ""}
                                    name="policy_type"
                                    errors={errors?.policy_type}
                                    handleChange={handleChange}
                                    options={policyType || []}
                                    id="policy_type"
                                    isRequired={false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <SelectField
                                    fieldName={t("whole_saler")}
                                    values={values?.whole_saler || ""}
                                    name="whole_saler"
                                    errors={errors?.whole_saler}
                                    handleChange={handleChange}
                                    options={wholeSaler || []}
                                    id="whole_saler"
                                    isRequired={false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <SelectField
                                    fieldName={t("sub_agent")}
                                    values={values?.sub_agent || ""}
                                    name="sub_agent"
                                    errors={errors?.sub_agent}
                                    handleChange={handleChange}
                                    options={reference || []}
                                    id="sub_agent"
                                    isRequired={false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <InputDatePicker
                                    fieldName={t("starting_effective_date")}
                                    values={values?.starting_effective_date || null}
                                    name="starting_effective_date"
                                    errors={errors?.starting_effective_date}
                                    handleChange={(e: any) =>
                                        setUpdateFormValue(
                                            "starting_effective_date",
                                            dayjs(e).format("YYYY-MM-DD")
                                        )
                                    }
                                    isRequired={false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <InputField
                                    fieldName={t("commission_due_for_new_policies")}
                                    values={values?.commission_due_for_new_policies || ""}
                                    name="commission_due_for_new_policies"
                                    errors={errors?.commission_due_for_new_policies}
                                    handleChange={handleChange}
                                    id="commission_due_for_new_policies"
                                    isRequired={false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <InputField
                                    fieldName={t("minimum_commission_due_for_new_policies")}
                                    values={values?.minimum_commission_due_for_new_policies || ""}
                                    name="minimum_commission_due_for_new_policies"
                                    errors={errors?.minimum_commission_due_for_new_policies}
                                    handleChange={handleChange}
                                    id="minimum_commission_due_for_new_policies"
                                    isRequired={false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <InputField
                                    fieldName={t("commission_due_for_renew_policies")}
                                    values={values?.commission_due_for_renew_policies || ""}
                                    name="commission_due_for_renew_policies"
                                    errors={errors?.commission_due_for_renew_policies}
                                    handleChange={handleChange}
                                    id="commission_due_for_renew_policies"
                                    isRequired={false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <InputField
                                    fieldName={t("minimum_commission_due_for_renew_policies")}
                                    values={values?.minimum_commission_due_for_renew_policies || ""}
                                    name="minimum_commission_due_for_renew_policies"
                                    errors={errors?.minimum_commission_due_for_renew_policies}
                                    handleChange={handleChange}
                                    id="minimum_commission_due_for_renew_policies"
                                    isRequired={false}
                                />
                            </Grid>

                            <Box sx={{ mt: 5.2, ml: 2, display: "flex" }}>
                                <Box>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={agency_commission}
                                                onChange={handlesetAgencyCommissionChange}
                                            />
                                        }
                                        label={t("Agency Commmission")}
                                    />
                                </Box>
                                <Box>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={carrier_gets_full_premium}
                                                onChange={handlePCarrierGetsFullPremium}
                                            />
                                        }
                                        label={t("Carrier gets full premium")}
                                    />
                                </Box>
                                <Box>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={carrier_gets_net_premium}
                                                onChange={handlearrierGetsNetPremium}
                                            />
                                        }
                                        label={t("carrier gets net premium")}
                                    />
                                </Box>

                            </Box>

                        </Grid>
                    </FormLayout>
                </Box>
            </>
        </MainContainerWrapper>
    )
}

export default AgencyCommissionCreate

function setPatchValue(data: any) {
    throw new Error('Function not implemented.')
}
