import EnhancedTable from "component/tables/selectableTable";
import { commonService } from "forms/helpers/common.service";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed, Success } from "forms/helpers/toast.helper";
import { debounce } from "helpers/debounce";
import MainContainerWrapper from "layout/wrappers/MainContainerWrapper";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "ui-component/Loader";
import { customerHeadCell, customerPolicyHeadCell } from "utils/table.utils";
import { saveAs } from "file-saver";
import axios from "axios";
import SweetAlertComponent from "component/HOC/Alert";
import deleteAlert from "component/HOC/deleteAlert";
import ImportModal from "component/helper-modals/import.modal";
import CustomerImport from "component/helper-modals/CustomerImport";
import TemplateModel from "component/helper-modals/Template.model";
import CustomerPolicyLocation from "./customer_policy";
import CustomerPolicyImport from "component/helper-modals/CustomerPolicyImport.model";
import { useTranslation } from "react-i18next";
import CustomerPolicyAdd from "./addCustomer_policy";




const CustomerPolicy = () => {

  const { t } = useTranslation();

  const customerPolicyHeadCell = [
    {
      id: "first_namee",
      numeric: false,
      disablePadding: true,
      label: t("First Name"),
      isSort: true,
      isBold: true,
    },
    {
      id: "last_namee",
      numeric: false,
      disablePadding: false,
      label: t("Last Name"),
      isSort: true,
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: t("Email"),
      isSort: false,
    },
    {
      id: "customer_type",
      numeric: false,
      disablePadding: false,
      label: t("Customer Type"),
      isSort: false,
    },
    // {
    //   id: "who_has_to_pay_the_transaction_fee",
    //   numeric: false,
    //   disablePadding: false,
    //   label: t("Who has to pay the Transaction Fee"),
    //   isSort: false,
    // },
    {
      id: "action",
      numeric: false,
      disablePadding: false,
      label: t("Action"),
      isSort: false,
    },
  ];

  let [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  let [order, setOrder] = useState<"DESC" | "ASC">("DESC");
  let [orderBy, setOrderBy] = useState("created_at");
  const [count, setCount] = useState<any>();
  const [rows, setRows] = useState<any>();
  const [isLoading, setIsLoading] = useState<any>(false);
  const [initailLoad, setInitailLoad] = useState(true);
  const [isImport, setIsImport] = useState(false);
  const [templateOpen, setTemplateOpen] = useState(false);
  const [importCustomerData, SetImportCustomerData] = useState<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isadd, setIsadd] = useState<boolean>(false);
  const [isEditId, setIsEditId] = useState<string>("")
  const [actionEdit, setActionEdit] = useState<boolean>(false);
  const [filter, setFilter] = useState<any>(null);
  const [typeList, setTypeList] = useState([]);
  const navigate = useNavigate();

  const handleAction = (id: string, action: string) => {
    if (action === "view") {
      navigate(`/customer-policy/view/${id}`);
    } else if (action === "edit") {
      setIsEditId(`${id}`)
      HandleEditCustomer(id)
      // setActionEdit(true)
      // setIsadd(true)
    }

    else if (action === "delete") {
      handleDeleteDriver(id);
    }
  };

  const handleDeleteDriver = (id: string) => {
    deleteAlert({
      title: t("Delete"),
      message: t("Are You sure?"),
      confirmButtonText: t("Yes"),
      onConfirmed: deleteAction,
      data: id,
    });
  };

  const HandleEditCustomer = (id: string) => {
    navigate(`/customer-policy/edit/${id}`)
  }

  const exportPdf = async (id: string) => {
    try {
      setIsLoading(true);
      const pdf = await axios({
        method: "get",
        url: `${process.env.REACT_APP_END_URL}/excel-policy/get-receipt/${id}`,
        // params: { customer: id },
        responseType: "blob",
      });
      saveAs(pdf?.data, `Receipt-${Date.now()}.pdf`);
      setIsLoading(false);
    } catch (err) {
      console.log("err", err);
      Failed(errorMessage(err, "Something went wrong, Please try again later"));
      setIsLoading(false);
    }
  };

  const deleteAction = (id: string) => {
    commonService
      .deleteService(`/excel-policy/${id}`)
      .then((res: any) => {
        Success(res.data.message);
        getTableData()
      })
      .catch((err) => {
        SweetAlertComponent({
          message: errorMessage(err, "Oops! Something went wrong!"),
          title: "Warning",
          onSuccess: () => { },
        });
      });
  };

  const handleChangeList = () => {
    if (page === 0) getTableData();
    else setPage(0);
  };

  useEffect(() => {
    getTableData();
  }, [rowsPerPage, page, orderBy, order, filter]);


  const getTableData = () => {
    setIsLoading(true);
    // const filterData = filter ? { customer_status: [filter?.value] } : {};
    commonService
      .postServiceParams(`/excel-policy/get-all-customer`, {
        filter: ""
      }, {
        per_page: rowsPerPage,
        page: page + 1,
        search: search ?? "",
        sort_order: order,
        sort_by: orderBy,
        // ...filterData,
      })
      .then((res) => {
        console.log("ssss", res?.data?.data?.results[0]?.broker_fee);
        const tableDetails = res?.data?.data?.results.map((item: any) => {
          return {
            id: item?.id,
            first_namee: item?.contactInfos[0]?.first_name,
            last_namee: item?.contactInfos[0]?.last_name,
            email: item?.contactInfos[0]?.emails[0]?.email,
            customer_type: item?.customer_type?.values,

          };
        });
        console.log(tableDetails);
        setCount(res?.data.data?.pagination?.total);
        setRows(tableDetails);
        setIsLoading(false);
        setInitailLoad(false);
      })
      .catch((err) => {
        Failed(
          errorMessage(err, "Something went wrong, please try again later")
        );
      });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === "ASC";
    order = isAsc ? "DESC" : "ASC";
    setOrder(order);
    orderBy = property;
    setOrderBy(orderBy);
  };

  const searchHandler = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    search = e.target.value;
    setSearch(search);
  };

  useEffect(() => {
    const delay = 500; // Debounce delay in milliseconds
    const performSearch = (text: string) => {
      // Perform your search logic here
      if (initailLoad) return;
      if (page == 0) getTableData();
      else {
        setPage(0);
      }
    };
    // if (search.length >= 3 || !search) {
    const debouncedSearch = debounce(performSearch, delay);
    const timeoutId = setTimeout(() => debouncedSearch(search), delay);
    return () => {
      clearTimeout(timeoutId);
    };
    // }
  }, [search]);

  const handleAddPolicyFinished = () => {
    // Triggered after the policy creation process is finished
    // setIsadd(false);
    handleChangeList(); // Refresh table data
  };

  const handleImport = () => {
    setIsImport(!isImport);
  };

  const handleTemplateOpen = () => {
    setTemplateOpen(!templateOpen);
  };


  const handleFilterChange = (newFilter: any) => {
    setFilter(newFilter);
    setPage(0);
  };

  const handlAddPolicyModel = () => {
    setIsadd(true);
    setIsEditId("")
    setActionEdit(false);
  }

  return (
    <>
      {isLoading && <Loader />}
      {/* {isadd && (
        <CustomerPolicyAdd
          handleformSubmit={handleAddPolicyFinished}
          isEdit={actionEdit}
          isEditId={isEditId}
        />
      )} */}
      {isImport && (
        <CustomerPolicyImport
          open={isImport}
          handleClose={handleImport}
          handleFormSubmit={handleChangeList}
        // apiPath="/customer/import-customers"
        />
      )}


      {/* {templateOpen && (
        <TemplateModel open={templateOpen} handleClose={handleTemplateOpen} />
      )} */}


      <MainContainerWrapper
        headingText={t("Customers")}
        isSearch={true}
        search={search}
        searchPlaceHolder={t("Search")}
        handleSearch={searchHandler}
        handleSearchClear={() => setSearch("")}
        isCreateButton={true}
        buttonText={t("Add Customer")}
        buttonIcon={<span>&#43;</span>}
        handleClick={() => navigate('create')}
        isSecondButton={true}
        secondButtonText={t("Import From Excel")}
        handleSecondClick={handleImport}
        isCustomerTemplate={true}
        templateButtonText={t("Download Template")}
        istemplateLink="/templates/Customer Policies-template.xlsx"
        setUpdateValue={handleFilterChange}
        filterPlaceHolder="Filter By Location"
      >
        <EnhancedTable
          headCells={customerPolicyHeadCell}
          rows={rows}
          rowsPerPage={rowsPerPage}
          maxHeightValue={"70vh"}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleRequestSort={handleRequestSort}
          isSelectable={false}
          order="asc"
          orderBy="name"
          isView={true}
          isEdit={true}
          isDelete={true}
          total={count}
          isExport={false}
          actionFunction={handleAction}
          routingLink={true}
        />
      </MainContainerWrapper>
    </>
  );
};

export default CustomerPolicy;
