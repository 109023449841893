import axios from 'axios'
import VoidReasonModel from 'component/helper-modals/VoidReason.model'
import SweetAlertComponent from 'component/HOC/Alert'
import deleteAlert from 'component/HOC/deleteAlert'
import EnhancedTable from 'component/tables/selectableTable'
import saveAs from 'file-saver'
import { commonService } from 'forms/helpers/common.service'
import { errorMessage } from 'forms/helpers/function.helper'
import { Failed, Success } from 'forms/helpers/toast.helper'
import MainContainerWrapper from 'layout/wrappers/MainContainerWrapper'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { idText } from 'typescript'
import Loader from 'ui-component/Loader'

function QuickReciptList() {

    const { t } = useTranslation()

    const headerCellsQuickReceipt = [
        {
            id: 'type_void',
            numeric: true,
            disablePadding: false,
            label: 'Type',
            isSort: false,
        },
        {
            id: 'first_name_Void',
            numeric: false,
            disablePadding: false,
            label: t("first_name"),
            isSort: false,
        },
        {
            id: 'date_received',
            numeric: false,
            disablePadding: false,
            label: 'date_received',
            isSort: false,
        },
        {
            id: 'receipt_number',
            numeric: false,
            disablePadding: false,
            label: 'receipt_number',
            isSort: false,
        },
        {
            id: 'total_paid',
            numeric: false,
            disablePadding: false,
            label: 'amount',
            isSort: false,
        },
        {
            id: 'action',
            numeric: false,
            disablePadding: false,
            label: 'Action',
            isSort: false,
        },
    ]

    let [search, setSearch] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    let [order, setOrder] = useState<"DESC" | "ASC">("DESC");
    let [orderBy, setOrderBy] = useState("created_at");
    const [count, setCount] = useState<any>();
    const [rows, setRows] = useState<any>([]);
    const [voidReceipt, setvoidReceipt] = useState<Boolean>(false)
    const [isLoading, setIsLoading] = useState<any>(false);
    const [receiptId, setReceiptId] = useState<string>("")
    const navigate = useNavigate()


    useEffect(() => {
        getReceiptData()
    }, [])

    const handleActionFunction = (id: string, action: string, is_void: boolean) => {
        if (action === "edit") {
            navigate(`/quickRecipt/edit/${id}`)
        } else if (action === "delete") {
            handleDeleteReceipt(id)
        } else if (action === "export") {
            exportPdf(id);
        } else if (action === "void") {
            setvoidReceipt(true);
            setReceiptId(id)
        } else if (action === "unvoid") {
            handleUnvoidReceipt(id)
        }
    }



    const exportPdf = async (id: string) => {
        try {
            setIsLoading(true)
            const pdf = await axios({
                method: "get",
                url: `${process.env.REACT_APP_END_URL}/quick-receipt/quick-receipt/export/${id}`,
                responseType: "blob",
            });
            saveAs(pdf?.data, `Quick-Receipt-${Date.now()}.pdf`);
            setIsLoading(false)
        } catch (err) {
            console.log("err", err);
            Failed(errorMessage(err, "Something went wrong, Please try again later"));
        }
    };

    const handleDeleteReceipt = (id: string) => {
        deleteAlert({
            title: t("Delete"),
            message: t("Are You sure?"),
            confirmButtonText: t("Yes"),
            onConfirmed: deleteAction,
            data: id,
        });
    }

    const deleteAction = (id: string) => {
        commonService
            .deleteService(`quick-receipt/${id}`)
            .then((res: any) => {
                Success(res.data.message);
                getReceiptData();
            })
            .catch((err) => {
                SweetAlertComponent({
                    message: errorMessage(err, "Oops! Something went wrong!"),
                    title: "Warning",
                    onSuccess: () => { },
                });
            });
    };

    const handleUnvoidReceipt = (id: string) => {
        deleteAlert({
            title: t("UnVoid Receipt"),
            message: t("Are You sure?"),
            confirmButtonText: t("Yes"),
            onConfirmed: UnVoidAction,
            data: id,
        });
    }

    const UnVoidAction = (id: string) => {
        commonService
            .postService(`/quick-receipt/void-unvoid/${id}`, {
                is_void: false
            })
            .then((res: any) => {
                Success(res.data.message);
                getReceiptData();
            })
            .catch((err) => {
                SweetAlertComponent({
                    message: errorMessage(err, "Oops! Something went wrong!"),
                    title: "Warning",
                    onSuccess: () => { },
                });
            });
    }
    const getReceiptData = () => {
        commonService
            .getServices(`/quick-receipt`)
            .then((res: any) => {
                const receiptData = res?.data?.data.map((i: any) => {
                    return {
                        id: i?.id,
                        is_void: i?.is_void === true ? true : false,
                        receipt_number: i?.receipt_number,
                        type_void: i?.client_type,
                        first_name_Void: i?.first_name,
                        date_received: i?.date_received,
                        total_paid: i?.total_paid
                    }
                })
                setRows(receiptData)
            })
            .catch((err: any) => { })
    }
    const handleCreateRecipt = () => {
        navigate(`create`)
    }

    const handleCloseReasonModel = () => {
        setvoidReceipt(false)
        getReceiptData()
    }

    return (

        <>
            {isLoading && <Loader />}

            {voidReceipt && <VoidReasonModel
                open={voidReceipt}
                handleClose={handleCloseReasonModel}
                receiptId={receiptId}
            />}
            <MainContainerWrapper
                headingText='Quick Recipt List'
                isCreateButton={true}
                isBackButton={true}
                buttonText={t("Add Recipt")}
                buttonIcon={<span>&#43;</span>}
                handleClick={handleCreateRecipt}
            >
                <EnhancedTable
                    headCells={headerCellsQuickReceipt}
                    rows={rows}
                    isEdit={true}
                    isDelete={true}
                    isExport={true}
                    isVoid={true}
                    actionFunction={handleActionFunction}
                />
            </MainContainerWrapper>
        </>
    )
}

export default QuickReciptList