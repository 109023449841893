import { Box, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useTheme } from '@mui/material'
import FormLayout from 'component/form'
import { commonService } from 'forms/helpers/common.service'
import { InputDatePicker, InputField, InputTimePicker, PhoneField, SelectField } from 'forms/hoc/formfield'
import useForm from 'forms/hooks/useForm'
import { createQuickReceiptValidation, createQuickReceiptValidationSchema } from 'forms/hooks/validateRules'
import MainContainerWrapper from 'layout/wrappers/MainContainerWrapper'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useNavigate, useParams } from 'react-router-dom'
import { Success } from 'forms/helpers/toast.helper'
import dayjs from 'dayjs'
import { log } from 'console'
import { dateTimeFormat } from 'forms/hooks/customHook'

function CreateReceipt() {

    const { t } = useTranslation()
    const theme = useTheme();
    const navigate = useNavigate()
    const { id } = useParams()


    const createQuickReceiptValidationT = createQuickReceiptValidationSchema(t)

    const [state, setState] = useState<any>();
    const [inputs, setInputs] = useState<any>([]);
    const [paymentMethod, setPaymentMethod] = useState<any>([]);
    const [descriptionList, setDescriptionList] = useState<any>()
    const [totalDue, setTotalDue] = useState<number>(0);
    const [totalPaid, setTotalPaid] = useState<number>(0)
    const [loading, setLoading] = useState(false);
    const [isCreateDisabled, setIsCreateDisabled] = useState<boolean>(true); // Track create button state


    const headers = [
        { name: "Description" },
        { amount: "Amount" },
        { action: "Action" }
    ];


    const paymentHeader = [
        { payment_method: "Payment Method" },
        { check: "Check #" },
        { amount: "Amount" },
        { action: "Action" }
    ]

    const paymentMethodList = [
        { name: 'cash', value: 'CASH' },
        { name: 'CERTIFIED CHECK', value: 'CERTIFIED CHECK' },
        { name: 'CHECK', value: 'CHECK' },
        { name: 'DISCOVER', value: 'DISCOVER' },
        { name: 'MASTERCARD', value: 'MASTERCARD' },
        { name: 'MONEY ORDER', value: 'MONEY ORDER' },
        { name: 'REFUND', value: 'REFUND' },
        { name: 'VISA', value: 'VISA' },

    ]
    const addInput = () => {
        setInputs([...inputs, { description: null, amount: null }]); // Add a new input object
    };

    const addpaymentMethod = () => {
        setPaymentMethod([...paymentMethod, { payment_method: null, check: null, amount: null }]);
    }

    // Update the value of a specific input
    const handleInputChange = (index: number, key: string, value: any) => {
        const updatedInputs: any = [...inputs];
        updatedInputs[index][key] = value; // Update the specific key (description/amount)
        setInputs(updatedInputs);
    };

    const handlePaymentMethodChange = (index: number, key: string, value: any) => {
        const updatedPaymentMethod: any = [...paymentMethod]
        updatedPaymentMethod[index][key] = value;
        setPaymentMethod(updatedPaymentMethod);
    }

    // Remove a specific payment method
    const removePaymentMethod = (index: any) => {
        const updatedPaymentMethod = paymentMethod.filter((_: any, i: any) => i !== index);
        setPaymentMethod(updatedPaymentMethod);
    }

    // Remove a specific input field
    const removeInput = (index: any) => {
        const updatedInputs = inputs.filter((_: any, i: any) => i !== index);
        setInputs(updatedInputs);
    };


    useEffect(() => {
        getState()
        getDescritptionList()
        if (id) {
            getReceiptOneData()
        }
    }, [])


    useEffect(() => {
        const calculateAmount = (dueArray: any[], paidArray: any[]) => {
            // Calculate total due
            const totalDueAmount = dueArray.reduce((sum, item) => sum + (item.amount || 0), 0);

            // Calculate total paid
            const totalPaidAmount = paidArray.reduce((sum, item) => sum + (item.amount || 0), 0);

            // Update state with the calculated totals
            console.log("Total due", totalDueAmount);

            setTotalDue(totalDueAmount);
            setTotalPaid(totalPaidAmount);
        };
        // Summing up amounts from `inputs` and `paymentMethod`
        calculateAmount(inputs, paymentMethod);

        const allValid = inputs.every((input: any) => input.description && input.amount);
        setIsCreateDisabled(!allValid); // Disable  button if any field is empty

    }, [inputs, paymentMethod]);


    const getReceiptOneData = () => {
        commonService
            .getServices(`/quick-receipt/${id}`)
            .then((res: any) => {
                const data = res?.data?.data
                const ReceiptData = {
                    client_type: data?.client_type,
                    company_name: data?.company_name ?? null,
                    first_name: data?.first_name ?? null,
                    last_name: data?.last_name ?? null,
                    address: data?.address ?? null,
                    city: data?.city ?? null,
                    state: data?.state?.id ?? null,
                    zip_code: data?.zip_code ?? null,
                    receipt_number: data?.receipt_number,
                    work_phone: data?.work_phone ?? null,
                    cell_phone: data?.cell_phone ?? null,
                    email: data?.email ?? null,
                }
                setPatchValue(ReceiptData)

                // set the Itemization Input Array
                const itemizationData = data?.itemizations.map((i: any) => {
                    return {
                        id: i?.id,
                        description: i?.description?.id ?? null,
                        amount: +i?.amount
                    }
                })
                setInputs(itemizationData)


                // set the Payment Method Input Array

                const paymentMethodData = data?.payments.map((i: any) => {
                    return {
                        id: i?.id,
                        payment_method: i?.payment_method ?? null,
                        check: i?.check ?? null,
                        amount: +i?.amount
                    }
                })
                setPaymentMethod(paymentMethodData)

                //set payment Amount Data
                setTotalDue(data?.total_due)
                setTotalPaid(data?.total_paid)
            })
            .catch((err: any) => {
                console.log("error", err)
            })


    }
    console.log("input", inputs);

    const getState = () => {
        commonService
            .getServices(`/state`)
            .then((res: any) => {
                setState(res?.data?.data);
            })
            .catch((err) => { });
    };


    const getDescritptionList = () => {
        commonService
            .getServices(`quick-receipt/reason/master`)
            .then((res: any) => {
                const result = res?.data?.data.map((i: any) => {
                    return {
                        name: i?.values,
                        value: i?.id
                    }
                })
                console.log("reassssssss", result);

                setDescriptionList(result);
                setLoading(false);
            }).catch((err: any) => {
                console.log("error", err)
            })
    }



    const formSubmit = () => {

        const api = !id ? `quick-receipt` : `quick-receipt/${id}`

        const method = id ? "patch" : "post"
        const bodyData = id ?
            {
                ...values,
                itemizations: inputs,
                payment_methods: paymentMethod,
                total_due: +totalDue,
                total_paid: +totalPaid,
            } :
            {
                ...values,
                itemizations: inputs,
                payment_methods: paymentMethod,
                total_due: +totalDue,
                total_paid: +totalPaid,
            }
        commonService
            .postPutService(api, bodyData, method)
            .then((res: any) => {
                Success(res?.data?.message)
                navigate(-1)
            }).catch((err: any) => { })
    }


    const {
        errors,
        values,
        handleChange,
        handleSubmit,
        setPatchValue,
        setUpdateValue,
        setUpdateFormValue
    } = useForm({}, formSubmit, createQuickReceiptValidation, createQuickReceiptValidationT);


    const handleBack = () => {
        navigate(-1)
    }


    console.log("errs", errors);


    return (
        <MainContainerWrapper
            headingText={!id ? `Create Receipt` : `Edit Receipt`}
            isBackButton={true}
        >
            {/* Your form components here */}
            <Box sx={{
                p: 3, height: "80vh", width: "100%",

            }}>
                <FormLayout
                    handleSubmit={handleSubmit}
                    isBackButton
                    backButtonClick={handleBack}
                    isSaveButton

                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={4}>
                            <InputDatePicker
                                fieldName={t("Date Received")}
                                values={values?.date_received || null}
                                name="date_received"
                                errors={errors?.date_received}
                                handleChange={(e) =>
                                    setUpdateFormValue(
                                        "date_received",
                                        dayjs(e).format("YYYY-MM-DD")
                                    )
                                }
                                id="date_received"
                                isRequired={false}
                            />

                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <InputTimePicker
                                fieldName={t("Time")}
                                values={values?.time_received || ""}
                                name="time_received"
                                errors={errors?.time_received}
                                //handleChange={}
                                handleChange={(e) => {
                                    setUpdateFormValue("time_received", dayjs(e))
                                    console.log(dateTimeFormat(e))
                                }
                                }
                                isRequired={false}

                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <InputField
                                fieldName={t("Receipt Number")}
                                values={values?.receipt_number || ""}
                                name="receipt_number"
                                errors={errors?.receipt_number}
                                handleChange={handleChange}
                                id="receipt_number"
                                isRequired={false}
                            />
                        </Grid>
                    </Grid>
                    <Box mt={1} py={2} pl={2} bgcolor={theme?.palette?.grey[200]}
                        sx={{ overflow: "hidden" }}
                    >
                        <Typography variant="h3" textAlign={"center"}>
                            {"Insured"}
                        </Typography>
                    </Box>
                    <Grid container columnSpacing={2}
                        sx={{ overflow: "hidden" }}
                    >
                        <Grid item xs={12} lg={4}>
                            <SelectField
                                fieldName={t("client_type")}
                                values={values?.client_type || ""}
                                name="client_type"
                                errors={errors?.client_type}
                                handleChange={handleChange}
                                options={[
                                    { name: "PERSONAL", value: "PERSONAL" },
                                    { name: "COMPANY", value: "COMPANY" },
                                ] || []}
                                id="client_type"
                                isRequired={true}
                            />
                        </Grid>
                        {values?.client_type === "PERSONAL" &&
                            <Grid item xs={12} lg={4}>
                                <InputField
                                    fieldName={t("first_name")}
                                    values={values?.first_name || ""}
                                    name="first_name"
                                    errors={errors?.first_name}
                                    handleChange={handleChange}
                                    id="first_name"
                                    isRequired={false}
                                />
                            </Grid>}
                        {values?.client_type === "PERSONAL" && <Grid item xs={12} lg={4}>
                            <InputField
                                fieldName={t("last_name")}
                                values={values?.last_name || ""}
                                name="last_name"
                                errors={errors?.last_name}
                                handleChange={handleChange}
                                id="last_name"
                                isRequired={false}
                            />
                        </Grid>}
                        {values?.client_type === "COMPANY" && <Grid item xs={12} lg={4}>
                            <InputField
                                fieldName={t("company_name")}
                                values={values?.company_name || ""}
                                name="company_name"
                                errors={errors?.company_name}
                                handleChange={handleChange}
                                id="company_name"
                                isRequired={false}
                            />
                        </Grid>}
                        <Grid item xs={12} lg={4}>
                            <InputField
                                fieldName={t("address")}
                                values={values?.address || ""}
                                name="address"
                                errors={errors?.address}
                                handleChange={handleChange}
                                id="address"
                                isRequired={false}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <InputField
                                fieldName={t("city")}
                                values={values?.city || ""}
                                name="city"
                                errors={errors?.city}
                                handleChange={handleChange}
                                id="city"
                                isRequired={false}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SelectField
                                fieldName={t("State")}
                                values={values?.state || null}
                                name="state"
                                errors={errors?.state}
                                handleChange={handleChange}
                                options={state || []}
                                id="state"
                                isRequired={false}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <InputField
                                fieldName={t("zip_code")}
                                values={values?.zip_code || ""}
                                name="zip_code"
                                errors={errors?.zip_code}
                                handleChange={handleChange}
                                id="zip_code"
                                isRequired={false}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <PhoneField
                                name="work_phone"
                                fieldName={t("work_phone")}
                                handleChange={handleChange}
                                errors={errors?.work_phone}
                                id="work_phone"
                                value={values?.work_phone}
                                isRequired={false}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <PhoneField
                                name="cell_phone"
                                fieldName={t("cell_phone")}
                                handleChange={handleChange}
                                errors={errors?.cell_phone}
                                id="cell_phone"
                                value={values?.cell_phone}
                                isRequired={false}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <InputField
                                fieldName={t("email")}
                                values={values?.email || ""}
                                name="email"
                                errors={errors?.email}
                                handleChange={handleChange}
                                id="email"
                                isRequired={false}
                            />
                        </Grid>
                    </Grid>
                    <Box mt={2} py={2} pl={2} bgcolor={theme?.palette?.grey[200]}>
                        <Typography variant="h3" textAlign={"center"}>
                            {"Itemization"}
                        </Typography>
                    </Box>
                    <Box sx={{ textAlign: "end", py: 2, mr: 1.3 }}>
                        <IconButton
                            color="primary"
                            onClick={addInput}
                            disabled={isCreateDisabled} // Button disabled state
                            sx={{
                                backgroundColor: "primary.light",
                                "&:hover": { backgroundColor: "primary.main", color: "#fff" },
                            }}
                        >
                            <AddCircleIcon fontSize="large" />
                        </IconButton>
                    </Box>


                    <Box sx={{ px: 2 }}>
                        <TableContainer
                            sx={{
                                border: '1px solid black', // Table border
                                backgroundColor: '#f9f9f9', // Table background color
                            }}
                        >
                            <Table
                                stickyHeader
                                aria-labelledby="tableTitle"
                                size="medium"
                            >
                                <TableHead>
                                    <TableRow>
                                        {headers.map((header, index) => (
                                            <TableCell
                                                key={index}
                                                align="center"
                                                sx={{
                                                    backgroundColor: "#34aadc"
                                                }}
                                            >
                                                <Typography variant="h4" fontWeight="bold">
                                                    {header.name || header.amount || header.action}
                                                </Typography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {inputs.map((input: any, index: any) => (
                                        <TableRow
                                            key={index}
                                            sx={{
                                                backgroundColor: index % 2 === 0 ? '#ffffff' : '#f5f5f5', // Alternate row background
                                            }}
                                        >
                                            {/* Description column with dropdown */}
                                            <TableCell
                                                align="center"
                                            >
                                                <FormControl sx={{ width: "140%" }}>
                                                    <Select
                                                        value={input.description}
                                                        onChange={(e) =>
                                                            handleInputChange(index, "description", e.target.value)
                                                        }
                                                    >
                                                        {descriptionList.map((i: any, idx: any) => (
                                                            <MenuItem key={idx} value={i?.value}>
                                                                {i?.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            {/* Amount column with input */}
                                            <TableCell
                                                align="center"
                                            >
                                                <TextField
                                                    value={input.amount}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        // Allow only numeric values
                                                        if (/^\d*$/.test(value)) {
                                                            handleInputChange(index, "amount", +value); // Update state if valid
                                                        }
                                                    }}
                                                    placeholder={`Amount ${index + 1}`}
                                                    variant="outlined"
                                                    type="text"
                                                    size="small"
                                                />
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                            >
                                                <IconButton
                                                    color="error"
                                                    onClick={() => removeInput(index)}
                                                >
                                                    <RemoveCircleIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>


                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
                        <Typography variant='h3' sx={{ mr: 3, mt: 1.2 }}>
                            {"Total Due"}
                        </Typography>
                        <Typography variant='h5' sx={{ border: "solid 1px black", mr: 2, p: 1.8, alignItems: "center" }}>
                            {totalDue}
                        </Typography>
                    </Box>
                    <Box mt={2} py={2} pl={2} bgcolor={theme?.palette?.grey[200]}>
                        <Typography variant="h3" textAlign={"center"}>
                            {"Payment Method"}
                        </Typography>
                    </Box>
                    <Box sx={{ textAlign: "end", py: 2, mr: 1.3 }}>
                        <IconButton
                            color="primary"
                            onClick={addpaymentMethod}
                            sx={{
                                backgroundColor: "primary.light",
                                "&:hover": { backgroundColor: "primary.main", color: "#fff" },
                            }}
                        >
                            <AddCircleIcon fontSize="large" />
                        </IconButton>
                    </Box>
                    <Box sx={{ px: 2 }}>
                        <TableContainer
                            sx={{
                                border: '1px solid black', // Table border
                                backgroundColor: '#f9f9f9', // Table background color
                            }}>
                            <Table
                                stickyHeader
                                aria-labelledby="tableTitle"
                                size="medium"
                            >
                                <TableHead>
                                    <TableRow>
                                        {paymentHeader.map((header, index) => (
                                            <TableCell key={index} align="center"
                                                sx={{
                                                    backgroundColor: "#34aadc"
                                                }}
                                            >
                                                <Typography variant="h4" fontWeight="bold">
                                                    {header.payment_method || header.check || header.amount || header.action}
                                                </Typography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {paymentMethod.map((input: any, index: any) => (
                                        <TableRow key={index}>
                                            {/* Payment Method Column */}
                                            <TableCell align="center">
                                                <FormControl sx={{ width: "100%" }}>
                                                    <Select
                                                        value={input?.payment_method}
                                                        onChange={(e) =>
                                                            handlePaymentMethodChange(index, "payment_method", e.target.value)
                                                        }
                                                    >
                                                        <MenuItem value="CASH">CASH</MenuItem>
                                                        {paymentMethodList.map((i: any, idx: any) => (
                                                            <MenuItem key={idx} value={i?.value}>
                                                                {i?.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>

                                            {/* Check Number Column */}
                                            <TableCell align="center">
                                                <TextField
                                                    value={input?.check}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (/^\d*$/.test(value)) {
                                                            handlePaymentMethodChange(index, "check", value);
                                                        }
                                                    }}
                                                    placeholder={`#Check ${index + 1}`}
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            </TableCell>

                                            {/* Amount Column */}
                                            <TableCell align="center">
                                                <TextField
                                                    value={input.amount}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (/^\d*$/.test(value)) {
                                                            handlePaymentMethodChange(index, "amount", +value);
                                                        }
                                                    }}
                                                    placeholder={`Amount ${index + 1}`}
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            </TableCell>

                                            {/* Action Column */}
                                            <TableCell align="center">
                                                <IconButton
                                                    color="error"
                                                    onClick={() => removePaymentMethod(index)}
                                                >
                                                    <RemoveCircleIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
                        <Typography variant='h3' sx={{ mr: 3, mt: 1.2 }}>
                            {"Total Paid"}
                        </Typography>
                        <Typography variant='h5' sx={{ border: "solid 1px black", mr: 2, p: 1.8, alignItems: "center" }}>
                            {totalPaid}
                        </Typography>
                    </Box>

                </FormLayout>
            </Box >
        </MainContainerWrapper >
    )
}

export default CreateReceipt
