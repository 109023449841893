

import { Box, Grid, Step, StepConnector, StepLabel, Stepper, useMediaQuery, useTheme } from '@mui/material';
import MainContainerWrapper from 'layout/wrappers/MainContainerWrapper';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import NewCustomer from './NewCustomer';
import Loader from 'ui-component/Loader';
import { useAppSelector } from 'store/store';
import { unstable_usePrompt, useNavigate } from 'react-router-dom';
import NewPolicyPayment from '../ExistingCustomer/NewPolicyPayment';

function NewCustomerIndex() {

    const [isLoading, setIsloading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);

    const { t, i18n } = useTranslation();
    const blockerData = useAppSelector((state) => state.blocker.blocker);
    const navigate = useNavigate();
    unstable_usePrompt({
        message: `Are you sure you want to leave? You haven't saved your changes yet.`,
        when: ({ currentLocation, nextLocation }) => {
            return (
                blockerData?.status &&
                currentLocation.pathname !== nextLocation.pathname
            );
        },
    });


    const theme = useTheme();
    const isLessThan900px = useMediaQuery(theme.breakpoints.down("lg")); // sm = 600px by default


    const makePayment = useAppSelector((state) => state?.makePayment?.makepayment)






    const steps = [
        t("Information"),
        t("Payment Information"),
    ]


    const handleNextSubmit = () => {
        setActiveStep((prev: any) => prev + 1);
    }


    const CustomConnector = (props: any) => (
        <StepConnector
            {...props}
            sx={{
                '& .MuiStepConnector-line': {
                    borderWidth: '4px',  // Change line thickness
                    margin: "0px -66px 0px -50px",
                },
                '&.MuiStep-horizontal': {
                    marginRight: "10px"
                }
            }}
        />
    );

    const handleBack = () => {
        navigate(`/quote`)
    }

    return (
        <Box
            sx={{ p: 5, pb: 11, bgcolor: "#EEF2F9" }}
        >
            <MainContainerWrapper
                headingText={t("Payment")}
                isBackButton={true}
                handleBack={handleBack}
            >
                {isLoading ? <Loader /> : (<Grid>
                    <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        width={"100%"}
                        height={"5rem"}
                    >
                        <Stepper
                            activeStep={activeStep}
                            sx={{
                                mt: 4,
                                width: isLessThan900px ? "90%" : "50%",

                                // overflowX: isLessThan900px ? "auto" : "visible",  
                            }}
                            connector={<CustomConnector />}  // Use the custom connector here
                        >
                            {steps.map((label, index) => {
                                const stepProps: { completed?: boolean } = {};
                                const labelProps: {
                                    optional?: React.ReactNode;
                                } = {};
                                return (
                                    <Step key={label} {...stepProps}
                                        sx={{

                                            // Applu margin-right only for first step
                                            ...(index === 0 && {
                                                marginRight: i18n?.language === 'es' ? "-12px" : "8px",
                                            }),
                                            // Apply margin-right only for the second step
                                            ...(index === 1 && {
                                                // marginRight: "-25px",
                                                marginRight: i18n?.language === 'es' ? "-26px" : "-18px",
                                                marginLeft: i18n?.language === 'es' ? "-1px" : "5px"
                                            }),
                                        }}
                                    >
                                        <StepLabel
                                            sx={{
                                                mt: 2,
                                                flexDirection: "column",
                                            }}
                                            {...labelProps}
                                        >
                                            {label}
                                        </StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Box>
                    <Grid item lg={12} sm={12} xs={12} height={`calc(100% - 5rem)`}>
                        {
                            activeStep === 0 && (
                                <NewCustomer
                                    handleFormSubmit={handleNextSubmit}
                                />
                            )
                        }


                        {
                            activeStep === 1 && (
                                <NewPolicyPayment
                                    
                                />
                            )
                        }
                    </Grid>
                </Grid>
                )}

            </MainContainerWrapper>
        </Box>
    )
}

export default NewCustomerIndex