import EnhancedTable from "component/tables/selectableTable";
import { commonService } from "forms/helpers/common.service";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed, Success } from "forms/helpers/toast.helper";
import { debounce } from "helpers/debounce";
import MainContainerWrapper from "layout/wrappers/MainContainerWrapper";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "ui-component/Loader";
import { customerHeadCell, customerPolicyHeadCell } from "utils/table.utils";
import { saveAs } from "file-saver";
import axios from "axios";
import SweetAlertComponent from "component/HOC/Alert";
import deleteAlert from "component/HOC/deleteAlert";
import ImportModal from "component/helper-modals/import.modal";
import CustomerImport from "component/helper-modals/CustomerImport";
import TemplateModel from "component/helper-modals/Template.model";
import CustomerPolicyImport from "component/helper-modals/CustomerPolicyImport.model";
import { useTranslation } from "react-i18next";
import CustomerPolicyLocation from "../customer_policy";
import CreatePoliciesForCustomer from "../createPoliciesForCustomer";
import CustomerPolicesView from "./CustomerPoliciesView";

const CustomerrPolicy = ({ customerId }: CustomerPolicyType) => {
  const { t } = useTranslation();

  const customerPolicyHeadCell = [
    {
      id: "policy_number_unlink",
      numeric: false,
      disablePadding: true,
      label: t("Policy Number"),
      isSort: true,
      isBold: true,
    },
    {
      id: "location",
      numeric: false,
      disablePadding: false,
      label: t("Location"),
      isSort: false,
    },
    {
      id: "action",
      numeric: false,
      disablePadding: false,
      label: t("Action"),
      isSort: false,
    },
  ];

  let [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  let [order, setOrder] = useState<"DESC" | "ASC">("DESC");
  let [orderBy, setOrderBy] = useState("created_at");
  const [count, setCount] = useState<any>();
  const [rows, setRows] = useState<any>();
  const [isLoading, setIsLoading] = useState<any>(false);
  const [initailLoad, setInitailLoad] = useState(true);
  const [isImport, setIsImport] = useState(false);
  const [templateOpen, setTemplateOpen] = useState(false);
  const [importCustomerData, SetImportCustomerData] = useState<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isadd, setIsadd] = useState<boolean>(false);

  const [actionEdit, setActionEdit] = useState<boolean>(false);
  const [filter, setFilter] = useState<any>(null);
  const [CustomerPoliciesList, setCustomerPoliciesList] =
    useState<boolean>(true);
  const [createCustomerPolicies, setCreateCustomerPolicies] =
    useState<boolean>(false);
  const [isEditCustomerPolicies, setIsEditCustomerPolicies] =
    useState<boolean>(false);
  const [isViewCustomerPolicies, setIsViewCustomerPolicies] =
    useState<boolean>(false);
  const [isEditId, setIsEditId] = useState<string>("");
  const navigate = useNavigate();

  const handleAction = (id: string, action: string) => {
    if (action === "view") {
      setCustomerPoliciesList(false);
      setIsEditId(`${id}`);
      setIsViewCustomerPolicies(true);
    } else if (action === "edit") {
      setCustomerPoliciesList(false);
      setIsEditId(`${id}`);
      setIsEditCustomerPolicies(true);
      setCreateCustomerPolicies(true);
    } else if (action === "delete") handleDeleteDriver(id);
  };

  const handleDeleteDriver = (id: string) => {
    deleteAlert({
      title: t("Delete"),
      message: t("Are You sure?"),
      confirmButtonText: t("Yes"),
      onConfirmed: deleteAction,
      data: id,
    });
  };

  const deleteAction = (id: string) => {
    commonService
      .deleteService(`/excel-policy/delete-policy/${id}`)
      .then((res: any) => {
        Success(res.data.message);
        getTableData();
      })
      .catch((err) => {
        SweetAlertComponent({
          message: errorMessage(err, "Oops! Something went wrong!"),
          title: "Warning",
          onSuccess: () => {},
        });
      });
  };

  // const handleChangeList = () => {
  //   if (page === 0) getTableData();
  //   else setPage(0);
  // };

  useEffect(() => {
    getTableData();
  }, [rowsPerPage, page, orderBy, order, filter]);

  const getTableData = () => {
    setIsLoading(true);
    // const filterData = filter ? { customer_status: [filter?.value] } : {};
    commonService
      .postServiceParams(
        `/excel-policy/get-all-policy/details`,
        {
          filter: "",
        },
        {
          customer: customerId,
          per_page: rowsPerPage,
          page: page + 1,
          // search: "ATIC",
          sort_order: order,
          sort_by: orderBy,
          // ...filterData,
        }
      )
      .then((res) => {
        console.log("ssss", res?.data?.data?.results[0]?.broker_fee);
        const tableDetails = res?.data?.data?.results.map((item: any) => {
          return {
            id: item?.id,
            policy_number_unlink: item?.policy_number,
            location: item?.location?.name ?? "-",
          };
        });
        console.log(tableDetails);
        setRows(tableDetails);
        setIsLoading(false);
        setInitailLoad(false);
      })
      .catch((err) => {
        Failed(
          errorMessage(err, "Something went wrong, please try again later")
        );
      });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === "ASC";
    order = isAsc ? "DESC" : "ASC";
    setOrder(order);
    orderBy = property;
    setOrderBy(orderBy);
  };

  const searchHandler = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    search = e.target.value;
    setSearch(search);
  };

  useEffect(() => {
    const delay = 500; // Debounce delay in milliseconds
    const performSearch = (text: string) => {
      // Perform your search logic here
      if (initailLoad) return;
      if (page == 0) getTableData();
      else {
        setPage(0);
      }
    };
    // if (search.length >= 3 || !search) {
    const debouncedSearch = debounce(performSearch, delay);
    const timeoutId = setTimeout(() => debouncedSearch(search), delay);
    return () => {
      clearTimeout(timeoutId);
    };
    // }
  }, [search]);

  const handleAddPolicyFinished = () => {
    // Triggered after the policy creation process is finished
    // setIsadd(false);
    // handleChangeList(); // Refresh table data
  };

  const handleImport = () => {
    setIsImport(!isImport);
  };

  const handleTemplateOpen = () => {
    setTemplateOpen(!templateOpen);
  };

  const handleFilterChange = (newFilter: any) => {
    setFilter(newFilter);
    setPage(0);
  };

  const handlCreateCustomerPolicies = () => {
    setCustomerPoliciesList(false);
    setCreateCustomerPolicies(true);
    setIsEditCustomerPolicies(false);
  };

  const handleChangeList = () => {
    setCustomerPoliciesList(true);
    setCreateCustomerPolicies(false);
    getTableData();
  };
  return (
    <>
      {isLoading && <Loader />}
      {isadd && (
        <CustomerPolicyLocation
          handleFormSubmit={handleAddPolicyFinished}
          isEdit={actionEdit}
          isEditId={isEditId}
          open={isadd}
          handleClose={undefined}
        />
      )}
      {isImport && (
        <CustomerPolicyImport
          open={isImport}
          handleClose={handleImport}
          handleFormSubmit={handleChangeList}
          // apiPath="/customer/import-customers"
        />
      )}

      {/* {templateOpen && (
        <TemplateModel open={templateOpen} handleClose={handleTemplateOpen} />
      )} */}

      {CustomerPoliciesList && (
        <MainContainerWrapper
          headingText={t("Customer Policies")}
          isSearch={false}
          search={search}
          searchPlaceHolder={t("Search")}
          handleSearch={searchHandler}
          handleSearchClear={() => setSearch("")}
          isCreateButton={true}
          buttonText={t("Create Policy")}
          buttonIcon={<span>&#43;</span>}
          handleClick={handlCreateCustomerPolicies}
          isSecondButton={false}
          secondButtonText={t("Import From Excel")}
          handleSecondClick={handleImport}
          isCustomerTemplate={false}
          templateButtonText={t("Download Template")}
          istemplateLink="/templates/Customer Policies - template 1.xlsx"
          setUpdateValue={handleFilterChange}
          filterPlaceHolder="Filter By Location"
        >
          <EnhancedTable
            headCells={customerPolicyHeadCell}
            rows={rows}
            rowsPerPage={rowsPerPage}
            maxHeightValue={"70vh"}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleRequestSort={handleRequestSort}
            isSelectable={false}
            order="asc"
            orderBy="name"
            isView={true}
            isEdit={true}
            isDelete={true}
            actionFunction={handleAction}
            routingLink={true}
          />
        </MainContainerWrapper>
      )}

      {createCustomerPolicies && (
        <CreatePoliciesForCustomer
          isEdit={isEditCustomerPolicies}
          isEditId={isEditId}
          isBack={handleChangeList}
        />
      )}

      {isViewCustomerPolicies && <CustomerPolicesView isViewId={isEditId} />}
    </>
  );
};

type CustomerPolicyType = {
  customerId?: string;
};

export default CustomerrPolicy;
