
import { Box, Button, Grid, Typography } from '@mui/material'
import FormLayout from 'component/form'
import { GroupRadioButton, InputField } from 'forms/hoc/formfield'
import useForm from 'forms/hooks/useForm'
import { areYouNewOrExisting, areYouNewOrExistingSchema, policyNumberSchema, policyNumbervalidation } from 'forms/hooks/validateRules'
import MainContainerWrapper from 'layout/wrappers/MainContainerWrapper'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { customerTypeOption } from 'utils/constant.utils'

function NewMakePayment() {

    const { t } = useTranslation()

    const navigate = useNavigate()

    const NewOrExistingCustomerSchemaT = areYouNewOrExistingSchema(t)

    const formSubmit = () => {

        if (values?.new_or_old === "Existing Customer") {
            navigate(`existing-customer`)
        } else {
            navigate(`new-customer`)
        }

    }

    const {
        errors,
        values,
        handleChange,
        handleSubmit,
        setUpdateValue,
    } = useForm({}, formSubmit, areYouNewOrExisting, NewOrExistingCustomerSchemaT);


    return (
        <Box
            sx={{ p: 5, pb: 11, bgcolor: "#EEF2F9" }}
        >

            <MainContainerWrapper
                headingText={t("Make Payment")}
                isBackButton={true}
            >

                <Box sx={{ px: "40%", py: "12%" }}>
                    <FormLayout
                        // handleSubmit={handleSubmit}
                        submitButtonText="Proceed"
                        isSaveButton={false}
                    >
                        <Grid container columnSpacing={2} direction="column">
                            <Grid item xs={12}>
                                <Box sx={{
                                    width: "100%",
                                    marginTop: "10px",
                                    marginBottom: "7px",
                                    border: "1px solid black",
                                    borderRadius: "5px",
                                    padding: "10px",
                                    bgcolor: "#f2f2f2",
                                    textAlign: "center"

                                }}>
                                    {/* <Typography>{t("Are you New or Existing Customer?")}</Typography> */}
                                    <GroupRadioButton
                                        isRow={false}
                                        fieldName={t("Are you New or Existing Customer?")}
                                        values={values?.new_or_old || ""}
                                        name="new_or_old"
                                        errors={errors?.new_or_old}
                                        options={customerTypeOption}
                                        handleChange={handleChange}
                                        id="new_or_old"
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12} sx={{ alignItems: "center", mt: 2 }} >
                                <Box sx={{ alignItems: "center" }} >
                                    <Button
                                        onClick={handleSubmit}
                                        sx={{
                                            fontSize: "18px",
                                            width: "100%",
                                        }}
                                    >
                                        {`${t("Proceed")}`}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>

                    </FormLayout>
                </Box>
            </MainContainerWrapper>
        </Box>
    )
}

export default NewMakePayment