import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  debounce,
  styled,
} from "@mui/material";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { SxProps, useTheme } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers";

import MuiPhoneNumber from "material-ui-phone-number";
import {
  handleKeyNumberPress,
  handleKeyDecimalPress,
  handleKeyNumberCommaPress,
} from "forms/hooks/customHook";
import "./form.scss";
import HoverImagePopover from "./helperText";
import { isMobile } from "react-device-detect";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { StaticDatePicker } from "@mui/x-date-pickers";
import { SketchPicker } from "react-color";
import Modal from "@mui/material/Modal";
import EventIcon from "@mui/icons-material/Event";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { InfoTooltip } from "./InfoTooltip";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { useTranslation } from "react-i18next";
import { t } from "i18next";

export const InputField = ({
  fieldName,
  fontSize = "1rem",
  values,
  helperText,
  errors,
  handleChange,
  blur = false,
  name,
  isRequired = true,
  type = "text",
  disabled = false,
  placeholder,
  Icon,
  adorament,
  position,
  handleIconClick,
  length,
  minRow = 0,
  multiline = false,
  id = name,
  onlyNumber = false,
  onlyNumComma = false,
  onlyDecimalNumber = false,
  onBlur,
  adoramentText,
  isInfo = false,
  mask = false, // Default value for mask
  inputWidth = "100%",
  subFieldName,
  ...rest // Accepting the rest of the props
}: InputFieldtypes) => {
  const theme = useTheme();

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  }

  return (
    <>
      <Box>
        {fieldName && (
          <>
            <Stack direction={"row"} alignItems={"center"} >

          <Typography
            py="10px"
            fontWeight={400}
            fontSize={fontSize}
            pr={1}
            color={blur ? 'grey' : theme.palette.text.primary}
          >
            {fieldName} {' '} {subFieldName && <span style={{ color: "red", fontSize: "10px" }}> {`(${subFieldName})`} </span>}
            {isRequired && <span style={{ color: "red" }}>*</span>}
          </Typography>

              {isInfo && <InfoTooltip
                title={"Enter the number of days before which the notification must be sent to the customer."}
                position={"top"}
              >
                <IconButton sx={{ padding: '0px !important' }}>
                  <InfoOutlinedIcon />
                </IconButton>
              </InfoTooltip>}
            </Stack>
          </>
        )}
        <TextField
          variant="outlined"
          value={values || ""}
          name={name}
          onChange={handleChange}
          sx={{
            width: inputWidth,
            backgroundColor: theme.palette.background.paper,
          }}
          placeholder={placeholder ? placeholder : ""}
          inputProps={{
            maxLength: length,
          }}
          multiline={multiline}
          minRows={minRow}
          // onKeyDown={handleKeyPress}
          // InputProps={
          //   adorament
          //     ? {
          //         [adorament]: (
          //           <InputAdornment
          //             position={position ? position : "start"}
          //             disablePointerEvents={handleIconClick ? false : true}
          //           >
          //             {adoramentText ? (
          //               adoramentText
          //             ) : (
          //               <IconButton onClick={handleIconClick}>
          //                 <Icon />
          //               </IconButton>
          //             )}
          //           </InputAdornment>
          //         ),
          //       }
          //     : undefined
          // }

          InputProps={{
            ...adorament
              ? {
                [adorament]: (
                  <InputAdornment
                    position={position ? position : "start"}
                    disablePointerEvents={handleIconClick ? false : true}
                  >
                    {adoramentText ? (
                      adoramentText
                    ) : (
                      <IconButton onClick={handleIconClick}>
                        <Icon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }
              : undefined,
            type: mask ? "password" : type // Apply masking
          }}
          disabled={disabled}
          error={Boolean(errors)}
          type={type}
          onKeyPress={(e) => {
            onlyDecimalNumber && handleKeyDecimalPress(e);
            onlyNumber && handleKeyNumberPress(e);
            onlyNumComma && handleKeyNumberCommaPress(e);
          }}
          id={id}
          helperText={!errors && helperText}
          onBlur={onBlur && onBlur}
          {...rest} // Passing the rest of the props to the TextField
        />
      </Box>
      {errors && (
        <FormHelperText
          error
          id="standard-weight-helper-text-email-login"
          sx={{ mt: 0.3, p: 0 }}
        >
          {errors}
        </FormHelperText>
      )}
    </>
  );
};
type InputFieldtypes = {
  isInfo?: boolean;
  fieldName?: string;
  fontSize?: string;
  values: string | number | null | undefined;
  helperText?: string;
  handleChange?: (_: any) => void;
  errors?: any;
  name?: string;
  type?: string;
  isRequired?: boolean;
  disabled?: boolean;
  placeholder?: string;
  adorament?: "endAdornment" | "startAdornment";
  position?: "start" | "end";
  Icon?: any;
  handleIconClick?: any;
  length?: number;
  multiline?: boolean;
  minRow?: number;
  id?: string;
  onlyNumber?: boolean;
  onlyNumComma?: boolean;
  onlyDecimalNumber?: boolean;
  onBlur?: (_: any) => void;
  adoramentText?: string;
  mask?: boolean;
  inputWidth?: string;
  sx?: SxProps;
  blur?: boolean;
  subFieldName?: string;
};


export const InputFieldWithNumber = ({
  fieldName,
  values,
  helperText,
  errors,
  handleChange,
  name,
  isRequired = true,
  disabled = false,
  placeholder,
  length,
  minRow = 0,
  id = name,
  onBlur,
}: InputFieldWithNumberProps) => {
  const theme = useTheme();

  const handleIncrement = () => {
    if (typeof values === 'number' && handleChange) {
      handleChange({ target: { name, value: values + 1 } });
    } else if (handleChange) {
      handleChange({ target: { name, value: 1 } });
    }
  };

  const handleDecrement = () => {
    if (typeof values === 'number' && values > 0 && handleChange) {
      handleChange({ target: { name, value: values - 1 } });
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const charCode = e.charCode;
    // Prevent non-numeric and negative number inputs
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };
  return (
    <>
      <Box>
        {fieldName && (
          <Typography
            py="10px"
            fontWeight={400}
            fontSize={"1rem"}
            color={theme.palette.text.dark}
          >
            {fieldName}
            {isRequired && <span style={{ color: "red" }}>*</span>}
          </Typography>
        )}
        <TextField
          variant="outlined"
          value={values || ""}
          name={name}
          onChange={handleChange}
          sx={{
            width: "100%",
            backgroundColor: theme.palette.background.paper,
          }}
          placeholder={placeholder ? placeholder : ""}
          inputProps={{
            maxLength: length,
          }}
          multiline={false}
          minRows={minRow}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleDecrement}>
                  <RemoveIcon />
                </IconButton>
                <IconButton onClick={handleIncrement}>
                  <AddIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          disabled={disabled}
          error={Boolean(errors)}
          onKeyPress={handleKeyPress}
          id={id}
          helperText={!errors && helperText}
        />
      </Box>
      {errors && (
        <FormHelperText
          error
          id="standard-weight-helper-text-email-login"
          sx={{ mt: 0.3, p: 0 }}
        >
          {errors}
        </FormHelperText>
      )}
    </>
  );
};

type InputFieldWithNumberProps = {
  fieldName?: string;
  values: number | null | undefined;
  helperText?: string;
  handleChange?: (_: any) => void;
  errors?: any;
  name?: string;
  type?: string;
  isRequired?: boolean;
  disabled?: boolean;
  placeholder?: string;
  length?: number;
  minRow?: number;
  id?: string;
  onBlur?: (_: any) => void;
};




// Input Text Area 


export const InputTextAreaField = ({
  fieldName,
  values,
  helperText,
  errors,
  handleChange,
  name,
  isRequired = true,
  type = "text",
  disabled = false,
  placeholder,
  Icon,
  adorament,
  position,
  handleIconClick,
  length,
  minRow = 0,
  multiline = false,
  id = name,
  onlyNumber = false,
  onlyDecimalNumber = false,
  onBlur,
  adoramentText,
}: InputTextAreaFieldTypes) => {
  const theme = useTheme();
  return (
    <>
      <Box>
        {fieldName && (
          <Typography
            py="10px"
            fontWeight={400}
            fontSize={"1rem"}
            color={theme.palette.text.dark}
          >
            {fieldName}
            {isRequired && <span style={{ color: "red" }}>*</span>}
          </Typography>
        )}
        <TextField
          variant="outlined"
          value={values || ""}
          name={name}
          onChange={handleChange}
          sx={{
            width: "100%",
            backgroundColor: theme.palette.background.paper,
          }}
          placeholder={placeholder ? placeholder : ""}
          inputProps={{
            maxLength: length,
          }}
          // multiline={multiline}
          // minRows={minRow}
          multiline   // Enable multiline input
          minRows={3}  // Minimum number of rows
          maxRows={10} // Optional: Maximum number of rows (adjust as needed)
          InputProps={
            adorament
              ? {
                [adorament]: (
                  <InputAdornment
                    position={position ? position : "start"}
                    disablePointerEvents={handleIconClick ? false : true}

                  >
                    {adoramentText ? (
                      adoramentText
                    ) : (
                      <IconButton onClick={handleIconClick}>
                        <Icon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }
              : undefined
          }
          disabled={disabled}
          error={Boolean(errors)}
          type={type}
          onKeyPress={(e) => {
            onlyDecimalNumber && handleKeyDecimalPress(e);
            onlyNumber && handleKeyNumberPress(e);
          }}
          id={id}
          helperText={!errors && helperText}
          onBlur={onBlur && onBlur}
        />
      </Box>
      {errors && (
        <FormHelperText
          error
          id="standard-weight-helper-text-email-login"
          sx={{ mt: 0.3, p: 0 }}
        >
          {errors}
        </FormHelperText>
      )}
    </>
  );
};
type InputTextAreaFieldTypes = {
  fieldName?: string;
  values: string | number | null | undefined;
  helperText?: string;
  handleChange?: (_: any) => void;
  errors?: any;
  name?: string;
  type?: string;
  isRequired?: boolean;
  disabled?: boolean;
  placeholder?: string;
  adorament?: "endAdornment" | "startAdornment";
  position?: "start" | "end";
  Icon?: any;
  handleIconClick?: any;
  length?: number;
  multiline?: boolean;
  minRow?: number;
  id?: string;
  onlyNumber?: boolean;
  onlyDecimalNumber?: boolean;
  onBlur?: () => void;
  adoramentText?: string;
};



export const InputFieldMinus = ({
  fieldName,
  values,
  helperText,
  errors,
  handleChange,
  name,
  isRequired = true,
  type = "text",
  disabled = false,
  placeholder,
  Icon,
  adorament,
  position,
  handleIconClick,
  length,
  minRow = 0,
  multiline = false,
  id = name,
  onlyNumber = false,
  onlyDecimalNumber = false,
}: InputFieldtypesMinus) => {
  const theme = useTheme();
  return (
    <>
      <Box>
        {fieldName && (
          <Typography
            py="10px"
            fontWeight={400}
            fontSize={"1rem"}
            color={theme.palette.text.dark}
          >
            {fieldName}
            {isRequired && <span style={{ color: "red" }}>*</span>}
          </Typography>
        )}
        <TextField
          variant="outlined"
          value={values || ""}
          name={name}
          onChange={handleChange}
          sx={{
            width: "100%",
            backgroundColor: theme.palette.background.paper,
          }}
          placeholder={placeholder ? placeholder : "-"}
          inputProps={{
            maxLength: length,
          }}
          multiline={multiline}
          minRows={minRow}
          InputProps={
            adorament
              ? {
                [adorament]: (
                  <InputAdornment
                    position={position ? position : "start"}
                    disablePointerEvents={handleIconClick ? false : true}
                  >
                    <IconButton onClick={handleIconClick}>
                      <Icon />
                    </IconButton>
                  </InputAdornment>
                ),
              }
              : undefined
          }
          disabled={disabled}
          error={Boolean(errors)}
          type={type}
          onKeyPress={(e) => {
            onlyDecimalNumber && handleKeyDecimalPress(e);
            onlyNumber && handleKeyNumberPress(e);
          }}
          id={id}
          helperText={!errors && helperText}
        />
      </Box>
      {errors && (
        <FormHelperText
          error
          id="standard-weight-helper-text-email-login"
          sx={{ mt: 0.3, p: 0 }}
        >
          {errors}
        </FormHelperText>
      )}
    </>
  );
};
type InputFieldtypesMinus = {
  fieldName?: string;
  values: string | number | null | undefined;
  helperText?: string;
  handleChange?: (_: any) => void;
  errors?: any;
  name?: string;
  type?: string;
  isRequired?: boolean;
  disabled?: boolean;
  placeholder?: string;
  adorament?: "endAdornment" | "startAdornment";
  position?: "start" | "end";
  Icon?: any;
  handleIconClick?: any;
  length?: number;
  multiline?: boolean;
  minRow?: number;
  id?: string;
  onlyNumber?: boolean;
  onlyDecimalNumber?: boolean;
};

export const InputFieldMask = ({
  fieldName,
  values,
  errors,
  handleChange,
  name,
  isRequired = true,
  type = "text",
  disabled = false,
  placeholder,
  Icon,
  adorament,
  position,
  handleIconClick,
  length,
  minRow = 0,
  multiline = false,
  id = name,
  onlyNumber = false,
  onlyDecimalNumber = false,
}: InputFieldtypesMask) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [onFocus, setOnFocus] = useState<boolean>();
  const [maskedValue, setMaskedValue] = useState<any>();

  const onFocusIN = (e: any) => {
    setOnFocus(false);
  };

  const onHandleFocusOut = (e: any) => {
    let value = e.target.value;
    let maskedValue =
      value.substring(0, value.length - 3).replace(/./g, "X") +
      value.substring(value.length - 3);
    setMaskedValue(maskedValue);
    setOnFocus(true);
  };
  return (
    <>
      <Box>
        {fieldName && (
          <Typography
            py="10px"
            fontWeight={400}
            fontSize={"1rem"}
            color={theme.palette.text.dark}
          >
            {fieldName}
            {isRequired && <span style={{ color: "red" }}>*</span>}
          </Typography>
        )}
        {onFocus ? (
          <TextField
            value={maskedValue}
            onFocus={onFocusIN}
            sx={{
              width: "100%",

              backgroundColor: theme.palette.background.paper,
            }}
          />
        ) : (
          <TextField
            variant="outlined"
            value={values || ""}
            name={name}
            onChange={handleChange}
            sx={{
              width: "100%",
              backgroundColor: theme.palette.background.paper,
            }}
            placeholder={placeholder ? placeholder : ""}
            inputProps={{
              maxLength: length,
            }}
            multiline={multiline}
            minRows={minRow}
            InputProps={
              adorament
                ? {
                  [adorament]: (
                    <InputAdornment
                      position={position ? position : "start"}
                      disablePointerEvents={handleIconClick ? false : true}
                    >
                      <IconButton onClick={handleIconClick}>
                        <Icon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }
                : undefined
            }
            disabled={disabled}
            error={Boolean(errors)}
            type={type}
            onKeyPress={(e) => {
              onlyDecimalNumber && handleKeyDecimalPress(e);
              onlyNumber && handleKeyNumberPress(e);
            }}
            onBlur={onHandleFocusOut}
            id={id}
          />
        )}
      </Box>
      {errors && (
        <FormHelperText
          error
          id="standard-weight-helper-text-email-login"
          sx={{ mt: 0.3, p: 0 }}
        >
          {errors}
        </FormHelperText>
      )}
    </>
  );
};
type InputFieldtypesMask = {
  fieldName?: string;
  values: string | number | null | undefined;
  handleChange?: (_: any) => void;
  errors?: any;
  name?: string;
  type?: string;
  isRequired?: boolean;
  disabled?: boolean;
  placeholder?: string;
  adorament?: "endAdornment" | "startAdornment";
  position?: "start" | "end";
  Icon?: any;
  handleIconClick?: any;
  length?: number;
  multiline?: boolean;
  minRow?: number;
  id?: string;
  onlyNumber?: boolean;
  onlyDecimalNumber?: boolean;
};

// export default SelectField;

export const SelectField = ({
  fieldName,
  values,
  errors,
  handleChange,
  name,
  options,
  disabled = false,
  isRequired = true,
  placeholder = "",
  helphover = false,
  id = name,
  helperImg,
  helperContent,
  isValueClearRequired = false,
  handleClearSelectValue,
  blur = false,
}: SelectFieldtypes) => {
  const theme = useTheme();
  return (
    <>
      <Box>
        {fieldName && (
          <Typography
            py="10px"
            fontWeight={400}
            fontSize={"1rem"}
            color={blur ? 'grey' : theme.palette.text.primary}
          >
            {fieldName}
            {isRequired && <span style={{ color: "red" }}>*</span>}
            {helphover && (
              <span>
                <HoverImagePopover img={helperImg} content={helperContent} />
              </span>
            )}
          </Typography>
        )}
        <TextField
          variant="outlined"
          id={id}
          name={name}
          select
          required={isRequired}
          onChange={handleChange}
          error={Boolean(errors)}
          sx={{
            width: "100%",
            // '& .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon': {
            //   display: ((values && isValueClearRequired) ? 'none' : 'inline'),
            // },
          }}
          value={values || ""}
          disabled={disabled}
          InputLabelProps={{
            shrink: false, // Display placeholder
          }}
          label={!values ? placeholder : ""}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                style: {
                  maxHeight: 300, // Adjust the max height as needed
                },
              },
            },
            endAdornment: values && isValueClearRequired && (
              <IconButton
                size="small"
                sx={{ mr: ".75rem" }}
                onClick={handleClearSelectValue}
              >
                <ClearIcon />
              </IconButton>
            ),
          }}
        >
          {!!options?.length &&
            options?.map((item: any) => (
              <MenuItem value={item.value} key={item.value}>
                {item.name}
              </MenuItem>
            ))}
        </TextField>
      </Box>
      {errors && (
        <FormHelperText error id="standard-weight-helper-text-email-login">
          {errors}
        </FormHelperText>
      )}
    </>
  );
};

type SelectFieldtypes = {
  fieldName?: string;
  values: string | number | null | undefined;
  handleChange: (_: any) => void;
  errors: any;
  name: string;
  options: { value: string | number | boolean; name: string }[];
  disabled?: boolean;
  isRequired?: boolean;
  placeholder?: string;
  id?: string;
  helphover?: boolean;
  helperImg?: any;
  helperContent?: any;
  isValueClearRequired?: boolean;
  handleClearSelectValue?: any;
  blur?: boolean;
};

export const SelectFieldLanguage = ({
  fieldName,
  values,
  errors,
  handleChange,
  name,
  options,
  disabled = false,
  isRequired = true,
  placeholder = "",
  helphover = false,
  id = name,
  helperImg,
  helperContent,
  isValueClearRequired = false,
  handleClearSelectValue,
}: SelectFieldtypesLanguage) => {
  const theme = useTheme();
  return (
    <>
      <Box>
        <Typography
          py="10px"
          fontWeight={400}
          fontSize={"1rem"}
          color={theme.palette.text.dark}
        >
          {fieldName}
          {isRequired && <span style={{ color: "red" }}>*</span>}
          {helphover && (
            <span>
              <HoverImagePopover img={helperImg} content={helperContent} />
            </span>
          )}
        </Typography>
        <TextField
          variant="outlined"
          id={id}
          name={name}
          select
          required={isRequired}
          onChange={handleChange}
          error={Boolean(errors)}
          sx={{
            width: "100%",
            // '& .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon': {
            //   display: ((values && isValueClearRequired) ? 'none' : 'inline'),
            // },
          }}
          value={values || ""}
          disabled={disabled}
          InputLabelProps={{
            shrink: false, // Display placeholder
          }}
          label={!values && "Please select a preferred langugae"}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                style: {
                  maxHeight: 300, // Adjust the max height as needed
                },
              },
            },
            endAdornment: values && isValueClearRequired && (
              <IconButton
                size="small"
                sx={{ mr: ".75rem" }}
                onClick={handleClearSelectValue}
              >
                <ClearIcon />
              </IconButton>
            ),
          }}
        >
          {!!options.length &&
            options.map((item: any) => (
              <MenuItem value={item.value} key={item.value}>
                {item.name}
              </MenuItem>
            ))}
        </TextField>
      </Box>
      {errors && (
        <FormHelperText error id="standard-weight-helper-text-email-login">
          {errors}
        </FormHelperText>
      )}
    </>
  );
};

type SelectFieldtypesLanguage = {
  fieldName: string;
  values: string | number | null | undefined;
  handleChange: (_: any) => void;
  errors: any;
  name: string;
  options: { value: string | number | boolean; name: string }[];
  disabled?: boolean;
  isRequired?: boolean;
  placeholder?: string;
  id?: string;
  helphover?: boolean;
  helperImg?: any;
  helperContent?: any;
  isValueClearRequired?: boolean;
  handleClearSelectValue?: any;
};

export const GroupRadioButton = ({
  fieldName,
  values,
  errors,
  handleChange,
  name,
  isRow,
  isRowWithLabel = false,
  options,
  isRequired = true,
  id = name,
  disabled = false,
}: GroupRadioFieldtypes) => {
  const theme = useTheme();

  return (
    <FormControl
      sx={
        isRowWithLabel
          ? { display: "flex", flexDirection: "row" }
          : { display: "inline-block" }
      }
    >
      {fieldName && (
        <Typography
          py="10px"
          fontWeight={400}
          fontSize={"1rem"}
          color={theme.palette.text.dark}
        >
          {fieldName}
          {isRequired && <span style={{ color: "red" }}>*</span>}
        </Typography>
      )}

      <RadioGroup
        row={isRow}
        aria-labelledby="demo-controlled-radio-buttons-group"
        name={name}
        value={values || ""}
        sx={{ marginLeft: isRowWithLabel ? "2rem" : "0" }}
        onChange={handleChange}
      >
        {!!options.length &&
          options.map((item: any) => (
            <FormControlLabel
              key={item.name}
              value={item.value}
              control={<Radio />}
              label={item.name}
              disabled={disabled}
            />
          ))}
      </RadioGroup>
      {errors && <FormHelperText error>{errors}</FormHelperText>}
    </FormControl>
  );
};

type GroupRadioFieldtypes = {
  fieldName?: string;
  values: string | number | null | undefined | boolean;
  isRow: boolean;
  handleChange: (_: any) => void;
  errors?: any;
  name: string;
  options: { value: string | number | boolean; name: string }[];
  isRequired?: boolean;
  id?: string;
  disabled?: boolean;
  isRowWithLabel?: boolean;
};

export const AutoCompleteField = ({
  fieldName,
  values,
  errors,
  handleChange,
  name,
  options,
  isRequired = true,
  disabled = false,
  handleInputChange,
  id = name,
  loading = false,
  isFreeSolo = false,
  placeholder = "",
}: AutoCompleteFieldtypes) => {
  const theme = useTheme();

  const delay = 500;
  const debouncedHandleChange = debounce((value: any) => {
    console.log("working");
    handleInputChange && handleInputChange(value);
  }, delay);

  return (
    <>
      <Box>
        {fieldName && (
          <Typography
            py="10px"
            fontWeight={400}
            fontSize={"1rem"}
            color={theme.palette.text.dark}
          >
            {fieldName && fieldName}
            {isRequired && <span style={{ color: "red" }}>*</span>}
          </Typography>
        )}
        <Autocomplete
          value={values}
          // onChange={handleChange}
          onOpen={() => handleInputChange && handleInputChange("")}
          sx={{
            width: "100%",
          }}
          freeSolo={isFreeSolo}
          PaperComponent={(props) => (
            <Paper
              sx={{
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              }}
              {...props}
            />
          )}
          onChange={(event: any, newValue: any) => {
            handleChange(newValue);
          }}
          id={id}
          className="auto_complete_default_height"
          getOptionLabel={(option: any) => option.name}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0, p: 1 } }}
              {...props}
            >
              {option.name}
            </Box>
          )}
          options={options}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              error={Boolean(errors)}
              placeholder={placeholder}
              variant="outlined"
              onChange={(e: any) => debouncedHandleChange(e.target.value)}
            />
          )}
          disabled={disabled}
          noOptionsText={t("No Options")}
        />
      </Box>
      {errors && <FormHelperText error>{errors}</FormHelperText>}
    </>
  );
};

type AutoCompleteFieldtypes = {
  fieldName?: string;
  values: { value: string | number | boolean; name: string } | null;
  handleChange: (_: any) => void;
  errors?: any;
  name: string;
  options: { value: string | number; name: string }[];
  isRequired?: boolean;
  disabled?: boolean;
  id?: string;
  handleInputChange?: (_: any) => void;
  loading?: boolean;
  isFreeSolo?: boolean;
  placeholder?: string;
};

export const MultiAutoCompleteField = ({
  fieldName,
  values,
  errors,
  handleChange,
  name,
  options,
  isRequired = true,
  id = name,
  size,
  multiple = false,
  readOnly = false,
  handleInputChange,
  label,
  onOpen,
  limit = 3,
  loading = false,
}: MultiAutoCompleteFieldtypes) => {
  const theme = useTheme();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const delay = 500;
  const debouncedHandleChange = debounce((value: any) => {
    console.log("working");
    handleInputChange && handleInputChange(value);
  }, delay);

  console.log("multiple select", values);

  return (
    <>
      <Box>
        <Typography
          py="10px"
          fontWeight={400}
          fontSize={"1rem"}
          color={theme.palette.text.dark}
        >
          {fieldName && fieldName}
          {isRequired && <span style={{ color: "red" }}>*</span>}
        </Typography>
        <Autocomplete
          disableListWrap
          value={values}
          defaultValue={values}
          multiple={multiple}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          disableCloseOnSelect={true}
          disabled={readOnly}
          id={id}
          limitTags={limit}
          className="auto_complete_default_height"
          getOptionLabel={(option) => option.name}
          size={size}
          options={options}
          loading={loading}
          onOpen={() => handleInputChange && handleInputChange("")}
          // open={true}
          sx={{
            width: "100%",
          }}
          // PaperComponent={(props) => (
          //   <Paper
          //     sx={{
          //       boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          //     }}
          //     {...props}
          //   />
          // )}
          onChange={(event: any, newValue: any) => {
            handleChange(newValue);
          }}
          renderOption={(props, option, { selected }) => {
            return (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                <span style={{ width: "90%", overflowWrap: "break-word" }}>
                  {option.name}
                </span>
              </Box>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={Boolean(errors)}
              placeholder={label}
              variant="outlined"
              // InputProps={{ ...params.InputProps, disableUnderline: true }}
              onChange={(e: any) => debouncedHandleChange(e.target.value)}
            />
          )}
        // renderTags={(value, getTagProps) => {
        //   const numTags = value.length;
        //   const limitTags = 4;
        //   return (
        //     <>
        //       {value.slice(0, limitTags).map((option, index) => (
        //         <Chip
        //           {...getTagProps({ index })}
        //           key={index}
        //           label={option.name}
        //         />
        //       ))}
        //       {numTags > limitTags && ` +${numTags - limitTags}`}
        //     </>
        //   );
        // }}
        />
      </Box>
      {errors && <FormHelperText error>{errors}</FormHelperText>}
    </>
  );
};

type MultiAutoCompleteFieldtypes = {
  fieldName?: string;
  values: any;
  // values: { value: string | number | boolean; name: string } | null;
  handleChange: (_: any) => void;
  errors?: any;
  name: string;
  options: { value: string | number; name: string }[];
  isRequired?: boolean;
  disabled?: boolean;
  id?: string;
  size?: "small" | "medium" | undefined;
  multiple?: boolean;
  readOnly?: boolean;
  handleInputChange?: (_: any) => void;
  label?: string;
  onOpen?: () => void;
  limit?: number;
  loading?: boolean;
};

//Date Picker
export const InputDatePicker = ({
  fieldName,
  values,
  errors,
  handleChange,
  name,
  isRequired = true,
  disabled = false,
  placeholder,
  maxDate,
  minDate,
  format,
  id = name,
  actionBar = {},
}: datePickerFiledType) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const onKeyDown = (e: any) => {
    e.preventDefault();
    return false;
  };

  return (
    <>
      <Box>
        {fieldName && (
          <Typography
            py="10px"
            fontWeight={400}
            fontSize={"1rem"}
            color={theme.palette.text.dark}
          >
            {fieldName}
            {isRequired && <span style={{ color: "red" }}>*</span>}
          </Typography>
        )}
        <DatePicker
          value={values || ""}
          onChange={handleChange}
          sx={{
            width: "100%",
            "& .MuiButton-root": {
              backgroundColor: "#0fadd5",
            },
          }}
          open={open}
          onClose={() => setOpen(!open)}
          disabled={disabled}
          disableOpenPicker={disabled}
          maxDate={maxDate}
          minDate={minDate}
          className={
            disabled ? "datepicker-input-slot1" : "datepicker-input-slot"
          }
          slotProps={{
            textField: {
              name: name,
              id,
              autoComplete: "off",
              onKeyPress: (e: any) => onKeyDown(e),
              disabled: disabled ? disabled : false,
              error: Boolean(errors),
              onClick: (e) => setOpen(!open),
              // sx: (theme) => ({
              //   day: {
              //     sx: (theme) => ({
              //       "&.MuiPickersDay-root.Mui-selected": {
              //         backgroundColor: theme.palette.primary.main,
              //         color: "white"
              //       },
              //       "&.MuiPickersYear-root.Mui-selected": {
              //         backgroundColor: theme.palette.primary.main,
              //         "&:hover": {
              //           backgroundColor: theme.palette.primary.main,
              //         },
              //         "&:focus": {
              //           backgroundColor: theme.palette.primary.main,
              //         },
              //       },
              //     }),
              //   },
              // }),
              className: disabled
                ? "datepicker-input-slot1"
                : "datepicker-input-slot",
            },
            actionBar: {
              actions: ["clear"],
            },
            //actionBar: actionBar,
          }}
          format={format || "MM/DD/YYYY"}
        />
      </Box>
      {errors && <FormHelperText error>{errors}</FormHelperText>}
    </>
  );
};

type datePickerFiledType = {
  fieldName?: string;
  // values: string | number | null | undefined;
  values: any;
  handleChange?: (_: any) => void;
  errors?: any;
  name?: string;
  isRequired?: boolean;
  disabled?: boolean;
  placeholder?: string;
  format?: string;
  id?: string;
  maxDate?: any;
  minDate?: any;
  actionBar?: any;
};
export const InputTimePicker = ({
  fieldName,
  values,
  errors,
  handleChange,
  name,
  isRequired = true,
  disabled = false,
  placeholder,
  maxDate,
  minDate,
  format,
  id = name,
  actionBar = {},
}: timePickerFiledType) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const onKeyDown = (e: any) => {
    e.preventDefault();
    return false;
  };

  return (
    <>
      <Box>
        {fieldName && (
          <Typography
            py="10px"
            fontWeight={400}
            fontSize={"1rem"}
            color={theme.palette.text.dark}
          >
            {fieldName}
            {isRequired && <span style={{ color: "red" }}>*</span>}
          </Typography>
        )}
        <TimePicker
          value={values}
          onChange={handleChange}
          sx={{
            width: "100%",
            "& .MuiButton-root": {
              backgroundColor: "#0fadd5",
            },
          }}
          open={open}
          onClose={() => setOpen(!open)}
          disabled={disabled}
          label=""
          className={
            disabled ? "datepicker-input-slot1" : "datepicker-input-slot"
          }
          slotProps={{
            textField: {
              name: name,
              id,
              autoComplete: "off",
              onKeyPress: (e: any) => onKeyDown(e),
              disabled: false,
              error: Boolean(errors),
              onClick: (e) => setOpen(!open),
              placeholder: "",
              InputProps: { placeholder: "" },
              // sx: (theme) => ({
              //   day: {
              //     sx: (theme) => ({
              //       "&.MuiPickersDay-root.Mui-selected": {
              //         backgroundColor: theme.palette.primary.main,
              //         color: "white"
              //       },
              //       "&.MuiPickersYear-root.Mui-selected": {
              //         backgroundColor: theme.palette.primary.main,
              //         "&:hover": {
              //           backgroundColor: theme.palette.primary.main,
              //         },
              //         "&:focus": {
              //           backgroundColor: theme.palette.primary.main,
              //         },
              //       },
              //     }),
              //   },
              // }),
              className: disabled
                ? "datepicker-input-slot1"
                : "datepicker-input-slot",
            },
            actionBar: {
              actions: ["clear"],
            },
            //actionBar: actionBar,
          }}
          format={format || "hh:mm:A"}
        />
      </Box>
      {errors && <FormHelperText error>{errors}</FormHelperText>}
    </>
  );
};

type timePickerFiledType = {
  fieldName: string;
  // values: string | number | null | undefined;
  values: any;
  handleChange?: (_: any) => void;
  errors?: any;
  name?: string;
  isRequired?: boolean;
  disabled?: boolean;
  placeholder?: string;
  format?: string;
  id?: string;
  maxDate?: any;
  minDate?: any;
  actionBar?: any;
};

//Date Dob Picker
export const InputDatePickerdob = ({
  fieldName,
  values,
  errors,
  handleChange,
  name,
  isRequired = true,
  disabled = false,
  placeholder,
  maxDate,
  minDate,
  format,
  id = name,
  actionBar = {},
}: datePickerFiledTypeDob) => {
  const theme = useTheme();

  const [isDatePickerField, setDatePickerField] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [isInitialLoad, setInitiLoad] = useState<boolean>(true);

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (values && isInitialLoad) {
      handleDateChange(values);
    }
  }, [values]);

  const handleDateChange = (date: string | null) => {
    setInitiLoad(false);
    let formattedDate = null;
    if (date) {
      const parsedDate = new Date(date);
      const options: Intl.DateTimeFormatOptions = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      };
      const monthMask = "XX";
      const dayMask = "XX";
      const month = monthMask.replace(/X/g, "X");
      const day = dayMask.replace(/X/g, "X");
      const year = parsedDate.getFullYear().toString();
      const maskedYear = "X" + year.slice(1); // Mask the first digit of the year
      formattedDate = parsedDate.toLocaleDateString("en-US", options);
      formattedDate = month + "/" + day + "/" + maskedYear;
    }
    setSelectedDate(formattedDate);
    handleChange && handleChange(date);
    setDatePickerField(false);
  };

  const handleOpenDatePicker = () => {
    setDatePickerField(true);
  };

  return (
    <>
      <Box sx={{ position: "relative" }}>
        {fieldName && (
          <Typography
            py="10px"
            fontWeight={400}
            fontSize="1rem"
            color={theme.palette.text.dark}
          >
            {fieldName}
            {isRequired && <span style={{ color: "red" }}>*</span>}
          </Typography>
        )}
        {!isDatePickerField ? (
          <TextField
            value={selectedDate}
            sx={{
              width: "100%",
            }}
            disabled={disabled}
            placeholder="DD/MM/YYYY"
            InputProps={{
              readOnly: true,
              endAdornment: (
                <IconButton
                  size="small"
                  onClick={handleOpenDatePicker}
                  disabled={disabled}
                >
                  <EventIcon />
                </IconButton>
              ),
            }}
          />
        ) : (
          <DatePicker
            open={isDatePickerField}
            value={values || ""}
            onChange={handleDateChange}
            sx={{ width: "100%" }}
            disabled={disabled}
            maxDate={maxDate}
            minDate={minDate}
            className={
              disabled ? "datepicker-input-slot1" : "datepicker-input-slot"
            }
            slotProps={{
              textField: {
                name: name,
                id,
                onKeyPress: (e: any) => onKeyDown(e),
                disabled: isMobile ? false : true,
                error: Boolean(errors),
                className: disabled
                  ? "datepicker-input-slot1"
                  : "datepicker-input-slot",
              },
              actionBar: actionBar,
            }}
            format={format || "MM/DD/YYYY"}
          />
        )}
      </Box>
      {errors && <FormHelperText error>{errors}</FormHelperText>}
    </>
  );
};

type datePickerFiledTypeDob = {
  fieldName: string;
  values: any;
  handleChange?: (_: any) => void;
  errors?: any;
  name?: string;
  isRequired?: boolean;
  disabled?: boolean;
  placeholder?: string;
  format?: string;
  id?: string;
  maxDate?: any;
  minDate?: any;
  actionBar?: any;
};

export const PhoneField = (props: phoneField) => {
  const theme = useTheme();

  const {
    handleChange,
    name,
    errors,
    fieldName,
    isRequired = true,
    value,
    disabled,
    id,
    blur = false,
  } = props;

  return (
    <>
      <Box>
        {fieldName && (
          <Typography
            py="10px"
            fontWeight={400}
            fontSize={"1rem"}
            color={blur ? 'grey' : theme.palette.text.primary}
          >
            {fieldName}
            {isRequired && <span style={{ color: "red" }}>*</span>}
          </Typography>
        )}
        <MuiPhoneNumber
          variant="outlined"
          name={name}
          InputProps={{
            name: name,
          }}
          onChange={(e) => handleChange({ target: { value: e, name: name } })}
          error={Boolean(errors)}
          value={value}
          disableAreaCodes={true}
          defaultCountry={"us"}
          preferredCountries={["us"]}
          disabled={disabled}
          id={id}
          sx={{ "& svg": { height: "1em" }, width: "100%" }}
        />
      </Box>
      {errors && <FormHelperText error>{errors}</FormHelperText>}
    </>
  );
};

type phoneField = {
  fieldName?: string;
  errors?: any;
  name: string;
  isRequired?: boolean;
  handleChange?: any;
  value?: string;
  disabled?: boolean;
  id?: string;
  blur?: boolean;
};

export const PhoneFieldMask = (props: phoneFieldMask) => {
  const theme = useTheme();
  const [onFocus, setOnFocus] = useState<boolean>();
  const [maskedValue, setMaskedValue] = useState<any>();

  const {
    handleChange,
    name,
    errors,
    fieldName,
    isRequired = true,
    value,
    disabled,
    id,
  } = props;

  const onFocusIN = (e: any) => {
    setOnFocus(false);
  };

  const onHandleFocusOut = (e: any) => {
    let value = e.target.value;
    let maskedValue = value.replace(/[a-zA-Z0-9]/g, "X");
    const newStr = maskedValue.slice(0, -3);
    const str = value.substring(12);
    const updatedMobile = newStr + str;
    setMaskedValue(updatedMobile);
    setOnFocus(true);
  };

  // useEffect(() => {
  //   onHandleFocusIn(value);
  // },[value]);

  const onHandleFocusIn = (values: any) => {
    let maskedValue = values.replace(/[a-zA-Z0-9]/g, "X"); // Replace all alphanumeric characters with 'X'
    const newStr = maskedValue.slice(0, -3);
    const str = values.substring(12);
    const updatedMobile = newStr + str;
    setMaskedValue(updatedMobile);
    setOnFocus(true);
  };

  return (
    <>
      <Box>
        {fieldName && (
          <Typography
            py="10px"
            fontWeight={400}
            fontSize={"1rem"}
            color={theme.palette.text.dark}
          >
            {fieldName}
            {isRequired && <span style={{ color: "red" }}>*</span>}
          </Typography>
        )}
        {onFocus ? (
          <TextField
            value={maskedValue}
            onFocus={onFocusIN}
            disabled={disabled}
            sx={{
              width: "100%",

              backgroundColor: theme.palette.background.paper,
            }}
          />
        ) : (
          <MuiPhoneNumber
            variant="outlined"
            name={name}
            InputProps={{
              name: name,
            }}
            onBlur={onHandleFocusOut}
            onChange={(e) => handleChange({ target: { value: e, name: name } })}
            error={Boolean(errors)}
            value={value}
            disableAreaCodes={true}
            defaultCountry={"us"}
            preferredCountries={["us"]}
            disabled={disabled}
            id={id}
            sx={{ "& svg": { height: "1em" }, width: "100%" }}
          />
        )}
      </Box>
      {errors && <FormHelperText error>{errors}</FormHelperText>}
    </>
  );
};

type phoneFieldMask = {
  fieldName?: string;
  errors?: any;
  name: string;
  isRequired?: boolean;
  handleChange?: any;
  value?: string;
  disabled?: boolean;
  id?: string;
};

export const PhoneFieldMask1 = (props: PhoneFieldMask1) => {
  const theme = useTheme();
  const [onFocus, setOnFocus] = useState<boolean>();
  const [maskedValue, setMaskedValue] = useState<any>();

  const {
    handleChange,
    name,
    errors,
    fieldName,
    isRequired = true,
    value,
    disabled,
    id,
  } = props;

  const onFocusIN = (e: any) => {
    setOnFocus(false);
  };

  const onHandleFocusOut = (e: any) => {
    let value = e.target.value;
    let maskedValue =
      value.substring(0, value.length - 3).replace(/./g, "X") +
      value.substring(value.length - 3);
    setMaskedValue(maskedValue);
    setOnFocus(true);
  };

  useEffect(() => {
    onHandleFocusIn(value);
  }, [value]);

  const onHandleFocusIn = (values: any) => {
    let maskedValue = values.replace(/[a-zA-Z0-9]/g, "X"); // Replace all alphanumeric characters with 'X'
    const newStr = maskedValue.slice(0, -3);
    const str = values.substring(12);
    const updatedMobile = newStr + str;
    setMaskedValue(updatedMobile);
    setOnFocus(true);
  };

  return (
    <>
      <Box>
        {fieldName && (
          <Typography
            py="10px"
            fontWeight={400}
            fontSize={"1rem"}
            color={theme.palette.text.dark}
          >
            {fieldName}
            {isRequired && <span style={{ color: "red" }}>*</span>}
          </Typography>
        )}
        {onFocus ? (
          <TextField
            value={maskedValue}
            onFocus={onFocusIN}
            disabled={disabled}
            sx={{
              width: "100%",

              backgroundColor: theme.palette.background.paper,
            }}
          />
        ) : (
          <MuiPhoneNumber
            variant="outlined"
            name={name}
            InputProps={{
              name: name,
            }}
            onBlur={onHandleFocusOut}
            onChange={(e) => handleChange({ target: { value: e, name: name } })}
            error={Boolean(errors)}
            value={value}
            disableAreaCodes={true}
            defaultCountry={"us"}
            preferredCountries={["us"]}
            disabled={disabled}
            id={id}
            sx={{ "& svg": { height: "1em" }, width: "100%" }}
          />
        )}
      </Box>
      {errors && <FormHelperText error>{errors}</FormHelperText>}
    </>
  );
};

type PhoneFieldMask1 = {
  fieldName?: string;
  errors?: any;
  name: string;
  isRequired?: boolean;
  handleChange?: any;
  value?: string;
  disabled?: boolean;
  id?: string;
};

export const SearchInputFiled = ({
  fieldName,
  isRequired,
  placeholder,
  values = "",
  name,
  handleChange,
  startIcon,
  endIcon,
  handleSearchClear,
  errors,
}: SearchFiled) => {
  const theme = useTheme();
  return (
    <Box>
      {fieldName && (
        <Typography
          py="10px"
          fontWeight={400}
          fontSize={"1rem"}
          color={theme.palette.text.dark}
        >
          {fieldName}
          {isRequired && <span style={{ color: "red" }}>*</span>}
        </Typography>
      )}
      <TextField
        variant="outlined"
        value={values || ""}
        name={name}
        onChange={handleChange}
        sx={{
          width: "100%",
          backgroundColor: theme.palette.background.paper,
        }}
        placeholder={placeholder ? placeholder : ""}
        error={Boolean(errors)}
        InputProps={{
          style: { display: "flex", alignItems: "center" },
          startAdornment: startIcon && (
            <IconButton edge="start" aria-label="start-icon">
              {startIcon}
            </IconButton>
          ),
          endAdornment: endIcon && (
            <IconButton
              edge="end"
              aria-label="end-icon"
              disabled={values && values?.length > 0 ? false : true}
              sx={{ visibility: !!values?.length ? "visible" : "hidden" }}
              onClick={handleSearchClear}
            >
              {endIcon}
            </IconButton>
          ),
        }}
      />
      {errors && (
        <FormHelperText
          error
          id="standard-weight-helper-text-email-login"
          sx={{ mt: 0.3, p: 0 }}
        >
          {errors}
        </FormHelperText>
      )}
    </Box>
  );
};

type SearchFiled = {
  fieldName?: string;
  isRequired?: boolean;
  placeholder?: string;
  values: string | null | undefined;
  name?: string;
  handleChange?: (_: any) => any;
  startIcon?: any;
  endIcon?: any;
  handleSearchClear?: (_: any) => void;
  errors?: any;
};

export const AutocompleteFieldWithGroupBy = ({
  handleChange,
  values,
  fieldName,
  errors,
  name,
  options,
  isRequired = true,
  disabled = false,
  id = name,
  multiple = false,
  helphover = false,
  helperImg,
  helperContent,
}: AutoCompleteWithGroupBy) => {
  const theme = useTheme();
  return (
    <>
      <Box>
        <Typography
          py="10px"
          fontWeight={400}
          fontSize={"1rem"}
          color={theme.palette.text.dark}
        >
          {fieldName && fieldName}
          {isRequired && <span style={{ color: "red" }}>*</span>}
          {helphover && (
            <span>
              <HoverImagePopover img={helperImg} content={helperContent} />
            </span>
          )}
        </Typography>
        <Autocomplete
          multiple={multiple}
          value={values}
          onChange={(event: any, newValue: any) => {
            handleChange(newValue);
          }}
          id={id}
          options={options}
          PaperComponent={(props) => (
            <Paper
              sx={{
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              }}
              {...props}
            />
          )}
          getOptionLabel={(option: any) => option?.name}
          groupBy={(option: any) => option?.groupHeader}
          renderOption={(props, option: any) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0, p: 1 } }}
              {...props}
            >
              {option.name}
            </Box>
          )}
          renderGroup={(params) => (
            <li key={params.key}>
              <GroupHeader>{params.group}</GroupHeader>
              <GroupItems>{params.children}</GroupItems>
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              error={Boolean(errors)}
              id={id}
              // placeholder={fieldName}
              variant="outlined"
            />
          )}
          sx={{
            width: "100%",
          }}
          disabled={disabled}
        />
      </Box>
      {errors && <FormHelperText error>{errors}</FormHelperText>}
    </>
  );
};

const GroupHeader = styled("div")(({ theme }) => ({
  fontSize: "1rem",
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.background.default,
  textTransform: "capitalize",
  fontWeight: "700",
}));

const GroupItems = styled("ul")({
  padding: 0,
});

type AutoCompleteWithGroupBy = {
  options: { value: string | number; name: string }[];
  handleChange: any;
  values: any;
  isRequired?: boolean;
  disabled?: boolean;
  id?: string;
  errors?: any;
  name: string;
  fieldName?: string;
  multiple?: boolean;
  helphover?: boolean;
  helperImg?: any;
  helperContent?: any;
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export function InputFileUpload({
  fieldName,
  isRequired = true,
  helperContent,
  helperImg,
  errors,
  helphover,
  handleChange,
  values,
  handleRemoveFile,
  fileName,
  id,
}: InputFieldUploadType) {
  const theme = useTheme();
  return (
    <>
      {values ? (
        <Stack direction="column" spacing={1} flexGrow={1} margin={0}>
          {fieldName && (
            <Typography
              py="10px"
              fontWeight={400}
              fontSize={"1rem"}
              color={theme.palette.text.dark}
            >
              {fieldName}
              {fieldName && isRequired && (
                <span style={{ color: "red" }}>*</span>
              )}
            </Typography>
          )}
          <Box
            sx={{
              border: "1px black dashed",
              p: 0.5,
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              bgcolor: "transparent",
              color: theme.palette.text.dark,
              borderRadius: "4px",
              mt: "0px !important",
            }}
          >
            {fileName && (
              <Typography
                fontWeight={400}
                fontSize={"1rem"}
                color={theme.palette.text.dark}
              >
                {fileName}
              </Typography>
            )}
            <IconButton onClick={handleRemoveFile} color="error">
              <ClearIcon />
            </IconButton>
          </Box>
        </Stack>
      ) : (
        <Box>
          <Typography
            py="10px"
            fontWeight={400}
            fontSize={"1rem"}
            color={theme.palette.text.dark}
          >
            {fieldName && fieldName}
            {fieldName && isRequired && <span style={{ color: "red" }}>*</span>}
            {helphover && (
              <span>
                <HoverImagePopover img={helperImg} content={helperContent} />
              </span>
            )}
          </Typography>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
            sx={{
              border: "1px black dashed",
              p: 1.5,
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              bgcolor: "transparent",
              color: theme.palette.text.dark,
              "&:hover": {
                bgcolor: "transparent",
                boxShadow: "none",
              },
            }}
          >
            Upload file
            <VisuallyHiddenInput
              name={id}
              type="file"
              onChange={handleChange}
            />
          </Button>
        </Box>
      )}
      {errors && <FormHelperText error>{errors}</FormHelperText>}
    </>
  );
}

type InputFieldUploadType = {
  handleChange?: any;
  values?: any;
  isRequired?: boolean;
  disabled?: boolean;
  id?: string;
  errors?: any;
  name?: string;
  fieldName?: string;
  multiple?: boolean;
  helphover?: boolean;
  helperImg?: any;
  helperContent?: any;
  handleRemoveFile?: any;
  fileName?: string;
};


type ColorPickerFieldType = {
  fieldName?: string;
  value?: string;
  name?: string;
  error?: string;
  handleChange?: any;
  id?: string;
  isRequired?: boolean
  color?: string | {
    hex?: string;
    hsl?: {
      h: number;
      s: number;
      l: number;
      a: number;
    };
    rgb?: {
      r: number;
      g: number;
      b: number;
      a: number;
    };
  };
}

const ColorPickerField = ({ fieldName, value, name, error, handleChange, isRequired = true, id = name,
}: ColorPickerFieldType) => {
  const handleColorChange = (color: any) => {
    handleChange({
      target: {
        name: name,
        value: color.hex
      }
    })
  }
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };
  const theme = useTheme();

  return (

    <>
      <Box>
        {fieldName && (
          <Typography
            py="10px"
            fontWeight={400}
            fontSize={"1rem"}
            color={theme.palette.text.dark}
          >
            {fieldName}
            {isRequired && <span style={{ color: "red" }}>*</span>}
          </Typography>
        )}
        <Box sx={{ position: 'relative' }}>
          <TextField
            id={id}
            label={fieldName}
            value={value}
            name={name}
            error={!!error}
            helperText={error}
            onChange={(e) => handleChange({ target: { name: e.target.name, value: e.target.value } })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton onClick={handleClick} sx={{ padding: 0 }}>
                    <Box
                      sx={{
                        width: 24,
                        height: 24,
                        backgroundColor: value,
                        border: '1px solid #ccc',
                        borderRadius: '4px'
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              )
            }}
            fullWidth
          />
          {displayColorPicker && (
            <Box sx={{ position: 'absolute', zIndex: 2 }}>
              <Box
                sx={{
                  position: 'fixed',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0
                }}
                onClick={handleClose}
              />
              <SketchPicker color={value} onChangeComplete={handleColorChange} />
            </Box>
          )}
        </Box>
      </Box>
      {/* {error && <FormHelperText>{error}</FormHelperText>} */}
    </>
  );
}

export default ColorPickerField;




type InputCheckBoxTypes = {

  fieldName?: String;
  values: boolean;
  handleChange?: (_: any) => void;
  errors?: any;
  name?: string;
  isRequired?: boolean;
  disabled?: boolean;
  id?: string;
  helperImg?: any;
  helperContent?: any;
  helphover?: boolean;
  helperText?: string;
  size?: string;
  label?: string;

}

export const InputCheckBox = ({
  fieldName,
  values,
  handleChange,
  errors,
  name,
  isRequired = true,
  disabled = false,
  id = name,
  helperImg,
  helperContent,
  helphover,
  helperText,
  size = "medium",
  label,
}: InputCheckBoxTypes) => {

  const theme = useTheme();

  return (
    <>
      <Box>
        {
          fieldName && (
            <Typography
              py="10px"
              fontWeight={400}
              fontSize={"1rem"}
              color={theme.palette.text.dark}
            >
              {fieldName}
              {isRequired && <span style={{ color: "red" }}>*</span>}
            </Typography>
          )}

        <Box sx={{mt:1}}>
          <FormControlLabel
            control={
              <Checkbox
                checked={values}
                onChange={handleChange}
                name={name}
                disabled={disabled}
                id={id}
                color="primary"

              />}
            label={label}
          />
        </Box>

        {errors && (
          <FormHelperText
            error
            id="standard-weight-helper-text-email-login"
            sx={{ mt: 0.3, p: 0 }}
          >
            {errors}
          </FormHelperText>
        )}

      </Box>
    </>
  )
}