import { Box, Grid, Typography } from "@mui/material";
import Loading from "container/loading";
import dayjs from "dayjs";
import { commonService } from "forms/helpers/common.service";
import TextView from "forms/hoc/text-view";
import { dateFormat } from "forms/hooks/customHook";
import { Roles } from "helpers/enum.helpers";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/store";
import Loader from "ui-component/Loader";

const CustomerInfoView = ({ customerId }: PolicyViewType) => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [policyInfoData, setPolicyInfoData] = useState<any>();
  const { t } = useTranslation();

  const { role } = useAppSelector((state) => state.user.user);
  useEffect(() => {
    getPolicyInfo();
  }, [customerId]);

  useEffect(() => {
    console.log("Updated policyInfoData:", policyInfoData);
  }, [policyInfoData]); 
  const getPolicyInfo = () => {
    setLoading(true);
    commonService
      .getServices(`/excel-policy/customer/${customerId}`)
      .then((res) => {
        const datas = res.data.data;
        console.log({ datas });

        setPolicyInfoData(datas);
        setLoading(false);

        console.log(
          "hello3333",
          policyInfoData,
          res?.data?.data,
          "eeeeee",
          res?.data?.data
        );
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <Loading />}
      <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} lg={3}>
          <Box p={1}>
            <TextView
              fieldName={t("First Name")}
              fieldValue={
                policyInfoData
                  ? policyInfoData?.contactInfos?.[0]?.first_name
                  : "-"
              }
            />
          </Box>
        </Grid>

        <Grid item xs={12} lg={3}>
          <Box p={1}>
            <TextView
              fieldName={t("Last Name")}
              fieldValue={
                policyInfoData?.contactInfos?.[0]?.last_name
                  ? policyInfoData?.contactInfos?.[0]?.last_name
                  : "-"
              }
            />
          </Box>
        </Grid>

        <Grid item xs={12} lg={3}>
          <Box p={1}>
            <TextView
              fieldName={t("Email")}
              fieldValue={
                policyInfoData?.contactInfos?.[0]?.emails?.[0]?.email
                  ? policyInfoData?.contactInfos?.[0]?.emails?.[0]?.email
                  : "-"
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Box p={1}>
            <TextView
              fieldName={t("Customer Type")}
              fieldValue={
                policyInfoData?.customer_type?.values
                  ? policyInfoData?.customer_type?.values
                  : "-"
              }
            />
          </Box>
        </Grid>

        <Grid item xs={12} lg={3}>
          <Box p={1}>
            <TextView
              fieldName={t("Address 1")}
              fieldValue={
                policyInfoData?.contactInfos?.[0]?.addresses?.[0]?.address_line_1 ?? "-"
              }
            />
          </Box>
        </Grid>

        <Grid item xs={12} lg={3}>
          <Box p={1}>
            <TextView
              fieldName={t("Address 2")}
              fieldValue={
                policyInfoData?.contactInfos?.[0]?.addresses?.[0]?.address_line_2 ?? "-"
              }
            />
          </Box>
        </Grid>

        <Grid item xs={12} lg={3}>
          <Box p={1}>
            <TextView
              fieldName={t("Zip Code")}
              fieldValue={
                policyInfoData?.contactInfos?.[0]?.addresses?.[0]?.zip_code ?? "-"
              }
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

type PolicyViewType = {
  customerId?: string;
};

export default CustomerInfoView;
