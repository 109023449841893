import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
    makepayment: {}
}

export const makepaymentSlice = createSlice({
    name: "makepayment",
    initialState,
    reducers: {
        // Define reducers here
        setMakepayment(state, action: PayloadAction<any>) {
            state.makepayment = action.payload
        },
        setPersonalInfo(state, action: PayloadAction<any>) {
            state.makepayment.personalInfo = action.payload
        },
        setPolicyType(state, action: PayloadAction<any>) {
            state.makepayment.policyType = action.payload
        },
        setExistingCustomerToken(state, action: PayloadAction<any>) {
            state.makepayment.existingCustomerToken = action.payload
        },
        setNewCustomerToken(state, action: PayloadAction<any>) {
            state.makepayment.newCustomerToken = action.payload
        }
    },
    extraReducers: (builder) => {
        // Define extra reducers here
    },
})

export const {
    setMakepayment,
    setPersonalInfo,
    setPolicyType,
    setExistingCustomerToken,
    setNewCustomerToken
} = makepaymentSlice.actions;
export default makepaymentSlice.reducer;