import deleteAlert from 'component/HOC/deleteAlert';
import EnhancedTable from 'component/tables/selectableTable';
import { commonService } from 'forms/helpers/common.service';
import { Failed, Success } from 'forms/helpers/toast.helper';
import MainContainerWrapper from 'layout/wrappers/MainContainerWrapper';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'store/store';
import { boolean } from 'yup';
import ReferenceContact from '../create/ReferenceContact';
import { Box, Button } from '@mui/material';

function ReferenceContactList({ handleNextTab }: ReferenceContactListType) {


    const { t } = useTranslation();

    const { referenceID } = useAppSelector((state) => state.reference)


    console.log("hahahahahha", referenceID);

    const { id } = useParams()

    const referenceContactList = [

        {
            id: "contact_name",
            numeric: false,
            disablePadding: false,
            label: t("contact_name"),
            isSort: false,
        },
        {
            id: "title",
            numeric: false,
            disablePadding: false,
            label: t("title"),
            isSort: false,
        },
        {
            id: "action",
            numeric: false,
            disablePadding: false,
            label: t("Action"),
            isSort: false,
        },

    ]

    const navigate = useNavigate();
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [editbankId, setEditBankId] = useState<String>()
    let [order, setOrder] = useState<"DESC" | "ASC">("DESC");
    let [orderBy, setOrderBy] = useState("updated_at");
    const [rows, setRows] = useState<any>([]);
    const [count, setCount] = useState<any>();
    const [isLoading, setIsLoading] = useState<any>(false);
    const [referenceContact, setReferenceContact] = useState<boolean>(false)
    const [isReferenceEditType, setIsReferenceEditType] = useState<boolean>(false)
    const [editReferenceContactId, setEditReferenceContactId] = useState<string>("")


    useEffect(() => {
        getTableData();
    }, [])

    const getTableData = () => {

        const api = id ? `reference-contact/${id}` : `reference-contact/${referenceID}`
        commonService
            .getServices(api)
            .then((res: any) => {
                const TableData = res?.data?.data?.results.map((data: any) => {
                    return {
                        contact_name: data?.contact_name,
                        title: data?.title
                    }
                })
                setRows(res?.data?.data?.results)
            })
            .catch((err: any) => {
                Failed("Something went wrong, please try again later");
            })
    };

    const handleCreateReferenceContact = () => {
        setReferenceContact(true)
    }

    const handleSubmitReferenceContact = () => {
        setReferenceContact(false)
        setIsReferenceEditType(false)
        getTableData()
    }

    const handleAction = (id: string, action: string) => {

        if (action === "edit") {

            setReferenceContact(true)
            setIsReferenceEditType(true)
            setEditReferenceContactId(id)

        } else if (action === "delete") {
            handleDelete(id)
        }
    }



    const handleDelete = (id: string) => {
        deleteAlert({
            title: t("Delete"),
            message: t("Are You sure?"),
            confirmButtonText: t("Yes"),
            onConfirmed: deleteAction,
            data: id,
        });
    };


    const deleteAction = (deleteId: string) => {
        const api = id ? `reference-contact/${id}/contact/${deleteId}` : `reference-contact/${referenceID}/contact/${deleteId}`
        commonService
            .deleteService(api)
            .then((res: any) => {
                Success(res?.data?.message);
                getTableData();
            })
            .catch((err) => {

            });
    };


    const handleSaveSubmit = () => {
        handleNextTab()
    }

    return (
        <>
            {!referenceContact && <MainContainerWrapper
                headingText='Contact List'
                isCreateButton
                buttonText={t("Create Contact")}
                buttonIcon={<span>&#43;</span>}
                handleClick={handleCreateReferenceContact}
            >
                <EnhancedTable
                    headCells={referenceContactList}
                    rows={rows}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    total={count}
                    isEdit={true}
                    actionFunction={handleAction}
                    isDelete={true}
                />
                <Box sx={{ pt: 2 }} display={"flex"} justifyContent={"right"}>
                    <Button
                        variant="contained"
                        sx={{ ml: 2 }}
                        type="button"
                        onClick={() => {
                            handleSaveSubmit();
                        }}
                    >
                        {t("Save_&_Proceed")}
                    </Button>
                </Box>
            </MainContainerWrapper>}
            {referenceContact &&
                <ReferenceContact
                    handleNextTab={handleSubmitReferenceContact}
                    isReferenceEditType={isReferenceEditType}
                    editReferenceContactId={editReferenceContactId}
                />}
        </>
    )
}

export default ReferenceContactList


type ReferenceContactListType = {
    handleNextTab: any
}