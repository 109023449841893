import { Box, Grid, Typography } from "@mui/material";
import { commonService } from "forms/helpers/common.service";
import TextView from "forms/hoc/text-view";
import MainContainerWrapper from "layout/wrappers/MainContainerWrapper";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "ui-component/Loader";
import { useTranslation } from "react-i18next";
import { Failed } from "forms/helpers/toast.helper";
import dayjs from "dayjs";

const RolodexView = () => {
  const { t } = useTranslation();
  const [isLoading, setIsloading] = useState(false);
  const [cat, setCat] = useState("");
  const [locationData, setLocationData] = useState<any>({});
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getWholesellerData();
    }
  }, []);

  const getWholesellerData = () => {
    setIsloading(true);
    commonService
      .getServices(`/rolodex/${id}`)
      .then((res) => {
        console.log("Location data", res);
        const locationData = {
          rolodex_category: res.data.data?.rolodex_category?.id,
          rolodex_type: res.data.data?.rolodex_type,
          company_name_one: res.data.data?.company_name_one,
          company_name_two: res.data.data?.company_name_two,
          prefix: res.data.data?.prefix?.id,
          suffix: res.data.data?.suffix?.id,
          title: res.data.data?.title,
          first_name: res.data.data?.first_name,
          last_name: res.data.data?.last_name,
          middle_name: res.data.data?.middle_name,
          dob: res.data.data?.dob,
          website: res.data.data?.website,
          dmv_code: res.data.data?.dmv_code,
          naci_code: res.data.data?.naci_code,
          producer_code: res.data.data?.producer_code,
          emails: res.data.data?.phones?.find(
            (item: any) => item?.is_default === 1
          )?.email,
          phones:
            res.data.data?.phones?.find((item: any) => item?.is_default === 1)
              ?.country_code +
            res.data.data?.phones?.find((item: any) => item?.is_default === 1)
              ?.phone,
          addresses: res.data.data?.addresses?.find(
            (item: any) => item?.is_default === 1
          ),
        };
        const cat = res.data.data?.rolodex_type;
        setCat(cat);
        setLocationData(locationData);
        setIsloading(false);
      })
      .catch((err) => {
        setIsloading(false);
        Failed("Something went wrong, please try again later");
      });
  };

  return (
    <>
      <MainContainerWrapper headingText="Rolodex View" isBackButton={true}>
        {isLoading && <Loader />}
        <Grid container sx={{ paddingRight: "25px", pl: 2 }}>
          <Grid item xs={12} lg={12}>
            <Box py={2} pl={2}></Box>
          </Grid>

          <Grid container columnSpacing={2} pt={1}>
            {cat === "company" && (
              <>
                <Grid item xs={12} lg={4}>
                  <Box p={1}>
                    <TextView
                      fieldName={t("Company Name 1")}
                      fieldValue={
                        locationData?.company_name_one
                          ? locationData?.company_name_one
                          : "-"
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Box p={1}>
                    <TextView
                      fieldName={t("Company Name 2")}
                      fieldValue={
                        locationData?.company_name_two
                          ? locationData?.company_name_two
                          : "-"
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Box p={1}>
                    <TextView
                      fieldName={t("Title")}
                      fieldValue={
                        locationData?.title ? locationData?.title : "-"
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Box p={1}>
                    <TextView
                      fieldName={t("DMV code")}
                      fieldValue={
                        locationData?.dmv_code ? locationData?.dmv_code : "-"
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Box p={1}>
                    <TextView
                      fieldName={t("NAIC code")}
                      fieldValue={
                        locationData?.naci_code ? locationData?.naci_code : "-"
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Box p={1}>
                    <TextView
                      fieldName={t("Producer Code")}
                      fieldValue={
                        locationData?.producer_code
                          ? locationData?.producer_code
                          : "-"
                      }
                    />
                  </Box>
                </Grid>
              </>
            )}


             {cat === "person" &&
             <>

            <Grid item xs={12} lg={4}>
              <Box p={1}>
                <TextView
                  fieldName={t("First Name")}
                  fieldValue={locationData?.first_name ? locationData?.first_name : "-"}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box p={1}>
                <TextView
                  fieldName={t("Middle Name")}
                  fieldValue={locationData?.middle_name ? locationData?.middle_name : "-"}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box p={1}>
                <TextView
                  fieldName={t("Last Name")}
                  fieldValue={locationData?.last_name ? locationData?.last_name : "-"}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box p={1}>
                <TextView
                  fieldName={t("Date Of Birth")}
                  fieldValue={locationData?.dob ? dayjs(locationData?.dob).format("MM/DD/YYYY") : "-"}
                />
              </Box>
            </Grid>

            </>}

            <Grid item xs={12} lg={4}>
              <Box p={1}>
                <TextView
                  fieldName={t("Phone Number")}
                  fieldValue={locationData?.phones ? locationData?.phones : "-"}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box p={1}>
                <TextView
                  fieldName={t("Email")}
                  fieldValue={locationData?.emails ? locationData?.emails : "-"}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box p={1}>
                <TextView
                  fieldName={t("Address Line One")}
                  fieldValue={
                    locationData?.addresses?.address_line_1
                      ? locationData?.addresses?.address_line_1
                      : "-"
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box p={1}>
                <TextView
                  fieldName={t("Address Line Two")}
                  fieldValue={
                    locationData?.addresses?.address_line_2
                      ? locationData?.addresses?.address_line_2
                      : "-"
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box p={1}>
                <TextView
                  fieldName={t("State")}
                  fieldValue={locationData?.state ? locationData?.state : "-"}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box p={1}>
                <TextView
                  fieldName={t("City")}
                  fieldValue={
                    locationData?.addresses?.city
                      ? locationData?.addresses?.city
                      : "-"
                  }
                />
              </Box>
            </Grid>

            <Grid item xs={12} lg={4}>
              <Box p={1}>
                <TextView
                  fieldName={t("zip_code")}
                  fieldValue={
                    locationData?.addresses?.zip_code
                      ? locationData?.addresses?.zip_code
                      : "-"
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </MainContainerWrapper>
    </>
  );
};

export default RolodexView;
