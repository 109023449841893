import deleteAlert from 'component/HOC/deleteAlert';
import EnhancedTable from 'component/tables/selectableTable'
import { commonService } from 'forms/helpers/common.service';
import { errorMessage } from 'forms/helpers/function.helper';
import { Failed, Success } from 'forms/helpers/toast.helper';
import MainContainerWrapper from 'layout/wrappers/MainContainerWrapper'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function AgencyCommissionList() {

    const { t } = useTranslation()

    const AgencyCommissionHeaderCell = [

        {
            id: "insuranceCompany",
            numeric: false,
            disablePadding: false,
            label: t("insuranceCompany"),
            isSort: true,
        },
        {
            id: "state",
            numeric: false,
            disablePadding: true,
            label: t("state"),
            isBold: true,
        },
        {
            id: "policy_type",
            numeric: false,
            disablePadding: false,
            label: t("policy_type"),
        },
        {
            id: "whole_saler",
            numeric: false,
            disablePadding: false,
            label: t("whole_saler"),
        },
        {
            id: "action",
            numeric: false,
            disablePadding: false,
            label: t("Action"),
            isSort: false,
        },

    ]




    let [search, setSearch] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    let [order, setOrder] = useState<"DESC" | "ASC">("DESC");
    let [orderBy, setOrderBy] = useState("created_at");
    const [count, setCount] = useState<any>();
    const [rows, setRows] = useState<any>();
    const [initailLoad, setInitailLoad] = useState(true);
    const [isLoading, setIsLoading] = useState<any>(false);
    const navigate = useNavigate();


    useEffect(() => {
        getTableData()
    }, [])

    const getTableData = () => {
        commonService
            .postServiceParams(`commission-settings/search`, {},
                {
                    per_page: rowsPerPage,
                    page: page + 1,
                    search: search ?? "",
                    sort_order: order,
                    sort_by: orderBy,

                }).then((res: any) => {
                    const tableData = res?.data?.data?.results.map((i: any) => {
                        return {
                            id: i?.id,
                            insuranceCompany: i?.insuranceCompany?.name ? i?.insuranceCompany?.name : "",
                            state: i?.state?.name ? i?.state?.name : "",
                            policy_type: i?.policy_type?.values ? i?.policy_type?.values : "",
                            whole_saler: i?.whole_saler?.name ? i?.whole_saler?.name : "",
                        }
                    })
                    setRows(tableData)
                    setCount(res?.data.data?.pagination?.total);
                    setIsLoading(false);
                    setInitailLoad(false);
                }).catch((err: any) => {
                    Failed(
                        errorMessage(err, "Something went wrong, please try again later")
                    )
                })
    }

    const handleAction = (id: string, action: string) => {
        if (action === "edit") {
            navigate(`/settings/AgencyCommission/edit/${id}`)
        } else if (action === "delete") {
            handleDelete(id)
        }
    }


    const handleDelete = (id: string) => {
        deleteAlert({
            title: t("Delete"),
            message: t("Are You sure?"),
            confirmButtonText: t("Yes"),
            onConfirmed: deleteAction,
            data: id,
        });
    };


    const deleteAction = (id: string) => {
        commonService
            .deleteService(`/commission-settings/${id}`)
            .then((res: any) => {
                Success(res?.data?.message);
                getTableData();
            })
            .catch((err) => {

            });
    };


    const handleCreateAgencyCommission = () => {
        navigate(`create`)
    }

    return (
        <>
            <MainContainerWrapper
                headingText={"Agency Commission"}
                isCreateButton
                isBackButton={true}
                buttonText={t("Create")}
                buttonIcon={<span>&#43;</span>}
                handleClick={handleCreateAgencyCommission}
                searchPlaceHolder={t("Search")}
                handleSearchClear={() => setSearch("")}

            >
                <EnhancedTable
                    headCells={AgencyCommissionHeaderCell}
                    rows={rows}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    total={count}
                    isEdit={true}
                    actionFunction={handleAction}
                    isDelete={true}
                />

            </MainContainerWrapper>
        </>
    )
}

export default AgencyCommissionList