import { Autocomplete, Box, Checkbox, FormControlLabel, FormHelperText, Grid, TextField, Typography } from '@mui/material';
import FormLayout from 'component/form';
import Loading from 'container/loading';
import { commonService } from 'forms/helpers/common.service';
import { Failed, Success } from 'forms/helpers/toast.helper';
import { InputCheckBox, InputField, MultiAutoCompleteField } from 'forms/hoc/formfield';
import useForm from 'forms/hooks/useForm';
import { createFeeValidation, createFeeValidationSchema } from 'forms/hooks/validateRules';
import MainContainerWrapper from 'layout/wrappers/MainContainerWrapper';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
function FeeCreate() {
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedPolicyType, setSelectedPolicyType] = useState<any[]>([]);
    const [policyType, setPolicyType] = useState<any>([])
    // const [AllPolicyType, setAllPolictyType] = useState<boolean>(false);
    const [recurringFee, setRecurringFee] = useState<boolean>(false);
    const [agencyFee, setAgencyFee] = useState<boolean>(false);
    const navigate = useNavigate();
    const { id } = useParams();



    const initialValues = {
        name: null,
        type: null,
        recurring_fee: null,
        agency_fee: null,
        feeCoverageTypes: [],

    };

    const getMaster = () => {
        let params = new URLSearchParams();
        params.append("types", `Policy Type`);
        commonService
            .getServices(`/master?${params.toString()}`)
            .then((res) => {
                const PolicyTypeObject = res?.data?.data["Policy Type"];
                if (PolicyTypeObject) {
                    setPolicyType(PolicyTypeObject)
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    }
    useEffect(() => {
        if (id) {
            getFeeData()
        }
        getMaster()
    }, [])


    const getFeeData = () => {
        commonService.getServices(`/fee/${id}`).
            then((res: any) => {

                // setPatchValue(res?.data?.data)
                const feeData = res?.data?.data;
                let getFeeData = {
                    apply_to_all_coverage:feeData?.apply_to_all_coverage,
                    name: feeData?.name,
                    feeCoverageTypes: feeData?.feeCoverageTypes.map((i: any) => {
                        return {
                            name: i?.policy_type?.values,
                            value: i?.policy_type?.id,
                        }
                    })
                }

                setPatchValue(getFeeData)

                if (feeData?.apply_to_all_coverage === false) {
                    const selectedTypes = feeData?.feeCoverageTypes.map((item: any) => ({
                        name: item?.policy_type?.values,
                        value: item?.policy_type?.id,
                    }));

                    setSelectedPolicyType(selectedTypes);
                }
                if (feeData?.recurring_fee) {
                    setRecurringFee(true)
                }
                if (feeData?.agency_fee) {
                    setAgencyFee(true)
                }

            })
            .catch((err: any) => { })
    }

    const formSubmit = () => {

        const api = id ? `/fee/${id}` : "/fee";
        const bodyData = id
            ? {
                ...values,
                recurring_fee: recurringFee,
                agency_fee: agencyFee,
                feeCoverageTypes: values?.apply_to_all_coverage ? [] : values?.feeCoverageTypes.map((i: any) => {
                    return { policy_type: i?.value }
                }),
                // apply_to_all_coverage: AllPolicyType
            }
            : {
                ...values,
                recurring_fee: recurringFee,
                agency_fee: agencyFee,
                feeCoverageTypes: values?.apply_to_all_coverage ? [] : values?.feeCoverageTypes.map((i: any) => {
                    return { policy_type: i?.value }
                }),
                // apply_to_all_coverage: AllPolicyType
            };

        commonService
            .postPutService(api, bodyData, id ? "patch" : "post")
            .then((res) => {
                Success(res?.data?.message);
                setLoading(false);
                handleBack();
            })
            .catch((err) => {
                setLoading(false);
                Failed("Something went wrong, please try again later");
            });
    };


    const { t } = useTranslation();
    const createFeeValidationSchemaT = createFeeValidationSchema(t)


    const {
        errors,
        values,
        handleChange,
        handleSubmit,
        setPatchValue,
        setUpdateValue,
        setUpdateFormValue
    } = useForm(initialValues, formSubmit, createFeeValidation, createFeeValidationSchemaT);




    const handleBack = () => {
        navigate(-1);
    };


    const handleChangeSelectPolicyType = (e: any, newSelectedValues: any[]) => {
        const currentPolicyType = newSelectedValues.map((i: any) => {
            return {
                value: i.value,
                name: i.name,
            }
        })
        setSelectedPolicyType(currentPolicyType)
    }


    const handleRecurringFeeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked
        setRecurringFee(checked)
    }

    const handleAgencyFeeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked
        setAgencyFee(checked)
    }


    useEffect(() => {
        console.log("selected", selectedPolicyType);

    }, [selectedPolicyType])

    console.log('values', values)
    console.log("erros",errors);
    
    return (
        <>
            <MainContainerWrapper
                headingText={!id ? t('Fee Create') : t('Fee Edit')}
                isBackButton={true}
            >
                <>
                    {loading && <Loading />}
                    <Box sx={{ p: 3, height: "80vh", width: "100%" }}>
                        <FormLayout
                            handleSubmit={handleSubmit}
                            isBackButton
                            backButtonClick={handleBack}
                            backButtonText={t("Cancel")}
                            isSaveButton
                        >
                            <Grid container columnSpacing={2}>
                                <Grid item xs={12} lg={4}>
                                    <InputField
                                        fieldName={t("Name of Fee")}
                                        values={values?.name || ""}
                                        name="name"
                                        errors={errors?.name}
                                        handleChange={handleChange}
                                        id="name"
                                    />
                                </Grid>
                                <Grid item xs={12} lg={2}>
                                    <Box sx={{ ml: 2, mt: 6 }}>
                                        {/* <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={AllPolicyType}
                                                    onChange={handlePolicyTypeChange}
                                                />
                                            }
                                            label={t("All Policy Type")}
                                        /> */}
                                        <InputCheckBox
                                            values={values?.apply_to_all_coverage || false}
                                            errors={errors?.apply_to_all_coverage}
                                            name="apply_to_all_coverage"
                                            handleChange={(e: any) => setUpdateFormValue("apply_to_all_coverage", e.target.checked)}
                                            id="apply_to_all_coverage"
                                            label={t("All Policy Type")}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    {!values?.apply_to_all_coverage &&
                                        // <Box
                                        //     sx={{ mt: 5 }}
                                        // >
                                        //     <Autocomplete
                                        //         multiple
                                        //         id="checkboxes-tags-demo"
                                        //         options={policyType}
                                        //         value={selectedPolicyType || []} // Bind selected values here
                                        //         disableCloseOnSelect={true}
                                        //         getOptionLabel={(option: any) => option.name}
                                        //         isOptionEqualToValue={(option, value) => option.value === value.value} // Ensure equality check
                                        //         onChange={handleChangeSelectPolicyType} // Add this line
                                        //         renderOption={(props, option, { selected }) => {
                                        //             return (
                                        //                 <Box
                                        //                     component="li"
                                        //                     sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                        //                     {...props}
                                        //                 >
                                        //                     <Checkbox
                                        //                         icon={icon}
                                        //                         checkedIcon={checkedIcon}
                                        //                         style={{ marginRight: 8 }}
                                        //                         checked={selected}
                                        //                     />
                                        //                     {option.name}
                                        //                 </Box>
                                        //             );
                                        //         }}
                                        //         style={{ width: 450 }}
                                        //         renderInput={(params) => (
                                        //             <TextField {...params} label="Policy Type" placeholder="Policy Type" />
                                        //         )}
                                        //     />
                                        // </Box>
                                        <MultiAutoCompleteField
                                            fieldName={t("Coverages Types")}
                                            values={values?.feeCoverageTypes || []}
                                            name="feeCoverageTypes"
                                            errors={errors?.feeCoverageTypes}
                                            handleChange={(feeCoverageTypes: any) =>
                                                setUpdateFormValue("feeCoverageTypes", feeCoverageTypes)
                                            }
                                            options={policyType}
                                            id="feeCoverageTypes"
                                            multiple={true}
                                        />
                                    }
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <Box sx={{ ml: 2, mt: 3 }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={recurringFee}
                                                    onChange={handleRecurringFeeChange}
                                                />
                                            }
                                            label={t("Recurring Fee")}
                                        />
                                    </Box>

                                    <Box sx={{ ml: 2, mt: 3 }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={agencyFee}
                                                    onChange={handleAgencyFeeChange}
                                                />
                                            }
                                            label={t("Agency Fee")}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </FormLayout>

                    </Box>
                </>
            </MainContainerWrapper>
        </>
    )
}

export default FeeCreate