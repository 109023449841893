import { Box, Button, Grid } from '@mui/material';
import FormLayout from 'component/form';
import Loading from 'container/loading';
import { commonService } from 'forms/helpers/common.service';
import { GroupRadioButton } from 'forms/hoc/formfield';
import useForm from 'forms/hooks/useForm';
import { newOrExistingPolicyvalidation, newOrExistingPolicyvalidationSchema } from 'forms/hooks/validateRules';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setPolicyType } from 'store/slice/makePayment.slice';
import { useAppDispatch, useAppSelector } from 'store/store';
import { policyTypeOption } from 'utils/constant.utils';

function PolicyType({ handleFormSubmit }: PolicyType) {
    const { t } = useTranslation();
    const NewOrExistingPolicySchemaT = newOrExistingPolicyvalidationSchema(t);
    const makePayment = useAppSelector((state) => state?.makePayment?.makepayment)
    const [isExistingPolicy, setExistingPolicy] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<any>(false);
    useEffect(() => {
        getExistingPolicy();
    }, []);

    const getExistingPolicy = () => {
        setLoading(true);
        commonService
          .postService(`make_payment/policy/exist`, {
            token: makePayment?.existingCustomerToken
          })
          .then((res: any) => {
            setExistingPolicy(!!res?.data?.data?.length);
            setLoading(false);
          })
          .catch((err: any) => {
            setLoading(false);
          });
    }

    const formSubmit = () => {
        dispatch(setPolicyType(storePolicyType));
        handleFormSubmit();
    }

    const {
        errors,
        values,
        handleChange,
        handleSubmit,
    } = useForm({}, formSubmit, newOrExistingPolicyvalidation, NewOrExistingPolicySchemaT);

    const storePolicyType = {
        policy_type: values?.new_or_old_policy
    }

    return (
        <Box sx={{
            maxWidth: "416px",
            margin: "auto"
        }}>
            {loading && <Loading />}
            <FormLayout
                handleSubmit={handleSubmit}
                submitButtonText="Proceed"
                isSaveButton={false}
            >
                <Grid container columnSpacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{
                            width: "90%",
                            marginTop: "90px",
                            marginBottom: "7px",
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "10px",
                            bgcolor: "#f2f2f2",
                            textAlign: "center"

                        }}>
                            <GroupRadioButton
                                isRow={false}
                                fieldName={t("New Policy or Existing Policy?")}
                                values={values?.new_or_old_policy || ""}
                                name="new_or_old_policy"
                                errors={errors?.new_or_old_policy}
                                options={isExistingPolicy? policyTypeOption: policyTypeOption.filter((val: any) => val.name !== "Existing Policy")}
                                handleChange={handleChange}
                                id="new_or_old_policy"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ alignItems: "center", mt: 2 }} >
                        <Box sx={{ alignItems: "center" }} >
                            <Button
                                onClick={handleSubmit}
                                sx={{
                                    fontSize: "18px",
                                    width: "90%",
                                }}
                            >
                                {`${t("Proceed")}`}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>

            </FormLayout>
        </Box>
    )
}

export default PolicyType


type PolicyType = {
    handleFormSubmit?: any
}