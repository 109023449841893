import ModalComponent from 'component/modal'
import React, { useEffect, useRef, useState } from 'react'
import { commonService } from 'forms/helpers/common.service';
import { Success } from 'forms/helpers/toast.helper';
import ReactQuill from 'react-quill';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { Box, Button, IconButton, Typography } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import Loading from 'layout/layout/MainLayout/Header/notification/fht-notification-dialog/loading';



function ViewNotesModel({
    open,
    handleClose,
    customerId,
    handleCloseNote,
    api,
    deleteNotesAPI,
    contactDeleteNoteApi

}: ViewNotesModelType) {


    const quillRef = useRef<any>(null); // Reference to the Quill instance
    const [current, setCurrent] = useState<number>(0)
    const [enableNext, setenableNext] = useState<boolean>(false)
    const [enablePrevious, setenablePervious] = useState<boolean>(false)
    const [noteList, setNoteList] = useState<any>([])
    const [noteData, setnoteData] = useState<any>("")
    const [noteId, setnoteId] = useState<string>("")
    const [notesDataLength, setnotesDataLength] = useState<boolean>(false)
    const [loading, setloading] = useState<boolean>(false)

    // const handlePreviousNote = () => {

    //     let previousVal
    //     let i: number = +current - 2
    //     previousVal = noteList[i]
    //     console.log("notlsss", noteList);
    //     console.log("prevv", previousVal);


    // }

    // const handleNextNote = () => {

    //     if (current < noteList.length) { // Ensure within bounds
    //         const nextval = noteList[current]; // Get the next note (currentNote is 1-based)
    //         setnoteData(nextval); // Update note data with next note

    //         // Update the current note number to reflect the next note
    //         setCurrent(current + 1);

    //         // Enable/disable navigation buttons as needed
    //         setenablePervious(true); // Enable "Previous" since there's at least one note before
    //         setenableNext(current + 1 < noteList.length); // Disable "Next" if at the end
    //     }
    // }


    const handlePreviousNote = () => {
        if (current > 0) { // Ensure within bounds
            const previousVal = noteList[current - 1].Note; // Get the previous note
            const prevalNoteId = noteList[current - 1].id;
            setnoteData(previousVal); // Update note data with previous note
            setnoteId(prevalNoteId)// customerId={customerId}(prevalNoteId)
            setCurrent(current - 1); // Update current index
            // Enable/disable navigation buttons as needed
            setenableNext(true); // Enable "Next" since there's at least one note after
            setenablePervious(current - 1 > 0); // Disable "Previous" if at the start
        }
    };

    const handleNextNote = () => {
        if (current < noteList.length - 1) { // Ensure within bounds
            const nextval = noteList[current + 1].Note; // Get the next note
            const nextNoteId = noteList[current + 1].id
            setnoteData(nextval); // Update note data with next note
            setnoteId(nextNoteId)
            setCurrent(current + 1); // Update current index

            // Enable/disable navigation buttons as needed
            setenablePervious(true); // Enable "Previous" since there's at least one note before
            setenableNext(current + 1 < noteList.length - 1); // Disable "Next" if at the end
        }
    };

    const modules = {
        toolbar: [
            // ["bold", "italic", "underline"],
            // [{ list: "ordered" }, { list: "bullet" }],
            // ["link"],
            // ["clean"],
        ],
    };
    const formats = ["bold", "italic", "underline", "list", "bullet", "link"];


    useEffect(() => {
        getNotes()
    }, [])

    const getNotes = () => {
        commonService.getServices(api)
            .then((res: any) => {
                const NotesDetails = res?.data?.data.map((item: any) => {
                    return {
                        Created_by_Note: item?.created_by?.email,
                        id: item?.id,
                        Note: item?.notes,
                    }
                })
                setNoteList(NotesDetails)
                if (NotesDetails.length > 0) {
                    setnoteData(NotesDetails[0]?.Note); // Set first note
                    setnoteId(NotesDetails[0]?.id)
                    setenableNext(NotesDetails.length > 1); // Enable "Next" if more than one note
                    setenablePervious(false); // Disable "Previous" at the start
                } else {
                    setnotesDataLength(true)
                }
            })
    }

    const againSetAlertNotes = () => {
        setloading(true);
        setTimeout(() => {
            setloading(false);
            setCurrent(0)
            getNotes();
            console.log("new 1243");
        }, 1000)
    }

    const handleDeleteNotes = () => {
        let checkDeleteAPI;

        if (api.includes('customer-contact-notes')) {
            checkDeleteAPI = `${contactDeleteNoteApi}/${noteId}`
        } else {
            checkDeleteAPI = `${deleteNotesAPI}/delete/${noteId}`
        }
        commonService
            .deleteService(checkDeleteAPI)
            .then((res: any) => {
                Success(res?.data?.message)
                // handleClose()
                if (noteList.length > 1) {
                    againSetAlertNotes()
                } else {
                    handleClose()
                }
            }).catch((err: any) => {
                console.log(err);
            })
    }


    const handleCancelNotes = () => {
        getNotes()
        handleClose()
    }



    return (
        <>
            <ModalComponent
                open={open}
                handleClose={handleClose}
                customeStyle={{ width: "40%", height: "59%", boxShadow: "0px" }}
            >
                <>
                    
                    <Typography
                        variant="h4"
                        sx={{
                            textAlign: "center",
                            mb: 2
                        }}
                    >
                        View Notes
                    </Typography>

                    <CloseIcon
                        sx={{
                            position: "absolute",
                            top: 35,
                            right: 18,
                            color: "red",
                            cursor: "pointer",
                            marginTop: "-18px",
                        }}
                        onClick={handleClose} // Use the new close function
                    />

                    {<ReactQuill
                        ref={quillRef}
                        value={noteData}
                        theme="snow"
                        formats={formats}
                        modules={{ toolbar: false }}
                        readOnly={true}
                        style={{ height: "75%", overflowY: "-moz-hidden-unscrollable" }}
                    />}

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: 3,
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleDeleteNotes} // Cancel button handler
                            sx={{
                                marginRight: 1,
                                height: "2.4rem"
                            }}
                            disabled={notesDataLength}
                        >
                            Delete
                        </Button>

                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleCancelNotes} // Cancel button handler
                            sx={{
                                marginRight: 1,
                                height: "2.4rem"
                            }}
                        >
                            Cancel
                        </Button>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <IconButton
                                color="primary"
                                onClick={handlePreviousNote} // Example for going back
                                disabled={!enablePrevious ? true : false}
                                sx={{
                                    marginRight: 1, border: "1px solid #4a90e2", ml: 1, color: "#fff", backgroundColor: "#4a90e2",
                                    "&:hover": {
                                        backgroundColor: "#4a90e2",
                                    },
                                }}
                            >
                                <SkipPreviousIcon />
                            </IconButton>
                            <IconButton
                                color="primary"
                                onClick={handleNextNote}
                                disabled={!enableNext ? true : false}
                                sx={{
                                    marginRight: 1, border: "1px solid #4a90e2", ml: 1, color: "#fff", backgroundColor: "#4a90e2",
                                    "&:hover": {
                                        backgroundColor: "#4a90e2",
                                    },
                                }}
                            >
                                <SkipNextIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </>

            </ModalComponent>
        </>
    )
}

type ViewNotesModelType = {
    open: boolean,
    handleClose: any,
    customerId?: string
    handleCloseNote?: any
    noteType?: any
    api?: any
    deleteNotesAPI?: any
    contactDeleteNoteApi?: any
}
export default ViewNotesModel